import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import idConstants from '../../../config/idConstants';

import VendorLookupComponent from './vendorLookupComponent';
import {layoutOperations} from '../../../ducks/layout';

export class VendorLookupContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vendor: null,
      address: null,
      zip: null,
      vendorId: null,
      results: null,
      selectedVendorId: 0,
      selected: {}, // the selected row values
      showPagination: false,
      defaultPageSize: 10,
      programTypes: null,
      message: this.props.message || ''
    }
  }

  componentDidMount() {
    this.cancelSource = axios.CancelToken.source();
    if (this.props.programTypes) {
      this.setState({programTypes: this.props.programTypes});
    }
    if (this.props.labType) {
      this.setState({labType: this.props.labType});
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.programTypes !== prevProps.programTypes) {
      this.setState({programTypes: this.props.programTypes}, this.handleVendorSearch);
    }
  }

  componentWillUnmount() {
    this.cancelSource.cancel('userCanceled');
    this.props.resetLoadingPanel();
  }

  /**
   * Event handler for when text input field value changes
   * Update state with current value in input
   * @param e - event Args
   */
  handleSearchInputValueChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  };

  /**
   * Clears cme results by state the results array to an empty array in the component state
   */
  clearResults = () => {
    this.setState({results: []});
  };

  getSearchQuery = () => {
    const {vendor, address, vendorId, zip, programTypes} = this.state;
    const searchKeys = {
      vendor, address, vendorId, zip, programTypes
    };
    let searchQuery = [];
    Object.keys(searchKeys).forEach(sk => {
      if (searchKeys[sk]) {
        searchQuery.push(`${sk}=${searchKeys[sk]}`);
      }
    });
    if (searchQuery.length > 0) {
      return `?${searchQuery.join('&')}`;
    } else {
      return '';
    }
  };

  handleVendorSearch = e => {
    if (e) {
      e.preventDefault(); // NOTE: we need this here, because the search button is a form submit button (so that the Enter key works)
    }
    this.props.showLoadingPanel();
    this.clearResults();
    const defaultPageSize = this.state.defaultPageSize;
    axios.get(`/v1/vendors${this.getSearchQuery()}`, {cancelToken: this.cancelSource.token})
    .then(response => {
      let results = response.data;
      if (this.props.isDrugTesting) {
        results = results.filter((clinic) => clinic.programList.some((prog) => prog.vendorProgramID === idConstants.vendorPrograms.ATN || prog.vendorProgramID === idConstants.vendorPrograms['FormFox eCCF']));
      }
      if (this.state.labType) { //if there is a lab type - meaning if this is not cme.
        //figure out what type of clinic we want to filter out
        let filterOut = this.state.labType.toLowerCase() === 'quest' ? 'labcorp patient service center' : 'quest patient service center';
        if (this.props.filterBreathAlcohol) {//filter out both quest and lab corp patient service centers because neither one do BAT
          filterOut = 'patient service center';
        }
        //filter the results to the lab type
        results = results.filter((clinic) => !clinic.programList.some((prog) => prog.vendorProgramName.toLowerCase().includes(filterOut)));
        // if we require point of collection testing, filter to only those
        if (this.props.requirePOCT) {
          results = results.reduce((clinics, clinic) => {
            const clinicVendorProgramList = {};
            (clinic.programList || []).forEach(vendorProgram => {
              clinicVendorProgramList[vendorProgram.vendorProgramName] = 1;
            });
            // Per Annina, as part of CSD-4043, these clinics need to contain ATN and POCT, and not have FormFox eCCF
            if((clinicVendorProgramList['POCT'] && clinicVendorProgramList['ATN']) && !clinicVendorProgramList['FormFox eCCF']){
              clinics.push(clinic);
            };
            return clinics;
          }, []);
        }
      }
      if (this.state.programTypes) {
        const programTypeToFilter = idConstants.vendorPrograms[this.state.programTypes];
        results = results.filter((clinic) => clinic.programList.some((prog) => prog.vendorProgramID === programTypeToFilter));
      }
      const showPagination = results.length > 0 ? (results.length / defaultPageSize) > 1 : false;
      this.setState({showPagination, results});
      this.props.hideLoadingPanel();
    })
    .catch(err => {
      this.clearResults();
      this.props.hideLoadingPanel();
    });
  };

  /**
   * Event handler for selecting a vendor into state
   */
  handleVendorSelection = (vendorRow) => {
    const {
      address1,
      address2,
      city,
      phone,
      state,
      vendorId,
      vendorName,
      zip,
      formFoxProgId,
      formFoxCode,
      fax,
      programList,
      ESSiteID
    } = vendorRow;
    const selected = {
      address1,
      address2,
      city,
      phone,
      state,
      vendorId,
      vendorName,
      zip,
      formFoxProgId,
      formFoxCode,
      fax,
      programList,
      ESSiteID
    };
    this.setState({selectedVendorId: vendorId, selected});
  };

  handleConfirmVendorSelection = () => {
    const {selectedVendorId} = this.state;
    if (selectedVendorId === null || selectedVendorId === 0) {
      return;
    }
    const updatedVendor = {
      vendorId: this.state.selected.vendorId,
      vendorName: this.state.selected.vendorName,
      address1: this.state.selected.address1,
      address2: this.state.selected.address2,
      city: this.state.selected.city,
      phone: this.state.selected.phone,
      state: this.state.selected.state,
      zip: this.state.selected.zip,
      formFoxProgId: this.state.selected.formFoxProgId,
      formFoxCode: this.state.selected.formFoxCode,
      fax: this.state.selected.fax,
      programList: this.state.selected.programList,
      ESSiteID: this.state.selected.ESSiteID
    };
    if (this.props.handleConfirmVendorSelection) {
      this.props.handleConfirmVendorSelection(updatedVendor);
    }
  };

  handleCancelVendorSelection = e => {
    if (this.props.handleCancelVendorSelection) {
      this.props.handleCancelVendorSelection(e);
    }
  };

  render() {
    const columns = [
      {
        Header: <i className="fas fa-check"/>,
        accessor: d => <input type="radio" value={d.vendorId} checked={d.vendorId === this.state.selectedVendorId}
                              readOnly/>,
        id: 'colSelected',
        headerClassName: 'text-center',
        className: 'text-center',
        width: 40
      },
      {
        id: 'colVendorId',
        Header: 'Vendor #',
        accessor: 'vendorId',
        headerClassName: 'text-left',
        show: !this.props.showECCFColumn
      },
      {
        id: 'colVendorName',
        Header: 'Vendor',
        accessor: 'vendorName',
        headerClassName: 'text-left'
      },
      {
        Header: 'Address',
        id: 'colAddress',
        accessor: d => `${d.address1} ${d.address2}`,
        headerClassName: 'text-left'
      },
      {
        id: 'colCity',
        Header: 'City',
        accessor: 'city',
        headerClassName: 'text-left'
      },
      {
        id: 'colState',
        Header: 'State',
        accessor: 'state',
        headerClassName: 'text-left',
        width: 50
      },
      {
        id: 'colZip',
        Header: 'Zip',
        accessor: 'zip',
        headerClassName: 'text-left',
        width: 60
      },
      {
        id: 'colECCF',
        Header: 'eCCF',
        accessor: 'formFoxProgId',
        headerClassName: 'text-left',
        show: !!this.props.showECCFColumn,
        Cell: ({value}) => {
          if (value) {
            return <i className="fas fa-check text-success"/>
          }
        },
        width: 50
      },
      {
        id: 'colDistance',
        Header: 'Dist. (mi.) *',
        accessor: 'distance',
        headerClassName: 'table-cell-wrap',
        className: 'text-right',
        width: 50,
        show: this.state.zip && this.state.zip.length === 5
      },
      {
        id: 'colPhone',
        Header: 'Phone',
        accessor: 'phone',
        headerClassName: 'text-left'
      }
    ];
    const buttonWrapClassName = this.props.buttonWrapClassName ? this.props.buttonWrapClassName : 'form-group form-row'; // avoid requiring a class name prop.

    return <VendorLookupComponent
        columns={columns}
        results={this.state.results}
        selectedVendorId={this.state.selectedVendorId}
        defaultPageSize={this.state.defaultPageSize}
        showPagination={this.state.showPagination}
        message={this.props.message}
        buttonWrapClassName={buttonWrapClassName}
        handleSearchInputValueChange={this.handleSearchInputValueChange}
        handleVendorSearch={this.handleVendorSearch}
        handleVendorSelection={this.handleVendorSelection}
        handleConfirmVendorSelection={this.handleConfirmVendorSelection}
        handleCancelVendorSelection={this.handleCancelVendorSelection}
        showVendorIdField={this.props.showVendorIdField}
        filterBreathAlcohol={this.props.filterBreathAlcohol}
        requirePOCT={this.props.requirePOCT}
        distanceColumnVisible={this.state.zip && this.state.zip.length === 5}
    />
  }
}

VendorLookupContainer.propTypes = {
  handleConfirmVendorSelection: PropTypes.func,
  handleCancelVendorSelection: PropTypes.func
};

const mapDispatchToProps = {
  resetLoadingPanel: layoutOperations.resetLoadingPanel,
  showLoadingPanel: layoutOperations.showLoadingPanel,
  hideLoadingPanel: layoutOperations.hideLoadingPanel
};

export default connect(null, mapDispatchToProps)(VendorLookupContainer);