import types from './types';
import {combineReducers} from 'redux';

// =======================================================================================
//                                Vaccines Reports Tables
// =======================================================================================
const tableSettingsDefaultState = {
  testsTable: {
    sorting: [{ id: 'colTestDate', desc: true }],
    filtering: [],
    pageIndex: 0,
    pageSize: 10,
    recentOnly: false
  },
  eventsTable: {
    sorting: [{ id: 'colEventDate', desc: true }],
    filtering: [],
    pageIndex: 0,
    pageSize: 10,
    recentOnly: false
  }
};

const vaccineReportsTableSettingsReducer = (state = tableSettingsDefaultState, action) => {

  switch (action.type) {
    case types.SET_VACCINE_EVENT_REPORT_TABLE_PAGE_INDEX: 
      return {
        ...state,
        eventsTable: {
          ...state.eventsTable,
          pageIndex: action.payload.pageIndex
        }
      }
    case types.SET_VACCINE_EVENT_REPORT_PAGE_SIZE:
      return {
        ...state,
        eventsTable: {
          ...state.eventsTable,
          pageIndex: action.payload.pageIndex,
          pageSize: action.payload.pageSize
        }
      }

    case types.SET_VACCINE_EVENT_REPORT_SORTING:
      return {
        ...state,
        eventsTable: {
          ...state.eventsTable,
          sorting: [{
            id: action.payload.columnId,
            desc: action.payload.desc
          }]
        }
      }

    case types.SET_VACCINE_EVENT_REPORT_FILTERING:
      return {
        ...state,
        eventsTable: {
          ...state.eventsTable,
          filtering: action.payload.filterArray
        }
      }

    case types.SET_VACCINE_EVENT_REPORT_RECENT:
      return {
        ...state,
        eventsTable: {
          ...state.eventsTable,
          recentOnly: action.payload.recent
        }
      }

    case types.SET_VACCINE_TEST_REPORT_TABLE_PAGE_INDEX: 
      return {
        ...state,
        testsTable: {
          ...state.testsTable,
          pageIndex: action.payload.pageIndex
        }
      }

    case types.SET_VACCINE_TEST_REPORT_PAGE_SIZE:
      return {
        ...state,
        testsTable: {
          ...state.testsTable,
          pageIndex: action.payload.pageIndex,
          pageSize: action.payload.pageSize
        }
      }

    case types.SET_VACCINE_TEST_REPORT_SORTING:
      return {
        ...state,
        testsTable: {
          ...state.testsTable,
          sorting: [{
            id: action.payload.columnId,
            desc: action.payload.desc
          }]
        }
      }

    case types.SET_VACCINE_TEST_REPORT_FILTERING:
      return {
        ...state,
        testsTable: {
          ...state.testsTable,
          filtering: action.payload.filterArray
        }
      }

    case types.SET_VACCINE_TEST_REPORT_RECENT:
      return {
        ...state,
        testsTable: {
          ...state.testsTable,
          recentOnly: action.payload.recent
        }
      }

    default:
      return state;
  }
};

const tableSettingsReducers = combineReducers({
  vaccineReportsTables: vaccineReportsTableSettingsReducer
});

export default tableSettingsReducers;