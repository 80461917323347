import permissions from './config/permissions';
import asyncComponent from './AsyncComponent';

import NotFound from './components/general/not-found/NotFound';
import React from 'react';

const Home = asyncComponent(() => import('./components/home/HomeContainer'));
const TermsOfService = asyncComponent(() => import('./components/general/termsOfService/TermsOfServiceContainer'));
const PrivacyPolicy = asyncComponent(() => import('./components/general/privacyPolicy/PrivacyPolicyContainer'));
const Login = asyncComponent(() => import( './components/general/auth/Login/LoginContainer'));
const Profile = asyncComponent(() => import('./components/general/profile/ProfileContainer'));
const ChangePassword = asyncComponent(() => import('./components/general/auth/ChangePassword/ChangePassword'));
const AddClient = asyncComponent(() => import('./components/Concorde/AddClient/AddClientContainer'));
const CoverSheetInfo = asyncComponent(() => import('./components/Concorde/DQInternal/CoverSheetInfo/CoverSheetInfoContainer'));

const CustomerCareReps = asyncComponent(() => import('./components/Concorde/CustomerCareReps/CustomerCareRepsContainer'));
const AddCCRep = asyncComponent(() => import('./components/Concorde/CustomerCareReps/components/AddEditCCRep/AddEditCCRepContainer'));
const CCRepDetail = asyncComponent(() => import('./components/Concorde/CustomerCareReps/components/CCRepDetail/CCRepDetailContainer'));

const RandomsDashboard = asyncComponent(() => import('./components/randoms/Dashboard/RandomsDashboard'));
const PoolCreation = asyncComponent(() => import('./components/randoms/Dashboard/PoolCreation'));
const PoolReporting = asyncComponent(() => import('./components/randoms/Dashboard/PoolReporting/PoolReportingContainer'));
const PoolDrilldown = asyncComponent(() => import('./components/randoms/Dashboard/PoolReporting/poolDrilldownContainer/PoolDrilldownContainer'));
const TestingRates = asyncComponent(() => import('./components/randoms/Dashboard/TestingRates'));

const CMESupervisorDashboard = asyncComponent(() => import('./components/cme/CMESupervisorDashboard/cmeSupervisorDashboard'));
const CMEWorklists = asyncComponent(() => import('./components/cme/CMEWorklists/CMEWorklistsContainer'));
const CMEWork = asyncComponent(() => import('./components/cme/CMEWork/CMEWork'));
const CMEChaseList = asyncComponent(() => import('./components/cme/CMEChaseList/CMEChaseListContainer'));
const CMEChaseDetail = asyncComponent(() => import('./components/cme/CMEChaseList/components/CMEChaseDetail/CMEChaseDetailContainer'));

const DQInternalWorklists = asyncComponent(() => import('./components/Concorde/DQInternal/DQInternalWorklistsContainer'));
const DQInternalMatch = asyncComponent(() => import('./components/Concorde/DQInternal/SearchAndMatchForm/DQInternalSearchAndMatchFormContainer'));
const DQInternalReview = asyncComponent(() => import('./components/Concorde/DQInternal/Review/DQInternalReviewContainer'));
const DQInternalGetExternalDocsWorklist = asyncComponent(() => import('./components/Concorde/DQInternal/GetExternalDocsWorklist/GetExternalDocsWorklistContainer'));
const DQInternalGetExternalDocs = asyncComponent(() => import('./components/Concorde/DQInternal/GetExternalDocs/DQInternalGetExternalDocsContainer'));
const WaitingForCoversheetWorklist = asyncComponent(() => import('./components/Concorde/DQInternal/WaitingForCoversheetWorklist/WaitingForCoversheetWorklistContainer'));
const DQSupervisorDashboard = asyncComponent(() => import('./components/Concorde/DQInternal/SupervisorDashboard/DQSupervisorDashboardContainer'));

const EmployeeSearch = asyncComponent(() => import('./components/dqf/employees/common/EmployeeSearch/EmployeeSearchContainer'));
const EmployeeSearchResults = asyncComponent(() => import('./components/dqf/employees/EmployeeSearchResults/EmployeeSearchResultsContainer'));

const Compliance = asyncComponent(() => import('./components/dqf/compliance/ComplianceContainer'));
const DocumentAlerts = asyncComponent(() => import('./components/dqf/manage/alerts/components/documentAlerts/DocumentAlertsContainer'));
const DocReviewAlerts = asyncComponent(() => import('./components/dqf/manage/alerts/components/docReviewAlerts/DocReviewAlertsContainer'));
const MVRScoring = asyncComponent(() => import('./components/dqf/manage/alerts/components/MVRScoring/MVRScoring'));
const ApplicantAlerts = asyncComponent(() => import('./components/dqf/manage/alerts/components/applicantAlerts/ApplicantAlertsContainer'));
const StaleApplicantAlerts = asyncComponent(() => import('./components/dqf/manage/alerts/components/staleApplicantAlerts/StaleApplicantAlertsContainer'));
const CSAAlerts = asyncComponent(() => import('./components/dqf/manage/alerts/components/csaAlerts/CSAAlertsContainer'));
const DACAlerts = asyncComponent(() => import('./components/dqf/manage/alerts/components/dacAlerts/DACAlertsContainer'));
const RandomAlerts = asyncComponent(() => import('./components/dqf/manage/alerts/components/randomAlerts/RandomAlertsContainer'));
const OnboardingAlerts = asyncComponent(() => import('./components/dqf/manage/alerts/components/onboardingAlerts/OnboardingAlertsContainer'));
const ClearinghouseQueryAlerts = asyncComponent(() => import('./components/dqf/manage/alerts/components/clearinghouseQueryAlerts/ClearinghouseQueryAlerts'));
const EmployeeList = asyncComponent(() => import('./components/dqf/employees/EmployeeList/EmployeeListContainer'));
const EmployeeDetail = asyncComponent(() => import('./components/dqf/employees/EmployeeDetail/EmployeeDetailContainer'));
const AddEmployee = asyncComponent(() => import('./components/dqf/employees/AddEmployee/AddEmployeeContainer'));
const UploadRosterUpdates = asyncComponent(() => import('./components/dqf/employees/UploadRosterUpdates/UploadRosterUpdatesContainer'));
const UploadDocument = asyncComponent(() => import('./components/dqf/employees/UploadDocument/UploadDocumentContainer'));
const MissingDocumentWizard = asyncComponent(() => import('./components/dqf/employees/MissingDocumentWizard/MissingDocumentWizardContainer'));
const MergeEmployees = asyncComponent(() => import('./components/dqf/employees/EmployeeDetail/components/MergeEmployees/MergeEmployeesContainer'));
const OrderList = asyncComponent(() => import('./components/dqf/manage/Orders/OrdersAdminContainer'));
const OrderDetail = asyncComponent(() => import('./components/dqf/manage/Orders/OrderDetail/OrderDetailContainer'));
const Reports = asyncComponent(() => import('./components/dqf/manage/reports/Reports/ReportsContainer'));
const Report = asyncComponent(() => import('./components/dqf/manage/reports/Reports/Report/ReportContainer'));
const ContactUs = asyncComponent(() => import('./components/dqf/ContactUs/ContactUsComponent'));
const SiteLocations = asyncComponent(() => import('./components/dqf/manage/admin/SiteLocations/SiteLocationsContainer'));
const SiteLocationDetail = asyncComponent(() => import('./components/dqf/manage/admin/SiteLocationDetail/SiteLocationDetailContainer'));
const FileSetup = asyncComponent(() => import('./components/dqf/manage/admin/SiteLocationDetail/FileSetup/FileSetupContainer'));
const ManageUsers = asyncComponent(() => import('./components/common/user/UserList/UserListContainer'));
const UserDetail = asyncComponent(() => import('./components/common/user/UserDetail/UserDetailContainer'));
const Contact = asyncComponent(() => import('./components/dqf/manage/admin/ContactsNotifications/ContactNotificationsContainer'));
const Signup = asyncComponent(() => import('./components/general/auth/Signup/SignupContainer'));
const ResetPassword = asyncComponent(() => import('./components/general/auth/ResetPassword/ResetPassword'));
const ForgotPassword = asyncComponent(() => import('./components/general/auth/ForgotPassword/ForgotPassword'));

const CMESearchResults = asyncComponent(() => import("./components/cme/CMESearchResults/CMESearchResultsContainer"));
const AddUser = asyncComponent(() => import("./components/dqf/manage/admin/AddUser/AddUserContainer"));
const AddLocation = asyncComponent(() => import("./components/dqf/manage/admin/AddLocation/NewLocationContainer"));
const AddContact = asyncComponent(() => import("./components/dqf/manage/admin/AddNotification/AddNotificationContainer"));
const NotificationList = asyncComponent(() => import("./components/dqf/manage/admin/Notifications/NotificationListContainer"));
const Clients = asyncComponent(() => import("./components/Concorde/Clients/ClientsContainer"));
const ClientDetail = asyncComponent(() => import('./components/Concorde/Clients/components/ClientDetail/ClientDetailContainer'));
const AuditorLogin = asyncComponent(() => import("./components/general/auth/AuditorLogin/AuditorLoginContainer"));
const DriverAnnual = asyncComponent(() => import('./components/dqf/DriverAnnual/DriverAnnualContainer'));
const ViolationsForm = asyncComponent(() => import("./components/dqf/DriverAnnual/ViolationsForm/ViolationsFormContainer"));
const OnboardingEmployee = asyncComponent(() => import("./components/dqf/OnboardingEmployee/OnboardingEmployeeContainer"));
// const ElectronicDOTApplication = asyncComponent(() => import('./components/dqf/ElectronicDOTApplication/ElectronicDOTApplicationContainer'));
const ApplicantScreening = asyncComponent(() => import('./components/applicants/ApplicantScreening/ApplicantScreeningContainer/ApplicantScreeningContainer'));
const ImportEmployeeProfilePhotos = asyncComponent(() => import('./components/Concorde/ImportEmployeeProfilePhotos/ImportEmployeeProfilePhotosContainer'));
const JumpList = asyncComponent(() => import("./components/dqf/common/JumpList/JumpListContainer"));
const SupervisorReview = asyncComponent(() => import("./components/dqf/DriverAnnual/SupervisorReview/SupervisorReviewContainer"));

const DrugTestResults = asyncComponent(() => import('./components/DrugTestResults/DrugTestResultsContainer'));
const BATReview = asyncComponent(() => import('./components/BAT/BATReview/BATReview'));

const BackgroundCheckReportCopyRequests = asyncComponent(() => import('./components/applicants/BackgroundCheckReportCopyRequests/BackgroundCheckReportCopyRequestsContainer'));

/**
 * This object defines all of the routes in the app. It is used to generate the menu, the breadcrumbs,
 * and the main page content.
 *
 * It is an array; each entry should contain the following properties:
 *    - text: the text to display (leave blank if setting the text via Redux)
 *    - path: the path element for the route (incremental by default; see next property)
 *    - absolutePath: true if the path property is ABSOLUTE rather than incremental (default: false)
 *    - inMenu: true if the route should be shown in the menu (default is false)
 *    - menuHeading: true if the entry is a menu heading (default is false)
 *
 * An entry that is a menu heading may contain the following (optional) property,
 * to render a component just below the menu section heading (for example, an employee search):
 *    - component: the React component to render in the menu
 *
 * Otherwise, the entry is interpreted as a route and it may also contain the following (optional) properties:
 *    - badgeCalculator: a method that can be called to calculate a badge number to show next to the menu link
 *    - badgeCalculatorArguments: a list of argument names corresponding to Redux object to pass to the badge calculator
 *    - showBreadcrumbs: true if the breadcrumbs should be shown when this route is active
 *    - component: the React component to link to (for the main page)
 *    - exact: true if the Route must match the path exactly in order for the menu link to be "active"
 *    - permission: the permission that a user must have in order to access the route (and see the menu link)
 *    - needClient: if true, and user is a Concorde user, disable the entry if a client is not selected
 *    - isConcordeUser: if true, the entry will only be shown if the user is a Concorde user (in addition to the permissions required)
 *    - protected: true if the user needs to be logged in (but does not need any specific permission) to access the route
 *
 * It is also possible for a menu entry to invoke a modal instead of going to a page. For that option, do not set a
 * path or a component (set them to null); instead, set the following:
 *    - modal: the name of the modal to display via Redux (see /src/components/common/Modal/modalComponent.js)
 *
 * Note that a menu heading does not have a permission setting; it will be shown in the menu ONLY if one or more
 * of its "child" entries is shown (based on permissions).
 *
 * Also note that the "tail" of the breadcrumbs can be set via Redux; to allow this to happen, do NOT
 * set the text property.
 */
const routes = [
  {
    text: 'Home',
    path: '/',
    inMenu: true,
    showBreadcrumbs: true,
    component: Home,
    exact: true
  },
  {
    text: 'Terms of Service',
    path: '/termsOfService',
    component: TermsOfService,
    showBreadcrumbs: true
  },
  {
    text: 'Privacy Policy',
    path: '/privacyPolicy',
    component: PrivacyPolicy,
    showBreadcrumbs: true
  },
  {
    text: 'Driver Annual Certification',
    path: '/driverannual/:dacId',
    inMenu: false,
    showBreadcrumbs: false,
    component: DriverAnnual
  },
  {
    text: 'Employee Onboarding',
    path: '/onboardingEmployee/:onboardingId',
    inMenu: false,
    showBreadcrumbs: false,
    component: OnboardingEmployee
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/signup/:token',
    component: Signup
  },
  {
    path: '/auditor/:auditorToken',
    component: AuditorLogin
  },
  {
    path: '/resetPassword/:token',
    component: ResetPassword
  },
  {
    path: '/forgotPassword',
    component: ForgotPassword
  },
  {
    path: '/additionalinfo/:dqItemId',
    component: CoverSheetInfo
  },
  {
    text: 'User Profile',
    path: '/user/profile',
    showBreadcrumbs: true,
    component: Profile,
    protected: true,
    routes: [
      {
        text: 'Change Password',
        path: '/user/changepassword',
        absolutePath: true,
        showBreadcrumbs: true,
        component: ChangePassword,
        protected: true
      }
    ]
  },
  {
    text: 'Clients',
    path: '/clients',
    inMenu: true,
    showBreadcrumbs: true,
    component: Clients,
    permission: permissions.customerCare.viewClients,
    routes: [
      {
        text: 'Add Client',
        path: '/action/addClient',
        showBreadcrumbs: true,
        component: AddClient,
        permission: permissions.admin.addClient
      },
      {
        path: '/:clientId',
        showBreadcrumbs: true,
        component: ClientDetail,
        permission: permissions.customerCare.viewClients
      }
    ]
  },
  {
    text: 'Customer Care Reps',
    path: '/customercarereps',
    inMenu: true,
    showBreadcrumbs: true,
    component: CustomerCareReps,
    permission: permissions.admin.viewCCRs,
    routes: [
      {
        path: '/action/addccrep',
        text: 'Create Customer Care Rep',
        showBreadcrumbs: true,
        component: AddCCRep,
        permission: permissions.admin.addCCR
      },
      {
        path: '/detail/:repId',
        showBreadcrumbs: true,
        component: CCRepDetail,
        permission: permissions.admin.viewCCRs,
        routes: [
          {
            path: '/edit',
            text: 'Edit',
            showBreadcrumbs: true,
            component: AddCCRep,
            permission: permissions.admin.configureCCR
          }
        ]
      }
    ]
  },
  {
    text: 'Internal User Management',
    path: '/concordeUsers',
    inMenu: true,
    showBreadcrumbs: true,
    component: ManageUsers,
    permission: permissions.admin.viewUsers,
    routes: [
      {
        path: '/action/adduser',
        text: 'Add User',
        showBreadcrumbs: true,
        component: AddUser,
        permission: permissions.client.addUser
      },
      {
        path: '/:userId',
        showBreadcrumbs: true,
        component: UserDetail,
        permission: permissions.admin.viewInternalUser
      }
    ]
  },
  {
    text: '',
    path: '/cme',
    inMenu: true,
    menuHeading: true,
    routes: [
      {
        text: 'CME Supervisor Dashboard',
        path: '/dashboard',
        inMenu: true,
        showBreadcrumbs: true,
        component: CMESupervisorDashboard,
        exact: true,
        permission: permissions.cme.dashboard
      },
      {
        text: <div><i className="fad fa-file-search"/> CME Tool</div>,
        path: null,
        modal: 'CMEResearch',
        inMenu: true,
        component: null,
        exact: true,
        permission: permissions.cme.research
      },
      {
        text: 'CME Worklists',
        path: '/workflow',
        inMenu: true,
        showBreadcrumbs: true,
        component: CMEWorklists,
        permission: permissions.cme.worklists,
        routes: [
          {
            path: '/:processInstanceId',
            showBreadcrumbs: true,
            component: CMEWork,
            permission: permissions.cme.worklists
          },
          {
            path: '/detail/:cmeId',
            showBreadcrumbs: true,
            component: CMEWork,
            permission: permissions.cme.viewDetail
          }
        ]
      },
      {
        text: 'Chase List',
        path: '/chase',
        inMenu: true,
        showBreadcrumbs: true,
        component: CMEChaseList,
        permission: permissions.cme.chaseList,
        routes: [
          {
            path: '/:medicalExamOrderId/:orderId',
            showBreadcrumbs: true,
            component: CMEChaseDetail,
            permission: permissions.cme.chaseList
          }
        ]
      },
      {
        text: 'Customer Care Intervention',
        path: '/customercare',
        inMenu: true,
        showBreadcrumbs: true,
        component: CMEWorklists,
        exact: true,
        permission: permissions.cme.customerCareIntervention,
        badgeCalculatorArguments: ['auth', 'cme'],
        badgeCalculator: ({auth, cme}) => {
          let badgeNumber = 0;
          if (auth && auth.user && cme.taskLists && cme.taskLists.taskListData && cme.taskLists.taskListData.length > 0) {
            badgeNumber = cme.taskLists.taskListData.filter(task => task.customerCareRep === auth.user.email && task.needsCCIntervention).length;
          }
          return badgeNumber;
        },
        routes: [
          {
            path: '/:processInstanceId',
            showBreadcrumbs: true,
            component: CMEWork,
            permission: permissions.cme.customerCareIntervention
          }
        ]
      },
      {
        text: 'CME Search Results',
        path: '/results',
        showBreadcrumbs: true,
        component: CMESearchResults,
        permission: permissions.cme.search
      }
    ]
  },
  {
    text: 'Document Alerts',
    path: '/alerts/documentAlerts',
    showBreadcrumbs: true,
    component: DocumentAlerts,
    permission: permissions.client.viewAlerts
  },
  {
    text: 'MVR Alerts',
    path: '/alerts/docReviewAlerts',
    showBreadcrumbs: true,
    component: DocReviewAlerts,
    permission: permissions.client.viewAlerts
  },
  {
    text: 'MVR Scoring',
    path: '/alerts/MVRScoring',
    showBreadcrumbs: true,
    component: MVRScoring,
    permission: permissions.client.viewAlerts
  },
  {
    text: 'Applicants Pending More than 60 Days',
    path: '/alerts/staleApplicants',
    showBreadcrumbs: true,
    component: StaleApplicantAlerts,
    permission: permissions.client.viewAlerts
  },
  {
    text: 'CSA Alerts',
    path: '/alerts/csaAlerts',
    showBreadcrumbs: true,
    component: CSAAlerts,
    permission: permissions.client.viewAlerts
  },
  {
    text: 'Driver Annual Certifications',
    path: '/alerts/dacAlerts',
    showBreadcrumbs: true,
    component: DACAlerts,
    permission: permissions.client.viewAlerts
  },
  {
    text: 'Open Randoms',
    path: '/alerts/randomAlerts',
    showBreadcrumbs: true,
    component: RandomAlerts,
    permission: permissions.client.viewAlerts
  },
  {
    text: 'Open Onboarding Employees',
    path: '/alerts/onboardingEmployees',
    showBreadcrumbs: true,
    component: OnboardingAlerts,
    permission: permissions.client.viewAlerts
  },
  {
    text: 'Applications and Background Checks',
    path: '/alerts/applicants',
    showBreadcrumbs: true,
    component: ApplicantAlerts,
    permission: permissions.client.viewAlerts
  },
  {
    text: 'Clearinghouse Queries',
    path: '/alerts/clearinghouseQueryAlerts',
    showBreadcrumbs: true,
    component: ClearinghouseQueryAlerts,
    permission: permissions.servicesClearinghouse.orderClearinghouseQueries
  },
  {
    text: '',
    path: '/dqInternal',
    inMenu: true,
    menuHeading: true,
    routes: [
      {
        text: 'DQ Worklists',
        path: '/workflow',
        inMenu: true,
        showBreadcrumbs: true,
        component: DQInternalWorklists,
        permission: permissions.dq.worklists,
        routes: [
          {
            path: '/match/:processInstanceId',
            showBreadcrumbs: true,
            component: DQInternalMatch,
            permission: permissions.dq.searchAndMatch
          },
          {
            path: '/review/:processInstanceId',
            showBreadcrumbs: true,
            component: DQInternalReview,
            permission: permissions.dq.review
          },
          {
            text: 'Get External Files',
            path: '/getexternaldocs',
            showBreadcrumbs: true,
            component: DQInternalGetExternalDocsWorklist,
            permission: permissions.dq.getExternalDocs,
            routes: [
              {
                path: '/:processInstanceId',
                showBreadcrumbs: true,
                component: DQInternalGetExternalDocs,
                permission: permissions.dq.getExternalDocs
              }
            ]
          },
          {
            text: 'Waiting for Cover Sheet',
            path: '/waitingforcoversheet',
            showBreadcrumbs: true,
            component: WaitingForCoversheetWorklist,
            permission: permissions.dq.review
          }
        ]
      },
      {
        text: 'DQ Supervisor Dashboard',
        path: '/dashboard',
        inMenu: true,
        showBreadcrumbs: true,
        component: DQSupervisorDashboard,
        exact: true,
        permission: permissions.dq.dashboard
      }
    ]
  },
  {
    text: 'Background Check Report Copy Requests',
    path: '/backgroundcheckreportcopyrequests',
    inMenu: true,
    showBreadcrumbs: true,
    component: BackgroundCheckReportCopyRequests,
    permission: permissions.client.viewEmployeeDataReports,
    isConcordeUser: true
  },
  {
    text: '',
    path: '/manage',
    inMenu: true,
    menuHeading: true,
    sectionComponents: [
      {component: EmployeeSearch, permission: permissions.client.viewEmployee},
      {component: JumpList, permission: permissions.client.viewEmployee}
    ],
    routes: [
      {
        text: 'Roster',
        path: '/employees',
        inMenu: true,
        needClient: true,
        showBreadcrumbs: true,
        component: EmployeeList,
        permission: permissions.client.viewRoster,
        routes: [
          {
            path: '/:employeeId',
            showBreadcrumbs: true,
            component: EmployeeDetail,
            permission: permissions.client.viewEmployee,
            routes: [
              {
                text: 'Upload a Document',
                path: '/upload',
                showBreadcrumbs: true,
                component: UploadDocument,
                permission: permissions.client.uploadDocuments
              },
              {
                text: 'Missing Document Wizard',
                path: '/missing',
                showBreadcrumbs: true,
                component: MissingDocumentWizard,
                permission: permissions.client.uploadDocuments
              },
              {
                text: 'Merge Employees',
                path: '/merge',
                showBreadcrumbs: true,
                component: MergeEmployees,
                permission: permissions.client.mergeEmployees
              }
            ]
          },
          {
            //this needs to be located here as opposed to the routes array above for '/:employeeId' b/c of issues with the breadcrumbs if placed there
            path: '/:employeeId/tab/:tabToOpen',
            showBreadcrumbs: true,
            component: EmployeeDetail,
            permission: permissions.client.viewEmployee
          },
          {
            text: 'Add a New Employee',
            path: '/add/:clientId',
            showBreadcrumbs: true,
            component: AddEmployee,
            permission: permissions.client.addEmployee
          },
          {
            text: 'Upload Roster Updates',
            path: '/roster/update',
            showBreadcrumbs: true,
            component: UploadRosterUpdates,
            permission: permissions.client.uploadRosterUpdates
          }
        ]
      },
      {
        text: 'Employee Search Results',
        path: '/employeesearch',
        showBreadcrumbs: true,
        component: EmployeeSearchResults,
        permission: permissions.client.viewEmployee
      },
      {
        text: 'Applicants',
        path: '/alerts/applicants',
        inMenu: true,
        needClient: true,
        showBreadcrumbs: true,
        component: ApplicantAlerts,
        permission: permissions.client.viewApplicants
      },
      {
        text: 'Compliance',
        path: '/compliance',
        inMenu: true,
        needClient: true,
        showBreadcrumbs: true,
        component: Compliance,
        permission: permissions.client.viewAlerts
      },
      {
        text: 'Orders',
        path: '/orders',
        inMenu: true,
        needClient: true,
        showBreadcrumbs: true,
        component: OrderList,
        permission: permissions.client.viewOrders,
        routes: [
          {
            path: '/:orderId',
            showBreadcrumbs: true,
            component: OrderDetail,
            permission: permissions.client.viewOrders
          }
        ]
      },
      {
        text: 'Reports',
        path: '/reports',
        inMenu: true,
        needClient: true,
        showBreadcrumbs: true,
        component: Reports,
        permission: permissions.client.viewEmployeeDataReports,
        routes: [
          {
            path: '/:reportName',
            showBreadcrumbs: true,
            component: Report,
            permission: permissions.client.viewEmployeeDataReports
          }
        ]
      },
      {
        text: 'Locations',
        path: '/admin/locations',
        inMenu: true,
        needClient: true,
        showBreadcrumbs: true,
        component: SiteLocations,
        permission: permissions.client.viewLocation,
        routes: [
          {
            path: '/action/addlocation',
            showBreadcrumbs: true,
            component: AddLocation,
            permission: permissions.client.addLocation
          },
          {
            path: '/:clientId',
            showBreadcrumbs: true,
            component: SiteLocationDetail,
            permission: permissions.client.viewLocation,
            routes: [
              {
                text: 'File Setup',
                path: '/fileSetup',
                showBreadcrumbs: true,
                component: FileSetup,
                permission: permissions.client.viewLocation
              }
            ]
          }
        ]
      },
      {
        text: 'Users',
        path: '/admin/users',
        inMenu: true,
        needClient: true,
        showBreadcrumbs: true,
        component: ManageUsers,
        permission: permissions.client.viewUsers,
        routes: [
          {
            path: '/action/adduser',
            text: 'Add User',
            showBreadcrumbs: true,
            component: AddUser,
            permission: permissions.client.addUser
          },
          {
            path: '/:userId',
            showBreadcrumbs: true,
            component: UserDetail,
            permission: permissions.client.configureUser
          }
        ]
      },
      {
        text: 'Notifications',
        path: '/admin/notifications',
        inMenu: true,
        needClient: true,
        showBreadcrumbs: true,
        component: NotificationList,
        permission: permissions.client.configureNotifications,
        routes: [
          {
            path: '/:email',
            showBreadcrumbs: true,
            component: Contact,
            permission: permissions.client.configureNotifications
          },
          {
            path: '/action/addcontact',
            showBreadcrumbs: true,
            component: AddContact,
            permission: permissions.client.configureNotifications
          }
        ]
      },
      {
        text: 'Driver Annual Certification',
        path: '/driverannual/:dacId',
        inMenu: false,
        showBreadcrumbs: true,
        component: ViolationsForm
      },
      {
        text: 'Review Driver Annual Certification',
        path: '/driverannual/review/:dacId',
        inMenu: false,
        showBreadcrumbs: true,
        component: SupervisorReview,
        permissions: permissions.client.viewAlerts
      },
      {
        text: 'Employee Onboarding',
        path: '/onboardingEmployee/:onboardingId',
        inMenu: false,
        showBreadcrumbs: true,
        component: OnboardingEmployee
      }
    ]
  },
  {
    text: 'Drug Testing',
    path: '/drugtesting',
    inMenu: true,
    menuHeading: true,
    routes: [
      {
        text: 'Drug Test Results',
        path: '/drugtestresults',
        inMenu: true,
        needClient: true,
        showBreadcrumbs: true,
        component: DrugTestResults,
        permission: permissions.client.viewOrders
      },
      {
        text: 'BAT Review',
        path: '/batreview',
        inMenu: true,
        showBreadcrumbs: true,
        component: BATReview,
        permission: permissions.customerCare.assumeClient
      }
    ]
  },
  {
    text: 'Randoms',
    path: '/randoms',
    inMenu: true,
    menuHeading: true,
    routes: [
      {
        text: 'Roster Management',
        path: '/roster',
        inMenu: true,
        showBreadcrumbs: true,
        component: RandomsDashboard,
        permission: permissions.servicesRandoms.viewSelections
      },
      {
        text: 'Pool Management/Creation',
        path: '/poolmanagement',
        inMenu: true,
        showBreadcrumbs: true,
        component: PoolCreation,
        permission: permissions.servicesRandoms.viewConfigurePools
      },
      {
        text: 'Compliance/Reporting',
        path: '/poolreporting',
        inMenu: true,
        showBreadcrumbs: true,
        component: PoolReporting,
        permission: permissions.servicesRandoms.viewSelections,
        routes: [
          {
            path: '/:clientId/:poolId',
            showBreadcrumbs: true,
            component: PoolDrilldown,
            permission: permissions.servicesRandoms.viewSelections
          }
        ]
      },
      {
        text: 'View Testing Rates',
        path: '/testingrates',
        inMenu: true,
        showBreadcrumbs: true,
        component: TestingRates,
        permission: permissions.servicesRandoms.viewConfigurePools
      }
    ]
  },
  {
    text: 'Applicant Screening',
    path: '/application/:applicantId',
    inMenu: false,
    showBreadcrumbs: true,
    component: ApplicantScreening
  },
  {
    text: 'Import Employee Profile Photos',
    path: '/importPhotos',
    showBreadcrumbs: true,
    component: ImportEmployeeProfilePhotos,
    permission: permissions.admin.superAdmin,
    isConcordeUser: true
  },
  {
    text: 'Score MVRs Manually',
    path: '/scoremvrs',
    inMenu: true,
    showBreadcrumbs: true,
    component: MVRScoring,
    permission: permissions.dq.scoreMVRsManually,
    isConcordeUser: true,
    badgeCalculatorArguments: ['auth', 'dqf'],
    badgeCalculator: ({auth, dqf}) => {
      let badgeNumber = 0;
      if (auth && auth.user && dqf && dqf.unscoredMVRs && dqf.unscoredMVRs.length > 0) {
        badgeNumber = dqf.unscoredMVRs.length;
      }
      return badgeNumber;
    },
  },
  {
    text: '',
    path: '',
    inMenu: true,
    externalOnly: true,
    menuHeading: true,
    routes: [
      {
        text: 'Contact Us',
        path: '/contact',
        showBreadcrumbs: true,
        component: ContactUs
      }
    ]
  },
  {
    path: '*',
    protected: true,
    component: NotFound
  }
];

export default routes;