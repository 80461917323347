import actions from './actions';

// We could just call these table dispatches directly, but I think this is technically best practice
// =======================================================================================
//                                Vaccines Reports Tables
// =======================================================================================
// event table
const updateVaccEventReportTablePageIndex = (table, pageIndex) => (dispatch) => {
  dispatch(actions.setVaccEventReportTableIndex(table, pageIndex));
}

const updateVaccEventReportTablePageSize = (table, pageIndex, pageSize) => (dispatch) => {
  dispatch(actions.setVaccEventReportTablePageSize(table, pageIndex, pageSize));
}

const updateVaccEventReportTableTableSorting = (table, columnId, desc) => (dispatch) => {
  dispatch(actions.setVaccEventReportTableSorting(table, columnId, desc));
}

const updateVaccEventReportTableTableFiltering = (table, filterArray) => (dispatch) => {
  dispatch(actions.setVaccEventReportTableFiltering(table, filterArray));
}

const updateVaccEventReportTableTableRecent = (recent) => (dispatch) => {
  dispatch(actions.setVaccEventReportRecent(recent));
}
// test table
const updateVaccTestReportTablePageIndex = (table, pageIndex) => (dispatch) => {
  dispatch(actions.setVaccTestReportTableIndex(table, pageIndex));
}

const updateVaccTestReportTablePageSize = (table, pageIndex, pageSize) => (dispatch) => {
  dispatch(actions.setVaccTestReportTablePageSize(table, pageIndex, pageSize));
}

const updateVaccTestReportTableTableSorting = (table, columnId, desc) => (dispatch) => {
  dispatch(actions.setVaccTestReportTableSorting(table, columnId, desc));
}

const updateVaccTestReportTableTableFiltering = (table, filterArray) => (dispatch) => {
  dispatch(actions.setVaccTestReportTableFiltering(table, filterArray));
}

const updateVaccTestReportTableTableRecent = (recent) => (dispatch) => {
  dispatch(actions.setVaccTestReportRecent(recent));
}

export default {
  // Vaccines Reports Tables
  updateVaccEventReportTablePageIndex,
  updateVaccEventReportTablePageSize,
  updateVaccEventReportTableTableSorting,
  updateVaccEventReportTableTableFiltering,
  updateVaccEventReportTableTableRecent,
  updateVaccTestReportTablePageIndex,
  updateVaccTestReportTablePageSize,
  updateVaccTestReportTableTableSorting,
  updateVaccTestReportTableTableFiltering,
  updateVaccTestReportTableTableRecent
};