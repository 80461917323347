import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import axios from "axios";
import {modalOperations} from "../../../../../../../../ducks/modal";
import DrugAlcoholQuestionModal from './drugAlcoholQuestionModalComponent';
import DrugAlcoholUploadModalComponent from './drugAlcoholUploadModalComponent';
import {alertsOperations} from "../../../../../../../../ducks/alerts";
import _ from 'lodash';
import async from 'async';
import {Rnd} from "react-rnd";
import ConfirmActionModal from '../../../../../../../common/ConfirmActionModal/ConfirmActionModal';
import {layoutOperations} from '../../../../../../../../ducks/layout';

class DrugAlcoholQuestionModalContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cancelConfirmationPrompt: false,
      showQ4Details: false,
      showQ6Details: false,
      disableSave: true,
      q4ReasonsRed: false,
      q6ViolationsRed: false,
      isUploading: false,
      hasFMCSAService: false,
      fileList: [],
      checklist: {}
    };
  }

  componentWillUnmount() {
    this.cancelSource.cancel('userCanceled');
  }

  componentDidMount() {
    this.cancelSource = axios.CancelToken.source();
    const hasFMCSAService = this.props.auth.user.client.services.some(service => service.name === 'FMCSA-Clearinghouse')
    this.setState({hasFMCSAService});
  }

  //six questions. question 4 has 10 possible sub part responses, question 6 has 4 possible sub part responses
  _questionList = ["q1", "q2", "q3", "q4", "q5", "q6", "q61", "q62", "q63", "q64",
    "q41", "q42", "q43", "q44", "q45", "q46", "q47", "q48", "q49", "q410"];

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.auth.user.client.legacyClientId !== prevProps.auth.user.client.legacyClientId) {
      this.setState({hasFMCSAService: this.props.auth.user.client.services.some(service => service.name === 'FMCSA-Clearinghouse')})
    }
  }

  //If the wizard is at the uploading step then do the upload and submit, otherwise move to the upload step.
  handleSaveClick = () => {
    if (this.state.isUploading) {
      //this is the upload screen, so upload and submitQuestions
      this.props.showLoadingPanel();
      this.handleUpload().then(() => {
        this.submitQuestions().then((response) => {
          this.props.hideModal(response, []);
          this.props.hideLoadingPanel();
        }).catch(err => {
          this.props.hideLoadingPanel();
        });
      }).catch(err => {
        this.props.hideLoadingPanel();
      });
    } else {
      //there should only be one service and bucket in the doc list - the one that pertains to drug and alcohol, but we
      //need to drill down to the actual document level to get the doc type information
      const docType = this.props.info.documentList && this.props.info.documentList.length > 0 && this.props.info.documentList[0].children && this.props.info.documentList[0].children.length > 0
      && this.props.info.documentList[0].children[0].children && this.props.info.documentList[0].children[0].children.length > 0 ? this.props.info.documentList[0].children[0].children[0] : null;
      this.setState({isUploading: true, docType});
    }
  };

  //submit the changes.
  submitQuestions = () => {
    return new Promise(resolve => {

      if (this.nothingChecked()) {
        return resolve(false);
      }
      //take the checklist information and make it into an array that just holds the numbers of the questions that the
      //user answered yes to, that is what the database expects
      const positiveQuestionList = [];
      Object.keys(this.state.checklist).forEach((theKey) => {
        if (this.state.checklist[theKey]) {
          positiveQuestionList.push(parseInt(theKey.substring(1)));//trim off the q and just put the number into the array
        }
      });
      axios.put(`/v1/employees/${this.props.info.employeeId}/drugAlcoholInfo?forUser=${this.props.auth.user._id}`, {
        positiveQuestionList
      }, {cancelToken: this.cancelSource.token})
      .then(() => {
        this.props.addAlert('Drug and alcohol questionnaire information saved successfully');
        return resolve(true);
      })
      .catch(err => {
        console.log('failed to update employee', err);
        this.props.addAlert('Drug and alcohol questionnaire information could not be saved.', 'danger');
        return resolve(false);
      });
    });
  };

  /*
  check if anything was marked yes yet
   */
  nothingChecked = () => {
    const {checklist} = this.state;
    let nothingChecked = true;
    this._questionList.forEach((field) => {
      nothingChecked = nothingChecked && !checklist[field];
    });
    return nothingChecked;
  };

  handleChecklistChange = e => {
    const checklist = _.cloneDeep(this.state.checklist);
    checklist[e.target.id] = !checklist[e.target.id];
    let {showQ4Details, showQ6Details} = this.state;
    if (e.target.name === 'q6') {
      if (showQ6Details) { //if we were showing the sub fields and it was just turned off, reset the sub fields
        this._questionList.forEach((field) => {
          if (field.charAt(1) === '6') {
            checklist[field] = false;
          }
        });
      }
      showQ6Details = !showQ6Details;
    }
    if (e.target.name === 'q4') {
      if (showQ4Details) { //if we were showing the sub fields and it was just turned off, reset the sub fields
        this._questionList.forEach((field) => {
          if (field.charAt(1) === '4') {
            checklist[field] = false;
          }
        });
      }
      showQ4Details = !showQ4Details;
    }
    this.validate(checklist);
    this.setState({checklist, showQ4Details, showQ6Details});
  };

  validate = (checklist) => {
    //if q4 but no reason
    const q4ReasonsRed = checklist.q4 && !checklist.q41 && !checklist.q42
        && !checklist.q43 && !checklist.q44 && !checklist.q45
        && !checklist.q46 && !checklist.q47 && !checklist.q48
        && !checklist.q49 && !checklist.q410;
    //if q6 but no violation
    const q6ViolationsRed = checklist.q6 && !checklist.q61 && !checklist.q62
        && !checklist.q63 && !checklist.q64;
    const noneSelected = !checklist.q1 && !checklist.q2 && !checklist.q3 && !checklist.q4 && !checklist.q5 && !checklist.q6;
    this.setState({disableSave: q4ReasonsRed || q6ViolationsRed || noneSelected, q4ReasonsRed, q6ViolationsRed})
  };

  handleCancel = e => {
    this.setState({cancelConfirmationPrompt: true});
  };

  handleConfirmCancel = e => {
    this.props.hideModal(true);
    this.setState({
      cancelConfirmationPrompt: false,
      isUploading: false,
      fileList: [],
      showQ4Details: false,
      showQ6Detail: false,
      checklist: {}
    });
  };

  handleCancelCancel = e => {
    this.setState({cancelConfirmationPrompt: false});
  };

  //When the user chooses to go back to the questionnaire, clear the fileList
  handleGoBack = e => {
    this.setState({isUploading: false, fileList: []})
  };

  //when a new file, or group of files is dropped into the drop zone, add it to the list to be uploaded.
  handleFileSelect = acceptedFiles => {
    const fileList = this.state.fileList.concat(acceptedFiles);
    this.setState({fileList});
  };

  //loop through the list of files to be uploaded and upload each one.
  handleUpload = () => {
    return new Promise(resolve => {
      const docType = _.cloneDeep(this.state.docType);
      if (!docType.docTypeId) {
        const docTypesFound = this.props.info.availableDocTypes.filter(dt => dt.docCategoryId === docType.categoryId);
        if (docTypesFound.length === 1) {
          docType.docTypeId = docTypesFound[0].docTypeId;
        }
      }

      async.eachSeries(this.state.fileList, (file, cb) => {
            const data = new FormData();
            data.append('document', file);
            data.append('docCategoryId', docType.categoryId);
            data.append('docTypeId', docType.docTypeId);
            data.append('uploadedBy', `web_${this.props.auth.user.firstName}${this.props.auth.user.lastName}`);
            data.append('docDate', new Date());
            axios
            .post(`/v1/employees/${this.props.info.employeeId}/documents`, data, {cancelToken: this.cancelSource.token})
            .then(() => { //alert success and clear fields
              cb();
            })
            .catch(err => {
              cb(err);
            });
          },
          err => {
            if (err) {
              console.error(err);
              this.props.addAlert(`Failed to upload ${this.state.fileList.length > 1 ? 'one or more documents' : 'the document'}`, 'danger');
              resolve();
            } else {
              if (this.state.fileList.length > 0) {
                this.props.addAlert(`Saved document${this.state.fileList.length > 1 ? 's' : ''} successfully`);
              }
              this.setState({
                fileList: [],
                docType: null,
                changesPresent: false
              });
              resolve();
            }
          });
    });
  };

  render() {
    const {
      checklist, cancelConfirmationPrompt, showQ4Details, showQ6Details,
      disableSave, q4ReasonsRed, q6ViolationsRed, hasFMCSAService,
      isUploading, docType, fileList
    } = this.state;

    return (
        <Fragment>
          <Modal isOpen={true} className={'large-modal'}>
            <Rnd dragHandleClassName={'modal-header'} enableResizing={false}>
              <ModalHeader className={'cursor-move'}>
                <div>Drug and Alcohol Questionnaire
                  - {this.props.info.employeeInfo.firstName} {this.props.info.employeeInfo.lastName}</div>
                {!isUploading && <small>Only click yes to questions that apply to this Driver</small>}
                {isUploading && docType && <small>Uploading Documents of Type: {docType ? docType.item : ''}</small>}
              </ModalHeader>
              <ModalBody>
                {!isUploading && <DrugAlcoholQuestionModal onChecklistChange={this.handleChecklistChange}
                                                           checklist={checklist}
                                                           showQ4Details={showQ4Details}
                                                           showQ6Details={showQ6Details}
                                                           q4ReasonsRed={q4ReasonsRed}
                                                           q6ViolationsRed={q6ViolationsRed}
                />}
                {isUploading && <DrugAlcoholUploadModalComponent
                    checklist={checklist}
                    docType={docType}
                    employeeId={this.props.info.employeeId}
                    handleFileSelect={this.handleFileSelect}
                    fileList={this.state.fileList}
                />}
              </ModalBody>
              <ModalFooter className={'d-flex justify-content-between'}>
                {!isUploading &&
                <button className={'btn btn-secondary btn-sm'} id='copy' onClick={this.handleCancel}>Cancel
                </button>}
                {isUploading &&
                <button className={'btn btn-secondary btn-sm'} id='copy' onClick={this.handleGoBack}>Go Back
                </button>}
                <div>
                  {isUploading && <small className={'text-danger pr-2'}>Once
                    you save, a yes answer cannot be changed!
                  </small>}
                  <button className={'btn btn-primary btn-sm'} id='copy' onClick={this.handleSaveClick}
                          disabled={disableSave || (isUploading && hasFMCSAService && (!fileList || fileList.length === 0))}>{!isUploading ? 'Next' : hasFMCSAService || (fileList && fileList.length > 0) ? 'Save and Upload' : 'Save Responses'}
                  </button>
                </div>
              </ModalFooter>
            </Rnd>
          </Modal>
          {cancelConfirmationPrompt &&
          <ConfirmActionModal
              modalHeaderText={'Are You Sure?'}
              modalBodyText={'This will discard any changes you have made.'}
              confirmText={'Yes - discard changes'}
              cancelText={'No - stay on this page'}
              onConfirm={this.handleConfirmCancel}
              onCancel={this.handleCancelCancel}
          />}
        </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = {
  hideModal: modalOperations.hideModal,
  addAlert: alertsOperations.addAlert,
  showLoadingPanel: layoutOperations.showLoadingPanel,
  hideLoadingPanel: layoutOperations.hideLoadingPanel
};

export default connect(mapStateToProps, mapDispatchToProps)(DrugAlcoholQuestionModalContainer);