const step2DefaultState = {
  byEmploymentStatus: false,
  byEmploymentStatusSelectionValues: [],
  byJobTitle: false,
  byJobTitleSelectionValues: [],
  bySafetyCode: false,
  bySafetyCodeSelectionValues: [],
  // there is no 'safetyCodeValuesForDropdown' property here. This is intentional. The safety code dropdown values come from from the 'safetyCodes.json' file.
  employmentStatusValuesForDropdown: [],
  jobTitleValuesForDropdown: []
};

export default step2DefaultState;