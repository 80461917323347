import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import MenuHeading from '../MenuHeading/MenuHeadingContainer';
import MenuEntryComponent from './menuEntryComponent';
import {modalOperations} from '../../../../../ducks/modal';

class MenuEntryContainer extends Component {

  handleShowModal = modal => {
    this.props.showModal(modal);
  };

  render() {
    const {path, entry} = this.props;
    // Only render if the entry belongs in the menu
    if (!entry.inMenu) {
      return null;
    }
    // If this entry is a menu heading, use the heading component
    if (entry.menuHeading) {
      return (
          <MenuHeading path={path} section={entry}/>
      );
    }
    const user = this.props.reduxState.auth.user;
    // If user does not have permission, do not render
    if (entry.permission && user.permissions.indexOf(entry.permission) === -1) {
      return null;
    }
    if (entry.isConcordeUser && !user.isConcordeUser) {
      return null;
    }
    // See if Concorde user is logged in and no client selected in session
    const sessionClient = this.props.reduxState.dqf.sites.sessionClient;
    const needClient = entry.needClient && user.isConcordeUser && !sessionClient;
    let badgeNumber = null;
    if (entry.badgeCalculator) {
      // Go through the list of arguments needed by the badge calculator, and copy them from the Redux state into an object
      const badgeCalculatorArguments = (entry.badgeCalculatorArguments || []).reduce((argAcccumulator, argument) => {
        argAcccumulator[argument] = this.props.reduxState[argument];
        return argAcccumulator;
      }, {});
      badgeNumber = entry.badgeCalculator(badgeCalculatorArguments);
    }
    return (
        <MenuEntryComponent
            path={path}
            entry={entry}
            badgeNumber={badgeNumber}
            needClient={needClient}
            onShowModal={this.handleShowModal}
            indent={this.props.indent}
        />
    );
  }
}

const mapStateToProps = state => ({
  reduxState: state
});

const mapDispatchToProps = {
  showModal: modalOperations.showModal
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuEntryContainer));