import types from './types';

// =======================================================================================
//                                Vaccines Reports Tables
// =======================================================================================
// Events table
const setVaccEventReportTableIndex = (table, pageIndex) => ({
  type: types.SET_VACCINE_EVENT_REPORT_TABLE_PAGE_INDEX,
  payload: {table, pageIndex}
});

const setVaccEventReportTablePageSize = (table, pageIndex, pageSize) => ({
  type: types.SET_VACCINE_EVENT_REPORT_PAGE_SIZE,
  payload: {table, pageIndex, pageSize}
});

const setVaccEventReportTableSorting = (table, columnId, desc) => ({
  type: types.SET_VACCINE_EVENT_REPORT_SORTING,
  payload: {table, columnId, desc}
});

const setVaccEventReportTableFiltering = (table, filterArray) => ({
  type: types.SET_VACCINE_EVENT_REPORT_FILTERING,
  payload: {table, filterArray}
});

const setVaccEventReportRecent = (recent) => ({
  type: types.SET_VACCINE_EVENT_REPORT_RECENT,
  payload: {recent}
});
// Tests Table
const setVaccTestReportTableIndex = (table, pageIndex) => ({
  type: types.SET_VACCINE_TEST_REPORT_TABLE_PAGE_INDEX,
  payload: {table, pageIndex}
});

const setVaccTestReportTablePageSize = (table, pageIndex, pageSize) => ({
  type: types.SET_VACCINE_TEST_REPORT_PAGE_SIZE,
  payload: {table, pageIndex, pageSize}
});

const setVaccTestReportTableSorting = (table, columnId, desc) => ({
  type: types.SET_VACCINE_TEST_REPORT_SORTING,
  payload: {table, columnId, desc}
});

const setVaccTestReportTableFiltering = (table, filterArray) => ({
  type: types.SET_VACCINE_TEST_REPORT_FILTERING,
  payload: {table, filterArray}
});

const setVaccTestReportRecent = (recent) => ({
  type: types.SET_VACCINE_TEST_REPORT_RECENT,
  payload: {recent}
});

export default {
  // Vaccines Reports Tables
  setVaccEventReportTableIndex,
  setVaccEventReportTablePageSize,
  setVaccEventReportTableSorting,
  setVaccEventReportTableFiltering,
  setVaccEventReportRecent,
  setVaccTestReportTableIndex,
  setVaccTestReportTablePageSize,
  setVaccTestReportTableSorting,
  setVaccTestReportTableFiltering,
  setVaccTestReportRecent
};