import types from './types';
import uuid from 'uuid';

const addAlert = (text, style = 'success', id = uuid()) => {
  return {
    type: types.ADD_ALERT,
    payload: {text, style, id}
  };
};

const removeAlert = id => {
  return {
    type: types.REMOVE_ALERT,
    payload: {id}
  };
};

const clearAlerts = () => {
  return {
    type: types.CLEAR_ALERTS,
    payload: null
  };
};

export default {
  addAlert,
  removeAlert,
  clearAlerts
};