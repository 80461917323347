import React, {Component, Fragment} from 'react';
import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import EditOrderStatusModal from "./components/EditOrderStatusModal/EditOrderStatusModalContainer";
import ordersService from "../../../services/ordersService";
import async from 'async';
import classNames from 'classnames';

/**
 * Edit Order Status Container
 * Purpose: Render a font awesome Edit icon that opens a modal window (Edit Order Status Modal Container)
 * Take note of the required PropTypes when implementing this component
 */
export class EditOrderStatusContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false
    }
  }


  componentDidMount() {
    this.cancelSource = axios.CancelToken.source();
  }

  componentWillUnmount() {
    this.cancelSource.cancel('userCanceled');
  }

  /**
   * Click event handler for Edit button (fa-edit)
   * @param e
   */
  handleEditOrderClick = e => {
    e.preventDefault();
    this.setState({editMode: true});
  };

  /**
   * Button click event handler for canceling the editing dialog
   * Simply sets 'editMode' to 'false' in component state which will cause the editing dialog to close
   */
  handleCancelEditOrder = e => {
    e.preventDefault();
    this.setState({editMode: false});
  };

  handleUpdateOrder = (updatedOrderInfo) => {
    const {clinic, orderStatusId, medicalExamOrderId, orderId, userEmail} = this.props;
    // list of changes to update in DQit (SQL), initialized with default root values / properties
    let orderStatusChanges = {
      medicalExamOrderId,
      userEmail,
      walkIn: updatedOrderInfo.walkIn,
      updateLog: {},
      orderSource: clinic.orderSource
    };

    //check for differences between values at initialization vs when the Edit Order Status dialog is closed
    if (clinic.vendorId !== updatedOrderInfo.vendorId) {
      orderStatusChanges.updateLog.updateVendor = {
        vendorId: updatedOrderInfo.vendorId,
        name: updatedOrderInfo.vendor,
        address1: updatedOrderInfo.address1,
        address2: updatedOrderInfo.address2,
        city: updatedOrderInfo.city,
        state: updatedOrderInfo.state,
        zip: updatedOrderInfo.zip,
        phone: updatedOrderInfo.phone,
        auditMessage: `Vendor Changed to ${updatedOrderInfo.vendor}`
      };
    }
    if (moment(clinic.appointment).format(updatedOrderInfo.walkIn ? 'M/D/YYYY' : 'M/D/YYYY h:mm a') !== moment(updatedOrderInfo.appointmentDate).format(updatedOrderInfo.walkIn ? 'M/D/YYYY' : 'M/D/YYYY h:mm a')
        || (updatedOrderInfo.walkIn !== clinic.walkIn)) {
      orderStatusChanges.updateLog.updateAppointment = {
        appointment: updatedOrderInfo.walkIn ? moment(updatedOrderInfo.appointmentDate).format('YYYY-MM-DD') : moment(updatedOrderInfo.appointmentDate).format('YYYY-MM-DD HH:mm'),
        auditMessage: `Appointment Date Set to ${moment(updatedOrderInfo.appointmentDate).format(updatedOrderInfo.walkIn ? 'YYYY-MM-DD' : 'M/D/YYYY h:mm a')}`
      };
    }

    if (updatedOrderInfo.orderStatusId > -1 && orderStatusId !== updatedOrderInfo.orderStatusId) {
      orderStatusChanges.updateLog.updateStatus = {
        orderStatus: updatedOrderInfo.orderStatus,
        orderStatusId: updatedOrderInfo.orderStatusId,
        auditMessage: `Status changed to ${updatedOrderInfo.orderStatus}`
      };
    }

    //process updates if we have something to update in DQit (update log keys > 0)
    if (Object.keys(orderStatusChanges.updateLog).length > 0) {
      this.processBatchStatusUpdates(orderId, orderStatusChanges);
    }

    // close the modal
    this.setState({editMode: false});
  };

  /**
   * Process patch of status updates to DQit Db.
   * @param orderId - the Order Id (not the Medical Exam Order id)
   * @param orderStatusChanges - object of changes to be passed up to the sever
   */
  processBatchStatusUpdates = (orderId, orderStatusChanges) => {
    ordersService.updateOrder(orderId, orderStatusChanges, this.cancelSource.token)
    .then(response => {
      const updateResponses = response;

      if (this.props.onOrderChangedCallback) {
        this.props.onOrderChangedCallback(orderId, null);
        return;
      }
      // what we are sending to mongo
      let cmeValuesToUpdate = {};

      // only sync data we know was updated successfully in DQit
      // loop through response data and build a CME update object
      Object.keys(updateResponses).forEach(key => {
        // where 'key' is: updateVendor, updateAppointment, updateStatus
        // use "update key" to pull response status (what came back in response.data)
        const hasError = updateResponses[key].hasError; // check response object for error
        if (!hasError) {
          // pull the updated values for specific key
          const updatedValues = this.mapStatusChange(key, orderStatusChanges.updateLog[key]);
          cmeValuesToUpdate = {...cmeValuesToUpdate, ...updatedValues};
        }
      });
      this.syncLocalData(cmeValuesToUpdate);
    })
    .catch(err => {
      console.log(err);
      if (this.props.onOrderChangedCallback) {
        this.props.onOrderChangedCallback(null, err);
      }
    });
  };

  mapStatusChange = (updateKey, updateValues) => {
    switch (updateKey) {
      case 'updateVendor':
        return {
          'clinic.vendorId': updateValues.vendorId,
          'clinic.name': updateValues.name,
          'clinic.address1': updateValues.address1,
          'clinic.address2': updateValues.address2,
          'clinic.city': updateValues.city,
          'clinic.state': updateValues.state,
          'clinic.zip': updateValues.zip,
          'clinic.phone': updateValues.phone
        };
      case 'updateAppointment':
        return {
          'clinic.appointment': updateValues.appointment
        };
      case 'updateStatus':
        return {
          'orderStatusId': updateValues.orderStatusId,
          'orderStatus': updateValues.orderStatus
        };
      default:
        return {}
    }
  };

  /**
   * Sync local MongoDb record with status change updates successfully updates w
   * @param cme
   */
  syncLocalData = cme => {
    // if component is not associated to a cmeId in Mongo immediately fire reload refresh events
    if (!this.props.cmeId || this.props.cmeId === '') {
      if (this.props.reloadPage) {
        this.props.reloadPage();
      }
      if (this.props.refreshAuditTrail) {
        this.props.refreshAuditTrail();
      }
      return;
    }
    async.parallel([
          done => {
            axios
            .patch(`/v1/cmes/${this.props.cmeId}`, cme, {cancelToken: this.cancelSource.token})
            .then(() => {
              done();
            })
            .catch(err => {
              done(err);
            });
          },
          done => {
            const variables = {};
            if (cme.hasOwnProperty('clinic.appointment')) {
              variables.appointmentDate = cme['clinic.appointment'];
            }
            if (cme.hasOwnProperty('clinic.name')) {
              variables.clinicName = cme['clinic.name'];
            }
            axios
            .patch(`/v1/processes/cmeProcess/tasks/${this.props.taskId}`,
                {variables}, {cancelToken: this.cancelSource.token})
            .then(() => {
              done();
            })
            .catch(err => {
              console.error(err);
              done(err);
            });
          }
        ], err => {
          if (err) {
            console.log(err);
          } else {
            this.props.reloadPage();
            this.props.refreshAuditTrail();
          }
        }
    );


  };

  render() {
    const {clinic, orderStatusId, orderStatus, hidePadding} = this.props;
    return (
        <Fragment>
          <i
              id={`edit_${this.props.orderId}`} // assuming only 1 order number will ever be rendered to a page at one time
              className={classNames({
                'far fa-edit float-right cursor-pointer': true,
                'p-2': !hidePadding,
                'p-0': hidePadding
              })}
              onClick={this.handleEditOrderClick}/>
          {this.state.editMode &&
          <EditOrderStatusModal
              modalOpen={this.state.editMode}
              clinic={clinic}
              orderStatusId={orderStatusId}
              orderStatus={orderStatus}
              hideOrderStatus={!!this.props.hideOrderStatus}
              handleUpdateOrder={this.handleUpdateOrder}
              handleCancelEditOrder={this.handleCancelEditOrder}
              showVendorIdField={this.props.showVendorIdField}
          />}
        </Fragment>
    )
  }
}

EditOrderStatusContainer.propTypes = {
  cmeId: PropTypes.string,
  clinic: PropTypes.object.isRequired,
  orderStatusId: PropTypes.number.isRequired,
  orderStatus: PropTypes.string.isRequired,
  medicalExamOrderId: PropTypes.number.isRequired,
  orderId: PropTypes.number.isRequired,
  userEmail: PropTypes.string.isRequired,
  reloadPage: PropTypes.func.isRequired,
  refreshAuditTrail: PropTypes.func
};

export default EditOrderStatusContainer;