import React, {Component} from 'react';
import axios from 'axios';

import FooterComponent from './footerComponent';

export class FooterContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiVersion: ''
    };
  }

  componentDidMount() {
    this.getApiVersion();
  }

  getApiVersion = () => {
    axios.get('/v1')
    .then(response => {
      this.setState({apiVersion: response.data.version});
    })
    .catch(err => {
      console.error(err);
    });
  };

  render() {
    const {apiVersion} = this.state;
    return (
        <FooterComponent
            apiVersion={apiVersion}
        />
    );
  }

}

export default FooterContainer;