import actions from './actions';

const setSessionStatus = actions.setSessionStatus;

const setSocket = actions.setSocket;


export default {
  setSessionStatus,
  setSocket
}