import { combineReducers } from 'redux';

import randomsPoolCreationWizardAllStepsReducer from './AllSteps/reducers';
import randomsPoolCreationWizardStep1Reducer from './Step1/reducers';
import randomsPoolCreationWizardStep2Reducer from './Step2/reducers';
import randomsPoolCreationWizardStep3Reducer from './Step3/reducers';
import randomsPoolCreationWizardStep4Reducer from './Step4/reducers';
import randomsPoolCreationWizardStep5Reducer from './Step5/reducers';

// This will allow us to export this reducer specific to the Randoms Pool Creation Wizard in ./index.js.
const randomsPoolCreationWizardReducers = combineReducers({
  allSteps: randomsPoolCreationWizardAllStepsReducer,
  step1: randomsPoolCreationWizardStep1Reducer,
  step2: randomsPoolCreationWizardStep2Reducer,
  step3: randomsPoolCreationWizardStep3Reducer,
  step4: randomsPoolCreationWizardStep4Reducer,
  step5: randomsPoolCreationWizardStep5Reducer
})

export default randomsPoolCreationWizardReducers;