const SET_DQ_TASKLIST = 'SET_DQ_TASKLIST';
const SET_DQ_TABLE_PAGE_INDEX = 'SET_DQ_TABLE_PAGE_INDEX';
const SET_DQ_TABLE_PAGE_SIZE = 'SET_DQ_TABLE_PAGE_SIZE';
const SET_DQ_TABLE_SORTING = 'SET_DQ_TABLE_SORTING';

export default {
  SET_DQ_TASKLIST,
  SET_DQ_TABLE_PAGE_INDEX,
  SET_DQ_TABLE_PAGE_SIZE,
  SET_DQ_TABLE_SORTING
};