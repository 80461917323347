import React, {Component} from 'react';
import ModalComponent from './modalComponent';
import {connect} from "react-redux";

class ModalContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.modal.displayModal !== prevState.displayModal) {
      return {displayModal: nextProps.modal.displayModal, info: nextProps.modal.info};
    }
    return null;
  }

  render() {
    return (
        <div id='modal-container'>
          <ModalComponent displayModal={this.state.displayModal} info={this.state.info}/>
        </div>
    )
  }
}

const mapStateToProps = state => ({
  modal: state.modal
});

export default connect(mapStateToProps)(ModalContainer);
