import React, {Component} from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';
import {CSSTransition} from 'react-transition-group';

import {alertsOperations} from "../../../../ducks/alerts";

export class AlertMessageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentCount: 10,
      showing: false
    };
  }

  componentDidMount() {
    const intervalId = setInterval(this.timer, 1000);
    this.setState({
      intervalId,
      showing: true
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  timer = () => {
    this.setState({currentCount: this.state.currentCount - 1});
    if (this.state.currentCount === 0) {
      this.setState({showing: false});
    }
  };

  handleRemoveAlert = e => {
    e.preventDefault();
    this.setState({showing: false});
  };

  handleAlertExited = () => {
    this.props.removeAlert(this.props.id);
  };

  handleKeepAlive = () => {
    clearInterval(this.state.intervalId);
  };

  render() {
    return (

        <CSSTransition
            in={this.state.showing}
            timeout={500}
            classNames="alert"
            onExited={this.handleAlertExited}
        >
          <div className={`alert alert-${this.props.style} cursor-pointer`}
               role="alert"
               onClick={this.handleKeepAlive}
          >
            <button type="button"
                    className="close"
                    aria-label="Close"
                    onClick={this.handleRemoveAlert}>
              <span aria-hidden="true">&times;</span>
            </button>
            <i className={classNames({
              'far alert-icon': true,
              'fa-exclamation-circle': this.props.style === 'danger',
              'fa-check': this.props.style === 'success'
            })}
            />
            <div className="message-container">
              <div className="message-text">{this.props.text}</div>
            </div>
          </div>
        </CSSTransition>
    )
  }
}

const mapDispatchToProps = {
  removeAlert: alertsOperations.removeAlert
};

export default connect(null, mapDispatchToProps)(AlertMessageComponent);
