import React, {Component} from 'react';
import {connect} from "react-redux";
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import axios from "axios";
import {alertsOperations} from '../../../../../../../../ducks/alerts/index';
import {modalOperations} from "../../../../../../../../ducks/modal/index";
import moment from 'moment';
import _ from 'lodash';
import {Rnd} from "react-rnd";

import AddAccidentModal from './addAccidentModalComponent';
import ConfirmActionModal from '../../../../../../../common/ConfirmActionModal/ConfirmActionModal';

class AddAccidentModalContainer extends Component {
  constructor(props) {
    super(props);

    //the props are coming in on the info prop of the modal container, so map them to the ones we need
    this.state = {
      accidentInfo: {}
    };
  }

  componentDidMount() {
    this.cancelSource = axios.CancelToken.source();
    this.setState({
      accidentInfo: {
        legacyClientId: this.props.info.legacyClientId
      },
      employeeId: this.props.info.employeeId
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.info.employeeId !== this.props.info.employeeId) {
      this.setState({employeeId: this.props.info.employeeId});
    }
  }

  componentWillUnmount() {
    this.cancelSource.cancel('userCanceled');
  }

  /**
   * on change handler for all of the editable fields
   * @param e
   */
  handleChange = e => {
    const accidentInfo = _.cloneDeep(this.state.accidentInfo);
    let showConfirmModal = false;
    if (e.target.id === 'cited') {
      if (e.target.checked) {
        showConfirmModal = true;
      } else {
        accidentInfo[e.target.id] = e.target.checked
      }
    } else if (e.target.id === 'hazmat') {//the checkboxes
      accidentInfo[e.target.id] = e.target.checked;
    } else {
      accidentInfo[e.target.name] = e.target.value;
    }

    this.setState({accidentInfo, showConfirmModal});
  };

  /**
   * the change handler for the date field, uses the passed in name parameter to determine which field to update
   * @param date
   * @param name
   */
  handleDateChange = (date, name) => {
    const accidentInfo = _.cloneDeep(this.state.accidentInfo);
    accidentInfo[name] = date ? moment(date) : null;
    this.setState({accidentInfo});
  };

  /**
   * the change handler for the select fields, uses the passed in name parameter to determine which field to update
   * @param e
   * @param name
   */
  handleSelectChange = (e, name) => {
    const selectedItem = e ? e.value : -1;
    const accidentInfo = _.cloneDeep(this.state.accidentInfo);
    accidentInfo[name] = selectedItem;
    if (name === 'state') {
      // for 'state' we want an empty string if the input is cleared out
      accidentInfo[name] = selectedItem || '';
    }
    this.setState({accidentInfo});
  };

  addAccident = () => {
    const {dataIsValid, message} = this.validData();
    if (!dataIsValid) {
      this.setState({showFieldValidation: true});
      this.props.addAlert(message, 'danger');
    } else {
      const {accidentInfo} = this.state;
      accidentInfo.fatalities = parseInt(accidentInfo.fatalities, 10);
      accidentInfo.disabled = parseInt(accidentInfo.disabled, 10);
      accidentInfo.injuries = parseInt(accidentInfo.injuries, 10);
      accidentInfo.addedBy = this.props.auth && this.props.auth.user ? this.props.auth.user.email : '';
      axios.post(`/v1/employees/${this.state.employeeId}/accidents`, accidentInfo, {cancelToken: this.cancelSource.token})
      .then(result => {
        this.props.addAlert('Accident Added successfully');
        this.setState({showFieldValidation: false});
        this.props.hideModal(true);
      }).catch(err => {
        //dispatch errors?
        console.log('error adding accident');
        this.props.addAlert('Error Adding Accident', 'danger');
      });
    }
  };

  validData = () => {
    const {accidentInfo} = this.state;
    if (!accidentInfo.fatalities || !accidentInfo.disabled || !accidentInfo.injuries) {
      return {dataIsValid: false, message: 'You must fill in all required fields.'};
    }
    if (!accidentInfo.state || !accidentInfo.city || !accidentInfo.date) {
      return {dataIsValid: false, message: 'You must fill in all required fields.'};
    }
    if (isNaN(accidentInfo.fatalities) || isNaN(accidentInfo.disabled) || isNaN(accidentInfo.injuries)) {
      return {dataIsValid: false, message: 'Number of fatalities, disabled vehicles and injuries must be numbers'};
    }
    return {dataIsValid: true, message: ''};
  };

  cancelAdd = () => {
    this.props.hideModal(false);
  };

  handleModalConfirmClick = () => {
    const accidentInfo = _.cloneDeep(this.state.accidentInfo);
    accidentInfo.cited = true;
    this.setState({accidentInfo, showConfirmModal: false});
  };

  render() {
    const {accidentInfo, showConfirmModal, showFieldValidation} = this.state;

    return (showConfirmModal ? <ConfirmActionModal
                modalHeaderText="Reminder"
                modalBodyText={`DOT regulations on Drug and Alcohol requirements`}
                onConfirm={this.handleModalConfirmClick}
                confirmText={'OK'}
                hideCancel={true}
            />
            : <Modal isOpen={true} size={'lg'}>
              <Rnd dragHandleClassName={'modal-header'} enableResizing={false}>
                <ModalHeader className={'cursor-move'}>Add Accident </ModalHeader>
                <ModalBody>
                  <AddAccidentModal
                      accidentInfo={accidentInfo}
                      onDateChange={this.handleDateChange}
                      onChange={this.handleChange}
                      onSelectChange={this.handleSelectChange}
                      showFieldValidation={showFieldValidation}
                  />
                </ModalBody>
                <ModalFooter>
                  <button className={'btn btn-primary btn-sm'} onClick={this.addAccident}>Save
                  </button>
                  <button className={'btn btn-secondary btn-sm'} onClick={this.cancelAdd}>Cancel</button>
                </ModalFooter>
              </Rnd>
            </Modal>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = {
  hideModal: modalOperations.hideModal,
  addAlert: alertsOperations.addAlert,
  showModal: modalOperations.showModal
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAccidentModalContainer);