import axios from 'axios';

import actions from './actions';
import setAuthToken from "../../utils/setAuthToken";
import {getCookie, deleteCookie} from "../../utils/storageUtils";
import clientService from '../../services/clientsService/clientsService';
import {sessionOperations} from "../userSession";
import {dqfOperations} from "../dqf";
import {layoutOperations} from "../layout";

const setTrueIdentity = actions.setTrueIdentity;

const setImpersonatingUser = actions.setImpersonatingUser; // store who is being impersonated

const setImpersonateMode = actions.setImpersonateMode; // essentially "start" / "restore"

const setCurrentUser = (userData, preserveState = false) => dispatch => {
  dispatch(actions.setCurrentUser(userData, preserveState));
};

const getFullCurrentUser = (userId, preserveState = false) => dispatch => {
  axios
  .get(`/v1/users/${userId}`)
  .then(res => dispatch(actions.setCurrentUser(res.data, preserveState)))
  .catch(err => console.error(err));
};

/**
 * Process a logout (manual or auto-logout)
 * @param status - 'EXITED' or 'EXPIRED'
 * @private
 */
const _logout = (status, dispatch) => {
  axios.post(`/v1/auth/logout/${getCookie('refreshToken')}`)
  .then()
  .catch(console.error);
  dispatch(sessionOperations.setSessionStatus(status));
  deleteCookie('jwtToken');
  deleteCookie('refreshToken');
  setAuthToken(false);
  dispatch(actions.setCurrentUser({}));
  // reset site tree and site selections in dqf.sites
  dispatch(dqfOperations.setSiteTree({}));
  dispatch(dqfOperations.setSelectedSites([], {}));
  dispatch(dqfOperations.setSessionClient(''));
  // reset roster views
  dispatch(dqfOperations.resetRosterViews());
  // reset alert counts
  dispatch(dqfOperations.setAlertCounts(null));
  dispatch(layoutOperations.clearUserOptions());
  // reset document view option to "multi"
  dispatch(dqfOperations.setDocumentTabsSingleOrMulti('multi'));
};

const logoutUser = () => dispatch => {
  _logout('EXITED', dispatch);
};

const autoLogoutUser = () => dispatch => {
  _logout('EXPIRED', dispatch);
};

const _getAllChildrenSites = client => {
  const sites = [];
  for (let childSite of client.children) {
    sites.push(childSite);
    if (childSite.children && childSite.children.length > 0) {
      sites.push(..._getAllChildrenSites(childSite));
    }
  }
  return sites;
};

/**
 * Operation to get list of Sites for given clientId
 * @param clientId (string) - mongoId for client
 * @param isConcorde
 * @returns {Function}
 */
const getSitesForUser = (clientId, isConcorde = false) => dispatch => {
  if (!clientId) {
    return;
  }
  axios.get(`/v1/clients?descendantsOf=${clientId}`)
  .then(response => {
    if (response.status === 200) {
      if (response.data.length > 0) {
        dispatch(dqfOperations.setSiteTree(response.data[0]));
        const siteList = _getAllChildrenSites(response.data[0]);
        siteList.unshift(response.data[0]);
        if (isConcorde) {
          dispatch(actions.setUserSites(siteList));
        }
      }
    }
  })
  .catch(err => {
    console.log(err);
  })
};

/**
 * Operation to get Client for given clientId
 * @param clientId (string) - mongoId for client
 * @returns {Function}
 */
const getClientForUser = clientId => dispatch => {
  axios.get(`/v1/clients/${clientId}`)
  .then(response => {
    if (response.status === 200) {
      // Pull out the client's "displayIncludeChildren" setting
      // so that it can be dispatched to the DQF Redux store separately
      const {displayIncludeChildren, ...client} = response.data;
      dispatch(actions.setClientForUser(client));
      dispatch(dqfOperations.setDisplayIncludeChildren(displayIncludeChildren));
      // Also need to clear the roster's saved location
      dispatch(dqfOperations.setRosterSelectedLocation(null));
    }
  })
  .catch(err => {
    console.error(err);
  })
};

/**
 * Get Client and Sites for provided clientId
 * Dispatches both getClientForUser and getSitesForUser operations
 * @param clientId (string) - mongoId for client
 * @returns {Function}
 */
const getClientAndSites = (clientId, isConcorde = false) => dispatch => {
  dispatch(actions.setUserSites([]));
  dispatch(getClientForUser(clientId));
  dispatch(getSitesForUser(clientId, isConcorde));
};

const getJumpList = userId => dispatch => {
  axios.get(`/v1/users/${userId}/jumplist`)
  .then(res => {
    if (res.status === 200) {
      dispatch(actions.setJumpList(res.data));
    }
  })
  .catch(err => console.error(err));
};

const clearJumpList = userId => dispatch => {
  axios.delete(`/v1/users/${userId}/jumplist`)
  .then(res => {
    if (res.status === 204) {
      dispatch(actions.setJumpList([]));
    }
  })
  .catch(err => console.error(err));
};

const addToEmployeeJumpList = (userId, jumpListRecord) => dispatch => {
  axios.post(`/v1/users/${userId}/jumplist`, jumpListRecord)
  .then(res => {
    if (res.status === 201) {
      dispatch(actions.setJumpList(res.data));
    }
  })
  .catch(err => console.error(err));
};

const removeEmployeeFromJumpList = (userId, employeeId) => dispatch => {
  axios.delete(`/v1/users/${userId}/jumplist/${employeeId}`)
  .then(res => {
    if (res.status === 201) {
      dispatch(actions.setJumpList(res.data));
    }
  })
  .catch(err => console.error(err));
};

const getTopLevelClientAndSetAssumedClientAndSites = childLocationId => dispatch => {
  clientService.getTopLevelClient(childLocationId)
  .then(topLevelClient => {
    // first set the CCR Client Selector
    dispatch(dqfOperations.setSessionClient(topLevelClient._id));
    // change the 'auth.user.client' object
    dispatch(actions.setClientForUser(topLevelClient));
    // change the 'auth.user.sites' array
    dispatch(getSitesForUser(topLevelClient._id, true));
  })
  .catch(err => console.log(err));
};

const startImpersonateMode = (trueIdentity, impersonateUser) => dispatch => {
  dispatch(setTrueIdentity(trueIdentity)); // store ccr true identity
  dispatch(setImpersonateMode(impersonateUser));
  dispatch(dqfOperations.setSelectedSites(impersonateUser.sites, {}));
  dispatch(setImpersonatingUser(impersonateUser._id, `${impersonateUser.firstName} ${impersonateUser.lastName}`));
};

const stopImpersonateMode = ccrUser => dispatch => {
  dispatch(setTrueIdentity({}));
  dispatch(setImpersonateMode(ccrUser));
  dispatch(dqfOperations.setSelectedSites(ccrUser.sites, {}));
  dispatch(setImpersonatingUser(null, null));
};

export default {
  setCurrentUser,
  getFullCurrentUser,
  logoutUser,
  autoLogoutUser,
  getClientForUser,
  getClientAndSites,
  getJumpList,
  clearJumpList,
  addToEmployeeJumpList,
  getTopLevelClientAndSetAssumedClientAndSites,
  startImpersonateMode,
  stopImpersonateMode,
  getSitesForUser,
  removeEmployeeFromJumpList
};
