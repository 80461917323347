import React from 'react';
import {NavLink} from 'react-router-dom';
import classNames from 'classnames';

const menuEntryComponent = ({path, entry, badgeNumber, needClient, onShowModal, indent}) => {
  if (!!entry.modal) {
    return (
        <NavLink
            to={'#'}
            exact={entry.exact}
            className={classNames({
              'menu-group d-flex justify-content-between align-items-center': true,
              'cursor-not-allowed': needClient
            })}
            disabled={needClient}
            onClick={() => onShowModal(entry.modal)}
        >
          <span className={classNames({'indent': indent})}>
            {entry.text}
          </span>
          {(badgeNumber !== null) && <span className={'badge badge-tab'}>{badgeNumber}</span>}
        </NavLink>
    );
  }
  return (
      <NavLink
          to={needClient ? '#' : `${path}${entry.path}`}
          exact={entry.exact}
          className={classNames({
            'menu-group d-flex justify-content-between align-items-center': true,
            'cursor-not-allowed': needClient
          })}
          disabled={needClient}
      >
        <span className={classNames({'indent': indent})}>
          {entry.text}
        </span>
        {(badgeNumber !== null) && <span className={'badge badge-tab'}>{badgeNumber}</span>}
      </NavLink>
  );
};

export default menuEntryComponent;