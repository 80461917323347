function subscribeToTaskListChange(socket, cb) {
  socket.on('update-cme-task-list-data', taskData => {
    cb(taskData);
  });
}

function subscribeToDQTaskListChange(socket, cb) {
  socket.on('update-dq-task-list-data', taskData => {
    cb(taskData);
  });
}

export {subscribeToTaskListChange, subscribeToDQTaskListChange}