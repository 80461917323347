import React, {Component} from 'react';
import {withRouter, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import routes from '../../../routes';

import BreadcrumbsComponent from './breadcrumbsComponent';

class BreadcrumbsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathMap: {}
    }
  }

  /**
   * Recursively traverse all the routes, and build the breadcrumbs for each route. As each route is processed,
   * add it to the global routeList.
   *
   * @param pathList
   * @param breadcrumbs
   * @param routes
   * @private
   */
  _buildRouteBreadcrumbs = (pathList, breadcrumbs, routes) => {
    routes.forEach(route => {
      const newPathList = pathList.slice(0);
      newPathList.push(route.path);
      route.fullPath = newPathList.join('');
      const newBreadcrumbs = breadcrumbs.slice(0);
      const mappedPath = this.state.pathMap[route.absolutePath ? route.path : route.fullPath];
      let url = mappedPath ? mappedPath.url : route.absolutePath ? route.path : route.fullPath;
      if (route.menuHeading && route.routes && route.routes.length > 0) {
        url += route.routes[0].path;
      }
      const text = mappedPath ? mappedPath.text : route.text;
      newBreadcrumbs.push({text, url});
      route.breadcrumbs = newBreadcrumbs;
      this.routeList.push(route);
      // Process this route's "children"
      if (route.hasOwnProperty('routes')) {
        this._buildRouteBreadcrumbs(newPathList, newBreadcrumbs, route.routes);
      }
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.breadcrumb.pathMap !== prevState.pathMap) {
      return {pathMap: nextProps.breadcrumb.pathMap};
    }
    return null;
  }

  render() {
    // Generate the breadcrumbs for the routes
    this.routeList = [];
    this._buildRouteBreadcrumbs([], [], routes);
    // Now produce an exact Route for each breadcrumb trail
    return (
        <div>
          {this.routeList.map(route => {
            if (!route.showBreadcrumbs) {
              return null;
            }
            return (
                <Route path={route.absolutePath ? route.path : route.fullPath} exact render={(props) => {
                  return (
                      <BreadcrumbsComponent {...props} route={route}
                                            userAuthenticated={this.props.auth.isAuthenticated}/>
                  );
                }} key={`${route.absolutePath ? route.path : route.fullPath}_${route.text}`}/>
            )
          })}
        </div>
    );
  }
}

const mapStateToProps = state => ({
  breadcrumb: state.breadcrumb,
  auth: state.auth
});

export default withRouter(connect(mapStateToProps)(BreadcrumbsContainer));