import React from 'react';

/**
 * Display a message on the page, in a <p> inside a Bootstrap card.
 * Props can include the following, to style the <p>:
 *   - color    the Bootstrap text color (success, info, etc.)
 *   - bgColor  the Bootstrap background color (success, info, etc.)
 *   - innerClassName   additional classNames for the <p>
 * Props can also include the following to style the card:
 *   - className  any arbitrary className(s) to add to the container card
 * The message is passed as the child, and can be plain text or a component.
 */
const pageMessageComponent = props => {
  const bgColor = `bg-${props.bgColor || 'white'}`;
  const textColor = `text-${props.color || 'black'}`;
  return <div className={`card ${props.className || ''}`}>
    <div className={'card-body p-2'}>
      <div className={`mb-0 p-2 ${bgColor} ${textColor} ${props.innerClassName || ''}`}>
        {props.children || props.text}
      </div>
    </div>
  </div>
};

export default pageMessageComponent;