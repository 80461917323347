import React from 'react';
import ReactTable from "react-table";
import PageMessage from '../../PageMessage/pageMessageComponent';
import CBReactTablePagination from '../../CBReactTablePagination/CBReactTablePagination';
import moment from 'moment';

const documentListPaneComponent = props => {

  let tableColumns = [
    {
      id: 'colItem',
      Header: 'Document',
      accessor: 'item',
      headerClassName: 'text-left'
    },
    {
      Header: 'Date',
      accessor: 'docDate',
      id: 'colDocDate',
      headerClassName: 'text-left',
      sortable: true,
      Cell: ({value}) => value ? moment.utc(value).format('M/D/YYYY') : ''
    },
    {
      Header: 'Expires',
      accessor: 'expireDate',
      id: 'colExpireDate',
      headerClassName: 'text-left',
      sortable: true,
      Cell: ({value}) => value ? moment.utc(value).format('M/D/YYYY') : ''
    },
    {
      id: 'colUploader',
      Header: 'Uploaded By',
      accessor: 'uploader',
      headerClassName: 'text-left'
    },
    {
      id: 'colDateUploaded',
      Header: 'Received',
      accessor: 'uploadDate',
      sortable: true,
      Cell: ({value}) => value ? moment.utc(value).format('M/D/YYYY') : '',
      headerClassName: 'text-left'
    }
  ];


  if (props.employeeInfo) {
    tableColumns = [{
      id: 'colReportTitle',
      Header: (<span
          className={'h6 mt-1 mb-0'}>{`CME Documents for ${props.employeeInfo.firstName} ${props.employeeInfo.lastName}`}</span>),
      columns: tableColumns
    }];
  }

  const maxRows = 5;
  const dataLength = props.data ? props.data.length : 0;
  let defaultPageSize = dataLength <= maxRows ? dataLength : maxRows;
  if (defaultPageSize === 0) {
    defaultPageSize = 3;
  }

  return (
      <ReactTable
          key={defaultPageSize}
          className={'-striped -highlight -cursor-pointer '}
          data={props.data}
          columns={tableColumns}
          defaultPageSize={defaultPageSize}
          filterable={false}
          noDataText={
            <PageMessage color={'black'} bgColor={'warning'}
                         text={'There are no historical CME documents for this employee'}/>
          }
          showPagination={dataLength > defaultPageSize}
          PaginationComponent={CBReactTablePagination}
          getTrProps={
            (state, rowInfo, column) => {
              if (!rowInfo) return {};
              if (!rowInfo.original) return {};
              return {
                className: rowInfo.original.selected ? 'selected' : ''
              }
            }
          }
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: (e, handleOriginal) => {
                if (!rowInfo) return;
                if (!rowInfo.original) return;
                if (column.cellClassName && column.cellClassName.includes('prevent-click')) {
                  return;
                }
                if (props.clickRowHandler) {
                  props.clickRowHandler(rowInfo.original);
                }
                if (handleOriginal) {
                  handleOriginal();
                }
              }
            };
          }}
      />
  );
};

export default documentListPaneComponent;