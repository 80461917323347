import React, {Component} from 'react';
import {connect} from "react-redux";

import AlertMessage from './components/AlertMessageComponent';

export class AlertsComponent extends Component {
  render() {
    const {alerts} = this.props;
    const alertMessages = alerts.reverse().map(a => {
      return <AlertMessage
          key={a.id}
          id={a.id}
          text={a.text}
          style={a.style}
      />
    });

    return (
        <div id="alerts-overlay">
          {alertMessages}
        </div>
    )
  }
}

const mapStateToProps = state => ({
  alerts: state.alerts
});

export default connect(mapStateToProps)(AlertsComponent);
