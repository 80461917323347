import React from 'react';
import { connect } from 'react-redux';

import licenseValidator from '@civteam/cc-driver-license-validator';
import permissions from '../../../config/permissions';
import { hasPermission } from "../../../utils/permissions";

const driverLicenseNumberFormatOverrideComponent = props => {
  if (!props.state) {
    return null;
  }
  let descriptionList = null;
  const validatorResponse = licenseValidator.getLicenseFormatDescriptions(props.state, props.driversLicenseFormatRules);
  if (typeof validatorResponse === "object") {
    descriptionList = validatorResponse;
  } else {
    descriptionList = ['No formats available']
  }
  if (!descriptionList) {
    return null;
  }

  return (
      <small>
        {!props.overrideLicenseValidation &&
        <>
          <strong>Expected driver license number formats:</strong>
          <ul className="list-unstyled mb-1">
            {descriptionList.map((vi, i) =>
                <li key={i}>
                  {vi}
                </li>)}
          </ul>
        </>
        }
        {hasPermission(props.auth.user, permissions.customerCare.assumeClient) &&
        <div className="custom-control custom-checkbox">
          <input id="overrideValidation"
                 type="checkbox"
                 className="custom-control-input"
                 value={!!props.overrideLicenseValidation}
                 checked={props.overrideLicenseValidation}
                 onChange={props.onOverrideValidation}
          />
          <label className="custom-control-label pt-1"
                 htmlFor="overrideValidation"
          >
            Override license validation
          </label>
        </div>
        }
      </small>
  )
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(driverLicenseNumberFormatOverrideComponent);