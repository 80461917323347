import React, {Component} from 'react';
import SocialSecurityNumberDisplayComponent from './socialSecurityNumberDisplayComponent.jsx';
import {connect} from 'react-redux';

export class SocialSecurityNumberDisplayContainer extends Component {

  render() {
    const {ssn, redact, ssnMask} = this.props;
    return (
        <SocialSecurityNumberDisplayComponent
            ssn={ssn}
            redact={redact}
            isHidden={ssnMask.isMasked}
        />
    )
  }
}

const mapStateToProps = state => ({
  ssnMask: state.layout.ssnMask
});

export default connect(mapStateToProps)(SocialSecurityNumberDisplayContainer);
