import React, {Component} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import _ from 'lodash';

import EditOrderStatusModalComponent from "./editOrderStatusModalComponent";

export class EditOrderStatusModalContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderInfo: {},
      vendorLookUpMode: false
    };
  }

  componentDidMount() {
    const walkIn = this.props.clinic && this.props.clinic.walkIn ? this.props.clinic.walkIn : false;
    const orderInfo = {
      orderStatusId: this.props.orderStatusId || -1,
      orderStatus: this.props.orderStatus || '',
      appointmentDate: this.props.clinic ? walkIn ? moment(this.props.clinic.appointment) : moment(this.props.clinic.appointment) : null,
      vendorId: this.props.clinic ? this.props.clinic.vendorId : null,
      vendor: this.props.clinic ? this.props.clinic.name : '',
      walkIn
    };
    this.setState({orderInfo});//the vendor id field shows for cme members, also the documents are hidden by default for them
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let newState = {};
    if (!_.isEqual(this.props.clinic, prevProps.clinic)) {
      newState.clinic = this.props.clinic;
    }
    if (this.props.orderStatusId !== prevProps.orderStatusId) {
      newState.orderStatusId = this.props.orderStatusId;
    }
    if (this.props.orderStatus !== prevProps.orderStatus) {
      newState.orderStatus = this.props.orderStatus;
    }
    if (Object.keys(newState).length > 0) {
      this.setState({...newState});
    }
  }

  handleAppointmentDateChange = date => {
    const orderInfo = {...this.state.orderInfo};
    orderInfo.appointmentDate = date;
    this.setState({orderInfo});
  };

  /**
   * Fired from child when a new status is selected to send back to this state
   * @param statusInfo
   */
  handleOrderStatusChange = statusInfo => {
    const orderInfo = {...this.state.orderInfo};
    orderInfo.orderStatusId = statusInfo.selectedOrderStatusId;
    orderInfo.orderStatus = statusInfo.selectedOrderStatus;
    this.setState({orderInfo});
  };

  /**
   * Event handler for opening the Vendor Lookup container
   */
  handleVendorLookup = e => {
    e.preventDefault();
    this.setState({vendorLookUpMode: true});
  };

  /**
   * Event handler from retrieving selected vendor from Vendor Lookup
   */
  handleConfirmVendorSelection = vendorChanges => {
    const orderInfo = {...this.state.orderInfo};
    orderInfo.vendorId = vendorChanges.vendorId;
    orderInfo.vendor = vendorChanges.vendorName;
    orderInfo.address1 = vendorChanges.address1;
    orderInfo.address2 = vendorChanges.address2;
    orderInfo.city = vendorChanges.city;
    orderInfo.phone = vendorChanges.phone;
    orderInfo.state = vendorChanges.state;
    orderInfo.zip = vendorChanges.zip;
    this.setState({
      vendorLookUpMode: false,
      orderInfo
    });
  };

  handleCancelVendorSelection = e => {
    e.preventDefault();
    this.setState({vendorLookUpMode: false});
  };

  /**
   * Passes back updated state to parent controller to update order status in DQit and Mongo
   */
  handleUpdateOrder = e => {
    e.preventDefault();
    this.props.handleUpdateOrder(this.state.orderInfo);
  };

  handleCheckboxChange = e => {
    const orderInfo = {...this.state.orderInfo};
    orderInfo.walkIn = e.target.checked;
    this.setState({orderInfo});
  };

  render() {
    return <EditOrderStatusModalComponent
        orderInfo={this.state.orderInfo}
        vendorLookUpMode={this.state.vendorLookUpMode}
        hideOrderStatus={this.props.hideOrderStatus}
        modalOpen={this.props.modalOpen}
        handleCancelEditOrder={this.props.handleCancelEditOrder}
        handleOrderStatusChange={this.handleOrderStatusChange}
        handleAppointmentDateChange={this.handleAppointmentDateChange}
        handleVendorLookup={this.handleVendorLookup}
        handleConfirmVendorSelection={this.handleConfirmVendorSelection}
        handleCancelVendorSelection={this.handleCancelVendorSelection}
        handleUpdateOrder={this.handleUpdateOrder}
        showVendorIdField={this.props.showVendorIdField}
        handleCheckboxChange={this.handleCheckboxChange}
    />
  }
}

EditOrderStatusModalContainer.propTypes = {
  handleUpdateOrder: PropTypes.func.isRequired,
  handleCancelEditOrder: PropTypes.func.isRequired
};

export default EditOrderStatusModalContainer;