import React, {Fragment} from 'react';

const orderMVRModalComponent = props => {
  return (
      <Fragment>
        {props.content}
      </Fragment>
  );
};

export default orderMVRModalComponent;