import types from './types';
import step2DefaultState from './defaultState';

const randomsPoolCreationWizardStep2Reducer = (state = step2DefaultState, action) => {
  switch (action.type) {
    // =======================================================================================
    //                             Randoms Pool Creation Wizard - Step 2
    // =======================================================================================
    case types.RESET_STEP_2_GLOBAL_STATE: 
      return step2DefaultState
      
    // By Employment Status
    case types.SET_STEP2_EMPLOYMENT_STATUS_CHECKBOX: 
      return {
        ...state,
        byEmploymentStatus: action.payload,
      }

    case types.SET_STEP2_EMPLOYMENT_STATUS_VALUES_FOR_DROPDOWN: 
      return {
        ...state,
        employmentStatusValuesForDropdown: action.payload
      }

    case types.SET_STEP2_BY_EMPLOYMENT_STATUS_SELECTION_VALUES: 
      return {
        ...state,
        byEmploymentStatusSelectionValues: action.payload
      }

    case types.SET_STEP2_FOR_EDITING: 
      return {
        ...state,
        ...action.payload
      }

    // By Job Titles
    case types.SET_STEP2_JOB_TITLE_CHECKBOX: 
      return {
        ...state,
        byJobTitle: action.payload,
      }
    
    case types.SET_STEP2_BY_JOB_TITLE_SELECTION_VALUES: 
      return {
        ...state,
        byJobTitleSelectionValues: action.payload
      }

    case types.SET_STEP2_JOB_TITLE_VALUES_FOR_DROPDOWN: 
      return {
        ...state,
        jobTitleValuesForDropdown: action.payload
      }

    // By Safety Codes
    case types.SET_STEP2_SAFETY_CODE_CHECKBOX: 
      return {
        ...state,
        bySafetyCode: action.payload,
      }

    case types.SET_STEP2_BY_SAFETY_CODE_SELECTION_VALUES: 
      return {
        ...state,
        bySafetyCodeSelectionValues: action.payload
      }

    default:
      return state;
  }
};

export default randomsPoolCreationWizardStep2Reducer;