import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';
import {Rnd} from "react-rnd";
import { useSelector } from 'react-redux';


const ConfirmActionModal = ({showModal = true, modalHeaderText, modalBodyText, confirmText, cancelText, onConfirm, onCancel, hideCancel}) => {
  const loading = useSelector((state) => state.layout.loadingPanel.instances);
  return (
    <Modal isOpen={showModal}>
      <Rnd dragHandleClassName={'modal-header'} enableResizing={false}>
        <ModalHeader className={'cursor-move'}>
          {modalHeaderText}
        </ModalHeader>
        <ModalBody>
          {modalBodyText}
        </ModalBody>
        <ModalFooter>
          <Button 
            color={"primary"}
            onClick={onConfirm}
            className={'confirm-action'}
            disabled={!!loading}
          >
            {confirmText || 'Yes'}
          </Button>
          {!hideCancel && 
            <Button 
              color={"secondary"}
              onClick={onCancel}
              className={'cancel-action'}
              disabled={!!loading}
            >
              {cancelText || 'Cancel'}
            </Button>
          }
        </ModalFooter>
      </Rnd>
    </Modal>
  );
};

export default ConfirmActionModal;