const RESET_STEP_3_GLOBAL_STATE = 'RESET_STEP_3_GLOBAL_STATE';
const SET_STEP3_DEFAULT_RANDOMS_TESTING_RATES = 'SET_STEP3_DEFAULT_RANDOMS_TESTING_RATES';
const SET_STEP3_EXISTING_POOL_NAMES = 'SET_STEP3_EXISTING_POOL_NAMES';
const SET_STEP3_FOR_EDITING = 'SET_STEP3_FOR_EDITING';
const SET_STEP3_FREQUENCY_SELECTION = 'SET_STEP3_FREQUENCY_SELECTION';
const SET_STEP3_PERCENTAGE_ALCOHOL = 'SET_STEP3_PERCENTAGE_ALCOHOL';
const SET_STEP3_PERCENTAGE_DRUG = 'SET_STEP3_PERCENTAGE_DRUG';
const SET_STEP3_POOL_NAME = 'SET_STEP3_POOL_NAME';
const SET_STEP3_POOL_NAME_ALREADY_EXISTS = 'SET_STEP3_POOL_NAME_ALREADY_EXISTS';
const SET_STEP3_TESTING_AUTHORITY_SELECTION = 'SET_STEP3_TESTING_AUTHORITY_SELECTION';

export default {
  RESET_STEP_3_GLOBAL_STATE,
  SET_STEP3_DEFAULT_RANDOMS_TESTING_RATES,
  SET_STEP3_EXISTING_POOL_NAMES,
  SET_STEP3_FOR_EDITING,
  SET_STEP3_FREQUENCY_SELECTION,
  SET_STEP3_PERCENTAGE_ALCOHOL,
  SET_STEP3_PERCENTAGE_DRUG,
  SET_STEP3_POOL_NAME,
  SET_STEP3_POOL_NAME_ALREADY_EXISTS,
  SET_STEP3_TESTING_AUTHORITY_SELECTION
}