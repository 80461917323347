import types from './types';
import _ from 'lodash';
import concordeSettings from '../../config/concordeClient.json';

const initialState = {
  isAuthenticated: false,
  user: {},
  trueIdentity: {}, // maybe it's ccrTrueIdentity with the 'ccr'
  impersonatingDisplayName: null,
  impersonatingUserId: null // the only thing we are not changing in the user object is the user Id
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CURRENT_USER:
      if (action.payload.preserveState) {
        // If preserveState is true, we got here from a token refresh or a profile update so . . .
        // we need to keep the client and sites (in case the user is an internal user who selected a client)
        if (!!state.impersonatingUserId) {
          // . . . but if the user was impersonating another user, don't lose that! -- just re-set the "true identity"
          return {
            ...state,
            trueIdentity: {
              ...action.payload.userData,
              isConcordeUser: action.payload.userData.client && action.payload.userData.client.legacyClientId === concordeSettings.concordeClient.legacyClientId
            }
          };
        } else {
          return {
            ...state,
            user: {
              ...action.payload.userData,
              isConcordeUser: action.payload.userData.client && action.payload.userData.client.legacyClientId === concordeSettings.concordeClient.legacyClientId,
              client: state.user.client,
              sites: state.user.sites
            }
          };
        }
      } else if (Object.keys(action.payload.userData).length === 0) {
        // If clearing the user data (user logged out), also clear the impersonation settings here
        return {
          ...state,
          user: {},
          isAuthenticated: false,
          trueIdentity: null,
          impersonatingUserId: null,
          impersonatingDisplayName: null
        };
      } else {
        return {
          ...state,
          isAuthenticated: true,
          user: {
            ...action.payload.userData,
            isConcordeUser: action.payload.userData.client && action.payload.userData.client.legacyClientId === concordeSettings.concordeClient.legacyClientId
          }
        };
      }
    case types.GET_SITES_FOR_USER:
      return {
        ...state,
        user: {
          ...state.user,
          sites: [...action.payload]
        }
      };
    case types.GET_CLIENT_FOR_USER:
      return {
        ...state,
        user: {
          ...state.user,
          client: action.payload
        }
      };
    case types.USER_SET_JUMP_LIST:
      return {
        ...state,
        user: {
          ...state.user,
          jumpList: action.payload
        }
      };
    case types.SET_TRUE_IDENTITY:
      const user = _.cloneDeep(state.user);
      user.isConcordeUser = !action.payload;
      return {
        ...state,
        user,
        trueIdentity: action.payload
      };
    case types.SET_USER_PERMISSIONS:
      return {
        ...state,
        user: {
          ...state.user,
          permissions: [...action.payload]
        }
      };
    case types.SET_IMPERSONATING_USER_ID:
      return {
        ...state,
        impersonatingUserId: action.payload.impersonatingUserId,
        impersonatingDisplayName: action.payload.impersonatingDisplayName
      };
    case types.SET_IMPERSONATE_MODE:
      const newProps = _.cloneDeep(action.payload);
      return {
        ...state,
        user: {
          ...state.user,
          ...newProps
        }
      };
    default:
      return state;
  }
};

export default authReducer;