import React, {Fragment} from 'react';
import moment from "moment";
import ReactTable from 'react-table';
import CBReactTablePagination from '../../../common/CBReactTablePagination/CBReactTablePagination';
import PageMessage from '../../../common/PageMessage/pageMessageComponent';
import {UncontrolledTooltip} from 'reactstrap';
import EditOrderStatus from '../../../common/EditOrderStatus/EditOrderStatusContainer';

const cmeResearchOrdersComponent = props => {

  const maxRows = 10;
  let defaultPageSize = props.results.length <= maxRows ? props.results.length : maxRows;
  if (defaultPageSize === 0) {
    defaultPageSize = 3;
  }

  const tableColumns = [
    {
      id: 'colReportTitle',
      Header: (<span
          className={'h6 mt-1 mb-0'}>{`Orders for ${props.employeeInfo.firstName} ${props.employeeInfo.lastName}`}</span>),
      columns: [
        {
          id: 'colOrderDateTime',
          Header: 'Order Date',
          Cell: ({value}) => value ? moment.utc(value).format('M/D/YYYY') : '',
          accessor: 'OrderDate',
          headerClassName: 'text-left'
        },
        {
          id: 'colEmployerName',
          Header: 'Employer',
          accessor: 'EmployerName',
          headerClassName: 'text-left'
        },
        {
          id: 'colEmploymentStatus',
          Header: 'Status',
          accessor: 'EmploymentStatus',
          headerClassName: 'text-left'
        },
        {
          id: 'colAppointmentDateTime',
          Header: 'Appointment',
          Cell: ({value}) => value ? moment.utc(value).format('M/D/YYYY h:mm a') : '',
          accessor: 'AppointmentDateTime',
          headerClassName: 'text-left'
        },
        {
          id: 'colOrderStatus',
          Header: 'Order Status',
          accessor: 'OrderStatus',
          headerClassName: 'text-left'
        },
        {
          id: 'colIcon',
          Header: '',
          accessor: '',
          headerClassName: 'text-left',
          width: 38,
          className: 'd-flex justify-content-center align-items-center',
          Cell: ({original}) => {
            return (
                <Fragment>
                  <UncontrolledTooltip placement="right" target={`edit_${original.OrderID}`} container='#root'>
                    Change appointment information for {original.FirstName} {original.LastName}
                  </UncontrolledTooltip>
                  <EditOrderStatus
                      hideOrderStatus={false}
                      clinic={{
                        vendorId: original.OctopusVendorID,
                        name: original.VendorName,
                        appointment: original.AppointmentDateTime,
                        walkIn: original.AppointmentDateTime ? moment(original.AppointmentDateTime).hour() === 0 : false,
                        orderSource: original.orderSource
                      }}
                      orderStatusId={original.OrderStatusID}
                      orderStatus={original.OrderStatus}
                      medicalExamOrderId={original.MedicalExamOrderID}
                      orderId={original.OrderID}
                      userEmail={props.userEmail}
                      reloadPage={props.onOrderChanged}
                      showVendorIdField={true}
                      onOrderChangedCallback={props.onOrderChangedCallback}
                      hidePadding={true}
                  />
                </Fragment>
            );
          }
        },
        {
          id: 'colClinic',
          Header: 'Clinic',
          accessor: d => `${d.VendorName} ${d.VendorAddress} ${d.VendorCity} ${d.VendorState} ${d.VendorZip}`,
          headerClassName: 'text-left'
        },
        {
          id: 'colVendorPhone',
          Header: 'Clinic Phone',
          accessor: 'VendorPhone',
          headerClassName: 'text-left'
        }
      ]
    }];

  return (
      <Fragment>
        <ReactTable
            className={'-striped -highlight -cursor-pointer'}
            columns={tableColumns}
            data={props.results}
            key={defaultPageSize}
            defaultPageSize={defaultPageSize}
            showPagination={props.results.length > defaultPageSize}
            PaginationComponent={CBReactTablePagination}
            noDataText={
              <PageMessage color={'white'} bgColor={'info'} text={'No orders found'}/>
            }
            getTrProps={
              (state, rowInfo, column) => {
                if (!rowInfo) return {};
                if (!rowInfo.original) return {};
                return {
                  className: rowInfo.original.selected ? 'selected' : ''
                }
              }
            }
            getTdProps={(state, rowInfo, column, instance) => {
              return {
                onClick: (e, handleOriginal) => {
                  if (!rowInfo) return;
                  const item = rowInfo.original;
                  props.handleItemSelected(item.OrderID);
                  if (handleOriginal) {
                    handleOriginal();
                  }
                }
              };
            }}
            />
      </Fragment>
  )
};

export default cmeResearchOrdersComponent;