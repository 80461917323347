/**
 * Input component that also validates email address format
 *
 * With each change, the validation check is performed. If the format is invalid, an error message is displayed
 * below the input field. (A blank email address is not considered invalid.)
 *
 * If an onChange handler is supplied, it is then invoked. In addition, if an onValidationCheck handler is supplied,
 * it is invoked with a TRUE or FALSE value indicating whether the email address is valid.
 *
 * Also accepts the following properties which are set on the inner <input> element:
 *
 *  - type          (default: 'text')
 *  - name          (default: 'email')
 *  - value         REQUIRED!!
 *  - placeholder   (default: 'Email')
 *  - className     (Note: if the email address is invalid, the classname 'is-invalid' is added)
 *  - onBlur
 *  - autoComplete
 */

import React, {Component} from 'react';
import classNames from 'classnames';
import _ from 'lodash';

// eslint-disable-next-line no-control-regex
const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;

export class InputEmail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: '',
      isValid: true
    };
  }

  componentDidMount() {
    const value = (this.props.value || '').trim();
    const isValid = value.length === 0 || emailRegex.test(value);
    // If the containing component specified an onValidationCheck handler, invoke it
    if (this.props.onValidationCheck) {
      this.props.onValidationCheck(isValid);
    }
    this.setState({value, isValid});
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.value !== this.props.value) {
      const value = (this.props.value || '').trim();
      const isValid = value.length === 0 || emailRegex.test(value);
      // If the containing component specified an onValidationCheck handler, invoke it
      if (this.props.onValidationCheck) {
        this.props.onValidationCheck(isValid);
      }
      this.setState({value, isValid});
    }
  }

  // Check the validity of the email address when the user edits the field
  handleChange = e => {
    const value = e.target.value.trim();  // Trim leading and trailing spaces after every change
    const isValid = value.length === 0 || emailRegex.test(value);
    this.setState({value, isValid});
    // If the containing component specified an onChange handler, invoke it
    if (this.props.onChange) {
      e.target.value = value; // in case we trimmed
      this.props.onChange(e);
    }
    // If the containing component specified an onValidationCheck handler, invoke it
    if (this.props.onValidationCheck) {
      this.props.onValidationCheck(isValid);
    }
  };

  handleBlur = e => {
    if (this.props.onBlur) {
      // trim leading and trailing spaces
      e.target.value = e.target.value.trim();
      this.props.onBlur(e);
    }
  };

  render() {
    const rest = _.omit(this.props, ['type', 'name', 'value', 'placeholder', 'className', 'onBlur', 'onChange', 'onValidationCheck']);
    return (
        <>
          <input
              type={this.props.type || 'text'}
              name={this.props.name || 'email'}
              value={this.state.value}
              placeholder={this.props.placeholder || 'Email'}
              className={classNames({[this.props.className]: true, 'is-invalid': !this.state.isValid})}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              aria-label='Email Input'
              {...rest}
          />
          {!this.state.isValid &&
          <small className="form-text text-danger">Please enter a properly formatted email address</small>
          }
        </>
    );
  }

};

export default InputEmail;