import React, {Fragment} from 'react';
import {Modal, ModalHeader, ModalBody} from 'reactstrap';
import OrderStatusFormComponent from '../OrderStatusForm/orderStatusFormComponent';
import VendorLookupContainer from '../../../VendorLookup/VendorLookupContainer';
import {Rnd} from 'react-rnd';

const editOrderStatusModalComponent = props => {
  const {modalOpen, vendorLookUpMode, orderInfo} = props;
  const modalClassName = 'modal-lg';
  const modalTitle = vendorLookUpMode ? 'Clinic Lookup' : 'Update Order';
  const vendorLookUpWrapperClassName = !vendorLookUpMode ? 'd-none' : '';
  const orderStatusFormWrapperClassName = vendorLookUpMode ? 'd-none' : '';
  return (
      <Fragment>
        <Modal isOpen={modalOpen} className={modalClassName}>
          <Rnd dragHandleClassName={'modal-header'} enableResizing={false}>
            <ModalHeader className={'cursor-move'}>{modalTitle}</ModalHeader>
            <ModalBody className={'roundBodyBottom'}>
              <div className={orderStatusFormWrapperClassName}>
                <OrderStatusFormComponent
                    orderInfo={orderInfo}
                    hideOrderStatus={props.hideOrderStatus}
                    handleOrderStatusChange={props.handleOrderStatusChange}
                    handleAppointmentDateChange={props.handleAppointmentDateChange}
                    handleVendorLookup={props.handleVendorLookup}
                    handleUpdateOrder={props.handleUpdateOrder}
                    handleCancelEditOrder={props.handleCancelEditOrder}
                    buttonWrapClassName={'modal-footer'}
                    onCheckboxChange={props.handleCheckboxChange}
                />
              </div>
              <div className={vendorLookUpWrapperClassName}>
                <VendorLookupContainer
                    programTypes={'CertifiedMedExamNetwork'}
                    handleConfirmVendorSelection={props.handleConfirmVendorSelection}
                    handleCancelVendorSelection={props.handleCancelVendorSelection}
                    buttonWrapClassName={'modal-footer'}
                    showVendorIdField={props.showVendorIdField}
                    isDrugTesting={false}
                />
              </div>
            </ModalBody>
          </Rnd>
        </Modal>
      </Fragment>
  )
};

export default editOrderStatusModalComponent;