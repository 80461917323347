const hasPermission = (user, permissionToCheck) => {
  return user.permissions && user.permissions.includes(permissionToCheck);
};

const hasAnyPermissions = (user, permissionGroupToCheck) => {
  if (!permissionGroupToCheck) return false;
  return Object.values(permissionGroupToCheck).some(permission => hasPermission(user, permission));
};

const whichPermissions = (user, permissionGroupToCheck) => {
  if (!permissionGroupToCheck) return [];
  return Object.values(permissionGroupToCheck).filter(permission => hasPermission(user, permission));
};

export {
  hasPermission,
  hasAnyPermissions,
  whichPermissions
};