import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import axios from "axios";
import {alertsOperations} from '../../../../../ducks/alerts';
import {modalOperations} from "../../../../../ducks/modal";
import {withRouter} from 'react-router-dom';
import {Rnd} from "react-rnd";
import _ from 'lodash';
import Select from 'react-select';
import async from 'async';
import settingsAPI from '../../../../../services/settings';
import {Progress} from 'reactstrap';

class BulkTransferEmployeeModalContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTargetSite: {},
      employmentStatusList: []
    };
  }

  componentDidMount() {
    this.cancelSource = axios.CancelToken.source();
    settingsAPI
    .getEmploymentStatus(this.cancelSource.token)
    .then(employmentStatusList => {
      this.setState({employmentStatusList});
    })
    .catch(err => {
      console.error(err);
    });
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!_.isEqual(this.props.employeeList, prevProps.employeeList)) {
      this.loadData();
    }
  }

  componentWillUnmount() {
    this.cancelSource.cancel('userCanceled');
  }

  loadData = () => {
    //the props are coming in on the info prop of the modal container, so map them to the ones we need
    this.setState({
      employeeList: this.props.info.employeeList
    });
  };

  handleTransferSelect = (propertyName, value) => {
    this.setState({[propertyName]: value});
  };

  performTransfer = () => {
    const {selectedTargetSite, employeeList, employmentStatusList} = this.state;
    const errorList = [];
    const skippedList = [];
    const userCount = employeeList.length;
    let progress = 0;
    this.setState({userCount, progress});

    async.eachOfSeries(employeeList, (employee, e, cb) => {
      const employmentStatus = employmentStatusList.filter((status) => status.status === employee.employmentStatus);
      if (employmentStatus.length === 0) {
        errorList.push({
          employeeId: employee.employeeId,
          employeeName: employee.employeeName,
          error: 'employment status could not be matched'
        });
        cb(null);
      }
      if (employee.employerId === selectedTargetSite.value) {
        //this one doesn't need to be done. so add to the skipped list
        skippedList.push({employeeId: employee.employeeId, employeeName: employee.employeeName});
      }
      axios.patch(`/v1/employees/${employee.employeeId}`, {
        transfer: {
          newClientId: selectedTargetSite.value,
          oldClientId: employee.employerId,
          jobTitleId: employee.jobTitleId,
          employmentStatusId: employmentStatus[0].employmentStatusId
        }
      }, {cancelToken: this.cancelSource.token})
      .then(result => {
        const progress = Math.floor(((e + 1) / userCount) * 100);
        this.setState({progress});
        return cb(null);
      })
      .catch(err => {
        console.log(err);
        const errorMsg = err.response && err.response.data ? err.response.data.errorText : 'error completing transfer';
        errorList.push({employeeId: employee.employeeId, error: errorMsg, employeeName: employee.employeeName});
        const progress = Math.floor(((e + 1) / userCount) * 100);
        this.setState({progress});
        return cb(null);
      });
    }, err => {
      this.setState({complete: true, errorList, skippedList});
    });
  };

  cancelTransfer = () => {
    this.props.hideModal(false);
  };

  finishTransfer = () => {
    this.setState({
      complete: null,
      errorList: null,
      skippedList: null,
      progress: null,
      userCount: null,
      selectedTargetSite: {},
      employmentStatusList: []
    });
    this.props.hideModal(true, {refreshData: true});
  };

  render() {
    const {
      employeeList, selectedTargetSite, progress, userCount, errorList, skippedList, complete
    } = this.state;

    const targetSiteOptions = this.props.auth.user.sites.map(site => ({
      value: site.legacyClientId,
      label: site.name
    }));
    targetSiteOptions.sort((a, b) => a.label.localeCompare(b.label));
    if (!employeeList) {
      return null;
    }

    return (
        <Fragment>
          <Modal isOpen={true}>
            <Rnd dragHandleClassName={'modal-header'} enableResizing={false}>
              <ModalHeader
                  className={'cursor-move'}>Transfer{progress || progress === 0 ? 'ring' : ''} the {employeeList.length} selected
                employee{employeeList.length > 1 ? 's' : ''} to
                {progress || progress === 0 ? ' ' + selectedTargetSite.label : ' another work location'} </ModalHeader>
              <ModalBody>
                {(progress || progress === 0) && !complete ?
                    <Fragment>
                      <div>Progress: {progress}% of {userCount} user{userCount > 1 ? 's' : ''}</div>
                      <Progress value={progress}/>
                    </Fragment>
                    :
                    (complete) ?
                        <Fragment>
                          <div> {errorList.length === 0 && skippedList.length === 0 && 'Transfer completed successfully'}</div>
                          {errorList.length > 0 && <div>
                            <div>The
                              following {errorList.length} employee transfers completed
                              with errors:
                            </div>
                            <div> {errorList.map((skipped) => skipped.employeeName).join(', ')} </div>
                          </div>}
                          {skippedList.length > 0 && <div>
                            <div className={skippedList.length > 0 && errorList.length > 0 ? 'border-top mt-1' : ''}>The
                              following {skippedList.length} employee{skippedList.length > 1 ? `s were ` : ` was`} skipped
                              because they are already working at the
                              destination location:
                            </div>
                            <div> {skippedList.map((skipped) => skipped.employeeName).join(', ')} </div>
                          </div>}
                        </Fragment>
                        :
                        <Fragment>
                          <div className={'form-group row'}>
                            <label className={'col-sm-3 col-form-label'}>New Location</label>
                            <div className={'col-sm-9'}>
                              <Select
                                  classNamePrefix={'Select'}
                                  placeholder={'New Location'}
                                  options={targetSiteOptions}
                                  value={targetSiteOptions.filter(option => option.value === selectedTargetSite.value)[0]}
                                  onChange={option => this.handleTransferSelect('selectedTargetSite', option)}
                              />
                            </div>
                          </div>
                        </Fragment>}
              </ModalBody>
              <ModalFooter>
                {complete &&
                <Fragment>
                  <button className={'btn btn-primary btn-sm'} onClick={this.finishTransfer}>OK
                  </button>
                </Fragment>}
                {!progress && progress !== 0 &&
                <Fragment>
                  <button className={'btn btn-primary btn-sm'} onClick={this.performTransfer}
                          disabled={selectedTargetSite.value === -1 ? 'disabled' : ''}>Transfer
                  </button>
                  <button className={'btn btn-secondary btn-sm'} onClick={this.cancelTransfer}>Cancel</button>
                </Fragment>}
              </ModalFooter>
            </Rnd>
          </Modal>
        </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = {
  hideModal: modalOperations.hideModal,
  addAlert: alertsOperations.addAlert
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BulkTransferEmployeeModalContainer));