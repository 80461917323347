import actions from './actions';

const addAlert = actions.addAlert;

const removeAlert = actions.removeAlert;

const clearAlerts = actions.clearAlerts;

export default {
  addAlert,
  removeAlert,
  clearAlerts
};