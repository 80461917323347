import React from 'react';
import MenuEntry from './components/MenuEntry/MenuEntryContainer';

const sidebarComponent = props => {
  return (
      <div id="sidebar">
        <div className="sidebar-menu">
          {props.routes.map(route => {
            return (
                <MenuEntry path={''} entry={route} key={route.text + route.path} alertsCount={props.alertsCount}/>
            )
          })}
        </div>
      </div>
  );
};

export default sidebarComponent;