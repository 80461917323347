import types from './types';

const initialState = [];

const alertsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_ALERT:
      return [
        ...state,
        {
          text: action.payload.text,
          style: action.payload.style,
          id: action.payload.id
        }
      ];

    case types.REMOVE_ALERT:
      return state.filter(a => a.id !== action.payload.id);

    case types.CLEAR_ALERTS:
      return [];

    default:
      return state;
  }
};

export default alertsReducer;