import React, {Fragment} from 'react';
import StateSelect from '../../../../../../../common/StateSelect/StateSelect';
import DateInputWrapper from '../../../../../../../common/DateWrapper/DateInputWrapperContainer';
import moment from 'moment';

const addAccidentModalComponent = props => {

  return (
      <Fragment>
        <div className={'form-group row no-gutters'}>
          <label className={'col-sm-5 col-form-label'}>Date of Accident<span
              className={'text-danger'}>*</span></label>
          <div className={'datepicker-wrapper col-sm-7'}>
            <DateInputWrapper selected={props.accidentInfo.date ? moment(props.accidentInfo.date) : null}
                              maxDate={moment()}
                              dateFormat={"M/D/YYYY"}
                              placeholderText="mm/dd/yyyy"
                              onChangeHandler={(e) => props.onDateChange(e, "date")}
                              name="date"
                              id="date"
                              autoComplete="new-password"
                              className={props.showFieldValidation && !props.accidentInfo.date ? "is-invalid form-control-sm form-control" : "form-control-sm form-control"}
                              dropdownMode={'scroll'}
            />
          </div>
        </div>
        <div className={'form-group row no-gutters'}>
          <label className={'col-sm-5 col-form-label'}>City <span className={'text-danger mr-1'}>*</span></label>
          <div className={'col-sm-7'}>
            <input type="text" autoComplete="new-password"
                   className={props.showFieldValidation && (!props.accidentInfo.city || props.accidentInfo.city.length === 0) ?
                       "is-invalid form-control form-control-sm" :
                       'form-control form-control-sm'}
                   name="city"
                   onChange={props.onChange}
                   value={props.accidentInfo.city ? props.accidentInfo.city : ''}/>
          </div>
        </div>
        <div className={'form-group row no-gutters'}>
          <label className={'col-sm-5 col-form-label'}>State <span className={'text-danger'}>*</span></label>
          <div className={'col-sm-7'}>
            <span className="flex-fill">
                    <StateSelect name="state"
                                 id="state"
                                 className={props.showFieldValidation && (!props.accidentInfo.state || props.accidentInfo.state.trim().length === 0) ? "is-invalid" : ""}
                                 placeholder="State"
                                 value={props.accidentInfo.state}
                                 onChange={(e) => props.onSelectChange(e, "state")}
                    />
          </span>
          </div>
        </div>
        <div className={'form-group row no-gutters'}>
          <label className={'col-sm-5 col-form-label'}>Hazmat Release? </label>
          <div className={'col-sm-7'}>
            <input type="checkbox"
                   id="hazmat" onChange={props.onChange}
                   checked={props.accidentInfo.hazmat}
            />
          </div>
        </div>
        <div className={'form-group row no-gutters'}>
          <label className={'col-sm-5 col-form-label'}>Driver Cited? </label>
          <div className={'col-sm-7'}>
            <input type="checkbox"
                   id="cited" onChange={props.onChange}
                   checked={props.accidentInfo.cited}
            />
          </div>
        </div>
        <div className={'form-group row no-gutters'}>
          <label className={'col-sm-5 col-form-label'}>Number of Injuries <span
              className={'text-danger mr-1'}>*</span></label>
          <div className={'col-sm-7'}>
            <input type="text" autoComplete="new-password"
                   className={props.showFieldValidation && !props.accidentInfo.injuries ?
                       "is-invalid form-control form-control-sm" :
                       'form-control form-control-sm'}
                   name="injuries"
                   onChange={props.onChange}
                   value={props.accidentInfo.injuries ? props.accidentInfo.injuries : ''}/>
          </div>
        </div>
        <div className={'form-group row no-gutters'}>
          <label className={'col-sm-5 col-form-label'}>Number of Vehicles Disabled <span
              className={'text-danger mr-1'}>*</span></label>
          <div className={'col-sm-7'}>
            <input type="text" autoComplete="new-password"
                   className={props.showFieldValidation && !props.accidentInfo.disabled ?
                       "is-invalid form-control form-control-sm" :
                       'form-control form-control-sm'}
                   name="disabled"
                   onChange={props.onChange}
                   value={props.accidentInfo.disabled ? props.accidentInfo.disabled : ''}/>
          </div>
        </div>
        <div className={'form-group row no-gutters'}>
          <label className={'col-sm-5 col-form-label'}>Number of Fatalities <span
              className={'text-danger mr-1'}>*</span></label>
          <div className={'col-sm-7'}>
            <input type="text" autoComplete="new-password"
                   className={props.showFieldValidation && !props.accidentInfo.fatalities ?
                       "is-invalid form-control form-control-sm" :
                       'form-control form-control-sm'}
                   name="fatalities"
                   onChange={props.onChange}
                   value={props.accidentInfo.fatalities ? props.accidentInfo.fatalities : ''}/>
          </div>
        </div>
      </Fragment>
  );
};

export default addAccidentModalComponent;