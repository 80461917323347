import React, {Component} from 'react';
import axios from 'axios';

class ProtectedFileDownload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: props.path || '',
      fileName: props.fileName || 'document.pdf',
      iconClass: props.iconClass || 'fa-download',
      buttonClass: props.buttonClass || 'btn-light'
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const tempState = {};
    if (nextProps.path !== prevState.path) {
      tempState.path = nextProps.path;
    }
    if (nextProps.fileName !== prevState.fileName) {
      tempState.fileName = nextProps.fileName || 'document.pdf';
    }
    if (nextProps.iconClass !== prevState.iconClass) {
      tempState.iconClass = nextProps.iconClass || 'fa-download';
    }
    if (nextProps.buttonClass !== prevState.buttonClass) {
      tempState.buttonClass = nextProps.buttonClass || 'btn-light';
    }
    return tempState;
  };

  downloadFile = e => {
    e.preventDefault();
    axios({
      url: this.state.path,
      method: 'GET',
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL && window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', this.state.fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch(err => {
      console.error(err);
    });
  };

  render() {
    return (
        <button className={`btn ${this.state.buttonClass}`} type={"button"} onClick={this.downloadFile}>
          <i className={`fas ${this.state.iconClass}`}/>
        </button>
    );
  }
}

export default ProtectedFileDownload;