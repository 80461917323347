import React from 'react';
import Select from 'react-select';

const clientSiteSelectorComponent = props => {
  let clientOptions = props.displaySites
    .map(client => ({
      value: client._id,
      label: client.name,
      levelNumber: client.levelNumber
    })).sort(function (a, b) {
        if ( a.levelNumber === 1 )  return -1;
        if ( b.levelNumber === 1 )  return  1;      
        return a.label.localeCompare(b.label);
    });
  
  if (props.selectAll) {
    clientOptions.unshift({value: 'allSites', label: 'All Sites', mongoId: 'allSites', legacyClientId: 'allSites'});
  }
  let selectedValue = null;
  if (props.allSitesSelected) {
    selectedValue = clientOptions.find(co => co.value === 'allSites');
  } else {
    if (props.multiSelect) {
      selectedValue = clientOptions.filter(co => props.selectedSites.map(ss => ss._id).includes(co.value));
    } else {
      if (props.selectedSite && Object.keys(props.selectedSite).length > 0) {
        selectedValue = clientOptions.find(co => co.value === props.selectedSite._id);
      }
    }
  }

  if (props.hideSelect || clientOptions.length === 0) {
    return null;
  }
  return (
      <Select
          isMulti={props.multiSelect}
          classNamePrefix={'Select'}
          className={'sites-selector'}
          placeholder={props.placeholder}
          options={clientOptions}
          value={selectedValue || null}
          onChange={props.onSitesChanged}
          isDisabled={props.selectIsDisabled}
      >
      </Select>
  );
};

export default clientSiteSelectorComponent;