import React, {Fragment} from 'react';
import ReactTable from 'react-table';
import PageMessage from '../../../common/PageMessage/pageMessageComponent';

const csaNotificationsModalComponent = props => {
  const data = props.data || {};
  const basics = props.basics || [];

  const tableColumns = basics.map((basic) => {
    return {
      Header: x => {
        return (
            <Fragment>
              <small>{basic.BasicID === 4 ? 'Drug & Alcohol' : basic.BasFullName}</small>
              {props.editMode &&
              <div>
                <input
                    type="checkbox"
                    className="checkbox ml-1"
                    onChange={(e) => props.onSelectAll(e, basic.BasName)}
                    checked={props.toggleAllCheckStatuses[basic.BasName] === data.length}
                    ref={input => {
                      if (input) {
                        input.indeterminate = props.toggleAllCheckStatuses[basic.BasName] > 0 && props.toggleAllCheckStatuses[basic.BasName] < data.length;
                      }
                    }}
                /></div>}
            </Fragment>
        );
      },
      id: basic.BasName,
      Cell: ({original}) => {
        if (props.editMode) {
          return <input type="checkbox"
                        checked={original[basic.BasName]}
                        onChange={(e) => props.onNotificationChange(e, basic.BasName, original.typeName)}
          />
        }
        if (original[basic.BasName]) {
          return <i className="fas fa-check text-success"/>
        }
        return null;
      },
      headerClassName: 'text-center table-cell-wrap',
      className: 'text-center',
      sortable: false,
      width: 85
    }
  });
  tableColumns.unshift({
    id: 'colNotificationType',
    Header: x => {
      return <small>Notification Type</small>
    },
    Cell: ({original}) => {
      return <div>{props.editMode &&
      <input type="checkbox"
             onChange={(e) => props.onSelectAllType(e, original.typeName)}
             checked={props.toggleAllCheckStatuses[original.typeName] === basics.length}
             ref={input => {
               if (input) {
                 input.indeterminate = props.toggleAllCheckStatuses[original.typeName] > 0 && props.toggleAllCheckStatuses[original.typeName] < basics.length;
               }
             }}
      />} {original.typeName} </div>
    },
    headerClassName: 'text-center table-cell-wrap'
  });


  return <div className={'col-sm-12 p-0'}>
    <ReactTable
        className={'border mt-2'}
        data={data}
        columns={tableColumns}
        defaultPageSize={3}
        showPagination={false}
        noDataText={<PageMessage color={'white'} bgColor={'info'} text={'No notifications to display'} />}
    />
  </div>
};

export default csaNotificationsModalComponent;