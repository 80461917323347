import { combineReducers } from 'redux';

import randomsPoolCreationWizardReducers from './randomsPoolCreationWizard/reducers'

// COMBINE ALL OF OUR RANDOMS REDUCERS HERE IN THIS FILE
const randomsReducers = combineReducers({
  randomsPoolCreationWizard: randomsPoolCreationWizardReducers
  // next property for different randoms functionality here...
  // next property for different randoms functionality here...
  // etc.
});

export default randomsReducers;