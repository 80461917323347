import React from 'react';
import classes from './LoadingPanel.module.css';

const loadingPanelComponent = props => {
  return(
    <div className={classes.overlay}>
      <div className={`loading-panel ${classes.loadingPanel}`} >
        <img
          src="/images/sparc-spinner-fluid-150-percent-speed.gif"
          alt="Working . . ."
          className={classes.spinner}
        />
      </div>
    </div>
  );
};

export default loadingPanelComponent;