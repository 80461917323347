import React from 'react';
import stringUtils from '../../../../utils/stringUtils';

const SocialSecurityNumberDisplayComponent = ({ssn, redact, isHidden}) => {
  if (!ssn) {
    return null;
  }

  const ssnString = stringUtils.formatSSNOverride(ssn, redact, isHidden);
  return <div>{ssnString}</div>;
};

export default SocialSecurityNumberDisplayComponent;