import types from './types';
import step5DefaultState from './defaultState';

const randomsPoolCreationWizardStep5Reducer = (state = step5DefaultState, action) => {
  switch (action.type) {
    case types.RESET_STEP_5_GLOBAL_STATE: 
      return step5DefaultState;

    case types.SET_STEP5_EMPLOYEES_IN_POOL: {
      return {
        ...state,
        employeesInPool: action.payload
      }
    }

    default:
      return state;
  }
}

export default randomsPoolCreationWizardStep5Reducer;