import React from 'react';
import Select from 'react-select';

const ccrClientSelectorComponent = props => {
  const clientOptions = props.sites.map(s => ({
    value: s._id,
    label: s.name
  }));
  const selectedOption = clientOptions.find(co => co.value === props.selectedClient) || null;
  return (
      <Select
          isDisabled={props.disabled}
          className={'ccr-client-selector'}
          classNamePrefix={'Select'}
          options={clientOptions}
          onChange={props.onClientChanged}
          value={selectedOption}
          placeholder={'Select a client...'}
      />
  )
};

export default ccrClientSelectorComponent;