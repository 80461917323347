import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from "lodash";
import CCRClientSelectorComponent from './ccrClientSelectorComponent';
import { authOperations } from "../../../ducks/auth";
import { dqfOperations } from "../../../ducks/dqf";
import { concordeOperations } from '../../../ducks/concorde';

export class CCRClientSelectorContainer extends Component {
  componentDidMount() {
    this.props.getClientList();
  };

  /**
   * Event handler for when the CCR Sites Selector value is changed
   * @param selectedClient
   */
  handleClientChanged = selectedClient => {
    // dqfOperations reset dqf.sites
    this.props.setSelectedSites();
    this.props.setSiteTree({});
    this.props.setSessionClient(selectedClient.value);
    this.props.resetRosterViews();
    // authOperations: update values in auth.user
    this.props.getClientAndSites(selectedClient.value, true);
    if (this.props.onClientChanged) {
      this.props.onClientChanged(selectedClient);
    }
  };

  render() {
    return (
        <CCRClientSelectorComponent
            disabled={!!this.props.disabled}
            sites={_.orderBy(this.props.clientList, ['name'], ['asc'])}
            selectedClient={this.props.dqf.sites.sessionClient || null}
            onClientChanged={this.handleClientChanged}
        />
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  dqf: state.dqf,
  clientList: state.concorde.clientList
});

const mapDispatchToProps = {
  getClientAndSites: authOperations.getClientAndSites,
  setSelectedSites: dqfOperations.setSelectedSites,
  setSiteTree: dqfOperations.setSiteTree,
  setSessionClient: dqfOperations.setSessionClient,
  resetRosterViews: dqfOperations.resetRosterViews,
  getClientList: concordeOperations.getClientList
};

export default connect(mapStateToProps, mapDispatchToProps)(CCRClientSelectorContainer);