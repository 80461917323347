import axios from 'axios';
import actions from './actions';
import concordeSettings from '../../config/concordeClient';

const getClientList = () => dispatch => {
  axios.get(`/v1/clients?filters=topLevel,active`)
  .then(response => {
    if (response.status === 200) {
      dispatch(actions.setClientList(response.data.filter(site => site.legacyClientId !== concordeSettings.concordeClient.legacyClientId)));
    }
  })
  .catch(err => {
    console.error(err);
  });
};

export default {
  getClientList
};