import React, {Component} from 'react';
import axios from "axios";
import PropTypes from 'prop-types';

import OrderStatusSelectComponent from './orderStatusSelectComponent';

export class OrderStatusSelectContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOrderStatusId: -1,
      selectedOrderStatus: '',
      statuses: []
    }
  }

  componentDidMount() {
    this.cancelSource = axios.CancelToken.source();
    this.setState({
      selectedOrderStatusId: this.props.selectedOrderStatusId || -1,
      selectedOrderStatus: this.props.selectedOrderStatus || '',
    });
    this.setStatuses();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let newState = {};
    if (this.props.selectedOrderStatusId !== prevProps.selectedOrderStatusId) {
      newState.selectedOrderStatusId = this.props.selectedOrderStatusId;
    }
    if (this.props.selectedOrderStatus !== prevProps.selectedOrderStatus) {
      newState.selectedOrderStatus = this.props.selectedOrderStatus;
    }
    if (Object.keys(newState).length > 0) {
      this.setState({...newState});
    }
  }

  componentWillUnmount() {
    this.cancelSource.cancel('userCanceled');
  }


  /**
   * Method to populate Order Statuses select box with statuses via axios call
   */
  setStatuses = () => {
    axios.get('/v1/orderStatuses', {cancelToken: this.cancelSource.token})
    .then(res => {
      const statuses = res.data;
      if (statuses) {
        this.setState({statuses});
      }
    })
    .catch(err => {
      if (!axios.isCancel(err)) {
        console.error(err);
      }
    })
  };

  /**
   * Event handler for Order Statuses select box index/value change
   */
  handleStatusChanged = e => {
    e.preventDefault();
    const selectedOrderStatusId = parseInt(e.target.value, 10);
    const selectedOrderStatus = selectedOrderStatusId > -1 ? this.state.statuses.find(s => s.OrderStatusID === selectedOrderStatusId).OrderStatus : null;
    this.setState({
      selectedOrderStatusId,
      selectedOrderStatus
    });
    if (this.props.handleOrderStatusChange) {
      this.props.handleOrderStatusChange({selectedOrderStatusId, selectedOrderStatus});
    }
  };

  render() {
    return <OrderStatusSelectComponent
        selectedOrderStatusId={this.state.selectedOrderStatusId}
        statuses={this.state.statuses}
        onStatusChanged={this.handleStatusChanged}/>
  }
}

OrderStatusSelectContainer.propTypes = {
  handleOrderStatusChange: PropTypes.func
};

export default OrderStatusSelectContainer;