import types from './types';

const showModal = (displayModal, info) => ({
  type: types.SHOW_MODAL,
  payload: {
    displayModal,
    info
  }
});

const hideModal = (refreshData, responseInfo) => ({
  type: types.HIDE_MODAL,
  payload: {
    refreshData,
    responseInfo
  }
});

export default {
  showModal,
  hideModal
}