// Importing the setClientVersion module for its side effects only.
//This runs the module's global code, but doesn't actually import any values.
// The setClientVersion module sets a global variable `clientVersion` to the version number from package.json.
import './utils/setClientVersion'
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Route, Switch, BrowserRouter} from 'react-router-dom';
import classNames from 'classnames';

import {getCookie} from './utils/storageUtils';
import routes from './routes';

// hoc
import Alerts from './components/common/Alerts/AlertsComponent';
import ProtectedRoute from './components/common/ProtectedRoute/ProtectedRoute';
import UserSession from './components/common/UserSession/UserSessionContainer';

// layout
import Header from './components/layout/Header/HeaderComponent';
import Sidebar from './components/layout/Sidebar/SidebarContainer';
import Breadcrumbs from './components/layout/Breadcrumbs/BreadcrumbsContainer';
import Footer from './components/layout/Footer/FooterContainer';
import ModalContainer from "./components/common/Modal/ModalContainer";
import LoadingPanel from "./components/common/LoadingPanel/LoadingPanelContainer";

import {layoutOperations} from './ducks/layout';
import {dqfOperations} from './ducks/dqf';
import concordeSettings from './config/concordeClient';
import permissions from './config/permissions';
import {hasPermission} from './utils/permissions';

export class App extends Component {

  // Load the broadcast items from the server, and do it again every 600 seconds (10 minutes)
  _loadBroadcastItems = () => {
    // Only do it if there's a refreshToken cookie (if there's not, there's no user logged in!)
    if (!!getCookie('refreshToken')) {
      this.props.loadBroadcastItems();
      if (hasPermission(this.props.auth.user, permissions.dq.scoreMVRsManually)) {
        this.props.getUnscoredMVRs();
      }
    }
    setTimeout(this._loadBroadcastItems, 600000);  // 10 * 60 * 1000ms
  };

  /**
   * Load Matomo (a web analytics application)
   */
  _loadMatomoAnalytics = () => {
    var _mtm = window._mtm = window._mtm || [];
    _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
    (function() {
      var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
      g.async=true;
      g.src=window.env.MATOMO_TAG_MANAGER_CONTAINER_URL;
      s.parentNode.insertBefore(g,s);
    })();
  }

  componentDidMount() {
    this._loadBroadcastItems();
    this._loadMatomoAnalytics();
  }

  /**
   * Recursively traverse all the routes, and build the full path for each route. As each route is processed,
   * add it to the global routeList.
   *
   * @param basePath
   * @param routes
   * @param isConcordeUser
   * @private
   */
  _buildRouteFullPaths = (basePath, routes, isConcordeUser) => {
    routes.forEach(route => {
      const path = basePath + route.path || '';
      // If the route is just a menu heading, we don't need a Route for it
      if (!route.menuHeading) {
        route.fullPath = path;
        this.routeList.push(route);
      }
      if (isConcordeUser && route.externalOnly) { //if it's a concorde user and this is the contact us route then skip it
        return;
      }
      // Process this route's "children"
      if (route.hasOwnProperty('routes')) {
        this._buildRouteFullPaths(path, route.routes, isConcordeUser);
      }
    });
  };

  render() {
    const {isAuthenticated} = this.props.auth;
    const isNonUserPseudoUser = isAuthenticated && this.props.auth.user.email === 'nonuserpseudouser';
    const {layout} = this.props;
    const showSidebar = isAuthenticated && !isNonUserPseudoUser && layout.sidebar.isOpen && this.props.auth.user.permissions && !this.props.auth.user.auditorToken;
    const isConcordeUser = (this.props.auth.user.client && this.props.auth.user.client.legacyClientId === concordeSettings.concordeClient.legacyClientId) ||
        (hasPermission(this.props.auth.user, permissions.customerCare.assumeClient));
    // Generate the full paths for the routes
    this.routeList = [];
    this._buildRouteFullPaths('', routes, isConcordeUser);

    return (
                <BrowserRouter>
                  <>
                  <Alerts/>
                  <div className={classNames({
                    'App': true,
                    'sidebar-open': showSidebar,
                    'sidebar-closed': !showSidebar,
                    'full-background-image': !isAuthenticated
                  })}>
                    <LoadingPanel/>
                    {isAuthenticated && !isNonUserPseudoUser &&
                    <Header/>
                    }
                    <ModalContainer/>
                    <UserSession/>
                    {showSidebar && <Sidebar/>}
                    <div id="main-container" className={classNames({'nomargins': !isAuthenticated})}>
                      <div className="wrapper">
                        <main id="main" className="container">
                          <Breadcrumbs/>
                          <Switch>
                            {/* Create a Route for each route in the list*/}
                            {this.routeList.map(route => {
                              if (route.permission || route.protected) {
                                // The route requires a specific permission or simply requires that the user
                                // be logged in, so use our ProtectedRoute component
                                return (
                                    <ProtectedRoute
                                        path={route.absolutePath ? route.path : route.fullPath}
                                        exact
                                        permission={route.permission}
                                        component={route.component}
                                        key={`ROUTE_${route.fullPath}_${route.text}`}
                                    />
                                );
                              } else {
                                // The route is not protected, so use the standard React Route component
                                return (
                                    <Route
                                        path={route.absolutePath ? route.path : route.fullPath}
                                        exact
                                        component={route.component}
                                        key={`ROUTE_${route.fullPath}_${route.text}`}
                                    />
                                );
                              }
                            })}
                          </Switch>
                        </main>
                      </div>
                    </div>
                    {isAuthenticated && !isNonUserPseudoUser &&
                    <Footer/>
                    }
                  </div>
                  </>
                </BrowserRouter>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  layout: state.layout
});

const mapDispatchToProps = {
  loadBroadcastItems: layoutOperations.loadBroadcastItems,
  getUnscoredMVRs: dqfOperations.getUnscoredMVRs
};

export default connect(mapStateToProps, mapDispatchToProps)(App);