import React, {Component, Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import moment from 'moment';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';
import {sessionOperations} from '../../../ducks/userSession/index';
import {authOperations} from '../../../ducks/auth/index';
import axios from 'axios';

export class UserSessionContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      intervalId: null,
      warningIntervalId: null,
      warningDurationMS: 0,
      warningModalOpen: false,
      durationDisplay: ''
    }
  }

  componentDidMount() {
    this.cancelSource = axios.CancelToken.source();
    if (!this.state.intervalId && this.props.userSession.status === 'ACTIVE') {
      this.startSessionTimer();
    }
  }

  componentWillUnmount() {
    this.cancelSource.cancel('userCanceled');
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.userSession.status !== prevProps.userSession.status) {
      if (this.props.userSession.status === 'ACTIVE' && prevProps.userSession.status !== 'ACTIVE') {
        this.startSessionTimer();
      }
      if (this.props.userSession.status !== 'ACTIVE' && prevProps.userSession.status === 'ACTIVE') {
        this.stopSessionTimer();
      }
    }
  }

  startSessionTimer = () => {
    this.sessionTimerTick(); //for tick 0 to n (interval)
    const intervalId = setInterval(this.sessionTimerTick, 30000);
    this.setState({intervalId});
  };

  stopSessionTimer = () => {
    clearInterval(this.state.intervalId);
    this.setState({intervalId: null});
  };

  sessionTimerTick = () => {
    if (!this.state.warningModalOpen) {
      const now = moment(Date.now());
      const expireAt = this.props.userSession.expireAt;
      const warnAt = this.props.userSession.warnAt;
      if (warnAt <= now) {
        const warningDurationMS = expireAt.diff(warnAt);
        this.setState({warningDurationMS});
        this.startWarningTimer();
      }
    }
  };

  userLogoff = () => {
    this.props.setSessionStatus('EXITED');
    this.props.logoutUser();
  };

  expireUserSession = () => {
    this.props.setSessionStatus('EXPIRED');
    this.props.autoLogoutUser();
  };

  startWarningTimer = () => {
    this.warningTick(); // tick 0
    const warningIntervalId = setInterval(this.warningTick, 1000);
    this.setState({warningIntervalId, warningModalOpen: true});
  };

  stopWarningTimer = () => {
    clearInterval(this.state.warningIntervalId);
    this.setState({
      warningModalOpen: false,
      warningIntervalId: null,
      warningDurationMS: 0,
      durationDisplay: ''
    });
  };

  warningTick = () => {
    let {warningDurationMS} = this.state;
    warningDurationMS = warningDurationMS - 1000;
    const durationDisplay = moment(moment.duration(warningDurationMS)._data).format('mm:ss');
    this.setState({warningDurationMS, durationDisplay});
    if (warningDurationMS < 1000) {
      this.stopWarningTimer();
      this.expireUserSession();
    }
  };

  handleStayLoggedOn = e => {
    e.preventDefault();
    this.props.setSessionStatus('ACTIVE');
    this.stopWarningTimer();
    //make an axios call in order to refresh the bearer token
    axios.get(`/v1/settings/lists?collections=employmentStatus`, {cancelToken: this.cancelSource.token})
    .then(response => {
    }).catch(err => {
    });
  };

  handleLogOff = e => {
    this.props.history.push('/');
    this.stopWarningTimer();
    this.userLogoff();
  };

  render() {
    return (
        <Fragment>
          <Modal isOpen={this.state.warningModalOpen}>
            <ModalHeader>Your session is about to expire due to inactivity</ModalHeader>
            <ModalBody>
              <p>You will be logged out in <span className="text-danger lead">{this.state.durationDisplay}</span>. Do
                you you want to stay logged on?</p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.handleStayLoggedOn}>Yes - stay logged on </Button>
              <Button color="secondary" onClick={this.handleLogOff}>No - sign me out</Button>
            </ModalFooter>
          </Modal>
        </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userSession: state.userSession,
  auth: state.auth
});

const mapDispatchToProps = {
  setSessionStatus: sessionOperations.setSessionStatus,
  logoutUser: authOperations.logoutUser,
  autoLogoutUser: authOperations.autoLogoutUser
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserSessionContainer));
