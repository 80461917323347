import React, {Component} from 'react';
import {connect} from "react-redux";
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import OrderMVR from './orderMVRModalComponent';
import axios from "axios";
import {alertsOperations} from '../../../../../../ducks/alerts';
import {modalOperations} from "../../../../../../ducks/modal";
import validator from '@civteam/cc-driver-license-validator';
import moment from 'moment';
import _ from 'lodash';
import {Rnd} from "react-rnd";
import settingsAPI from '../../../../../../services/settings';

class OrderMVRModalContainer extends Component {
  constructor(props) {
    super(props);

    //the props are coming in on the info prop of the modal container, so map them to the ones we need
    this.state = {};
  }

  componentDidMount() {
    this.cancelSource = axios.CancelToken.source();
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!_.isEqual(this.props.info, prevProps.info)) {
      this.loadData();
    }
  }

  componentWillUnmount() {
    this.cancelSource.cancel('userCanceled');
  }

  loadData = async () => {
    let driversLicenseFormatRules;
    try {
      driversLicenseFormatRules = await settingsAPI.getDriversLicenseFormatRules(this.cancelSource.token);
    } catch (err) {
      console.error(err);
    }
    this.setState({
      employeeInfo: this.props.info.employeeInfo,
      message: this.props.info.message,
      licenseClassList: this.props.info.licenseClassList,
      licenseFormatInvalid: this.props.info.licenseFormatInvalid,
      driversLicenseFormatRules
    });
  };

  revalidateDriverLicenseInfo = (state, license) => {
    const dlState = state && state.trim().length > 0 ? state : null;
    const dlNum = license && license.trim().length > 0 ? license : null;
    if (dlState && dlNum && !this.state.overrideLicenseValidation) {
      const licenseFormatInvalid = !validator.validateLicenseNumber(dlNum, dlState, this.state.driversLicenseFormatRules);
      this.setState({licenseFormatInvalid});
    }
  };

  /**
   * on change handler for all of the editable fields uses the id to edit the field in the employeeInfo object
   * that corresponds to this item
   * @param e
   */
  handleChange = e => {
    const employeeInfo = _.cloneDeep(this.state.employeeInfo);
    employeeInfo[e.target.name] = e.target.value;
    if (e.target.name === 'license') {
      this.revalidateDriverLicenseInfo(employeeInfo.state, employeeInfo.license);
    }
    this.setState({employeeInfo});
  };

  /**
   * the change handler for the date field, uses the passed in name parameter to determine which field to update
   * @param date
   * @param name
   */
  handleDateChange = (date, name) => {
    const employeeInfo = _.cloneDeep(this.state.employeeInfo);
    employeeInfo[name] = date ? moment(date) : null;
    this.setState({employeeInfo});
  };

  /**
   * the change handler for the select fields, uses the passed in name parameter to determine which field to update
   * @param e
   * @param name
   */
  handleSelectChange = (e, name) => {
    const selectedItem = e ? e.value : -1;
    const employeeInfo = _.cloneDeep(this.state.employeeInfo);
    employeeInfo[name] = selectedItem;
    if (name === 'state') {
      // for 'state' we want an empty string if the input is cleared out
      employeeInfo[name] = selectedItem || '';
      this.revalidateDriverLicenseInfo(employeeInfo.state, employeeInfo.license);
    }
    this.setState({employeeInfo});
  };


  /**
   * validateMVRInfo - before ordering an mvr, verify that the fields are valid
   * @returns {{message: string, validData: boolean}}
   */
  validateMVRInfo = (employeeInfo) => {
    let validData = true;
    let message = '';
    let licenseFormatInvalid = false;
    const dlState = employeeInfo.state && employeeInfo.state.trim().length > 0 ? employeeInfo.state : null;
    const dlNum = employeeInfo.license && employeeInfo.license.trim().length > 0 ? employeeInfo.license : null;

    if (!dlState || !dlNum || !employeeInfo.dob || !employeeInfo.licenseClassId) {
      validData = false;
      message = 'You must fill in all of the required fields.'
    }
    if (dlState && dlNum) {
      if (!this.state.overrideLicenseValidation && !validator.validateLicenseNumber(dlNum, dlState, this.state.driversLicenseFormatRules)) {
        validData = false;
        licenseFormatInvalid = true;
        message = `You must update the driver's license information. The number ${dlNum} is invalid for ${dlState}`;
      }
    }
    return {message, validData, licenseFormatInvalid};
  };

  placeOrder = async () => {
    const {employeeInfo} = this.state;
    const {validData, message, licenseFormatInvalid} = this.validateMVRInfo(employeeInfo);
    if (validData) {
      await this.setStateAsync({orderPlaced: true});
      const updates = {
        dob: employeeInfo.dob,
        license: employeeInfo.license,
        state: employeeInfo.state,
        licenseClassId: employeeInfo.licenseClassId
      };
      axios.patch(`/v1/employees/${employeeInfo.employeeId}`, updates, {cancelToken: this.cancelSource.token})
      .then(() => {
        this.props.addAlert('Employee information updated successfully');
        const info = {
          employeeInfo: this.state.employeeInfo,
          refreshData: true,
          employeeId: this.state.employeeInfo.employeeId //employee id is needed separately for the dac order mvr buttons
        };
        this.props.showModal('verification', info);
      })
      .catch(err => {
        this.props.addAlert('Employee information could not be updated.', 'danger');
      });
    } else {
      this.props.addAlert(message, 'danger');
      this.setState({message, licenseFormatInvalid});
    }
  };

  cancelOrderPlacement = () => {
    this.props.hideModal(false);
  };

  handleOverrideValidation = e => {
    const employeeInfo = {...this.state.employeeInfo};
    this.setState({overrideLicenseValidation: e.target.checked}, () => {
      this.revalidateDriverLicenseInfo(employeeInfo.state, employeeInfo.license)
    });
  };

  setStateAsync = state => {
    return new Promise(resolve => {
      this.setState({...state}, () => resolve())
    });
  };


  render() {
    const {
      employeeInfo, message, licenseClassList, licenseFormatInvalid
    } = this.state;

    if (!employeeInfo) {
      return null;
    }

    return (
        <Modal isOpen={true}>
          <Rnd dragHandleClassName={'modal-header'} enableResizing={false}>
            <ModalHeader className={'cursor-move'}>Update Information
              for {employeeInfo.firstName} {employeeInfo.lastName} </ModalHeader>
            <ModalBody>
              <OrderMVR
                  employeeInfo={employeeInfo}
                  message={message}
                  licenseClassList={licenseClassList}
                  licenseFormatInvalid={licenseFormatInvalid}
                  overrideLicenseValidation={this.state.overrideLicenseValidation}
                  onDateChange={this.handleDateChange}
                  onChange={this.handleChange}
                  onSelectChange={this.handleSelectChange}
                  onOverrideValidation={this.handleOverrideValidation}
                  driversLicenseFormatRules={this.state.driversLicenseFormatRules}
              />
            </ModalBody>
            <ModalFooter>
              <button className={'btn btn-primary btn-sm'}
                      onClick={this.placeOrder}
                      disabled={this.state.orderPlaced}>
                Save and Place Order
              </button>
              <button className={'btn btn-secondary btn-sm'} onClick={this.cancelOrderPlacement}>Cancel</button>
            </ModalFooter>
          </Rnd>
        </Modal>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = {
  hideModal: modalOperations.hideModal,
  addAlert: alertsOperations.addAlert,
  showModal: modalOperations.showModal
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderMVRModalContainer);