import React, {Component, Fragment} from 'react';
import ToggleMaskComponent from './toggleMaskComponent.jsx';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {layoutOperations} from '../../../../ducks/layout/';
import {hasPermission} from '../../../../utils/permissions';
import permissions from '../../../../config/permissions';

export class ToggleMaskContainer extends Component{

    onChangeMask = (e) => {
        e.target.value === 'off' ? this.props.setSSNMask(false) : this.props.setSSNMask(true)
    }
    render(){
        return(
            <Fragment>
            {hasPermission(this.props.auth.user, permissions.client.viewPII)
                ? <ToggleMaskComponent hideSSN={this.props.ssnMasked} handleChangeMask={this.onChangeMask}/>
                : null
            }
            </Fragment>
        )
    }

}



const mapStateToProps = state => ({
    auth: state.auth,
    ssnMask: state.layout.ssnMasked,
});

const mapDispatchToProps = {
    setSSNMask: layoutOperations.setSSNMask,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ToggleMaskContainer));