import axios from 'axios';
import { stringify } from 'querystring';
import { apiService } from '../apiService/apiService';

const getClientList = (cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.get(`/v1/clients?filters=topLevel`, {cancelToken})
    .then(response => {
      resolve(response.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};

const getClient = (clientId, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.get(`/v1/clients/${clientId}`, {cancelToken})
    .then(response => {
      resolve(response.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};

const getClientByLegacyId = (clientId, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.get(`/v1/clients/${clientId}?source=legacy`, {cancelToken})
    .then(response => {
      resolve(response.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};


const getTopLevelClient = (childClientId, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.get(`/v1/clients?familyOf=${childClientId}`, {cancelToken})
    .then(response => {
      if (response.status !== 200 || response.data.length !== 1) {
        return reject('Unexpected response from server looking up parent location');
      }
      return resolve(response.data[0]);
    })
    .catch(err => {
      console.error(err);
      reject(err);
    });
  });
};

// NOTE: this method is dangerous because if the object passed is missing any
// of the following information: (name, primaryAddress, contacts, notes, deactivated, employeeIdentifier) these fields will
// null out the existing values. Right now only the Client Detail page is using this method and is providing
// the full client object so all information is retained.
// todo: create dynamic patch method only modify what is provided
const updateClient = (client, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .put(`/v1/clients/${client._id}`, {
      name: client.name,
      primaryAddress: client.primaryAddress,
      contacts: client.contacts,
      notes: client.notes,
      deactivated: client.deactivated,
      employeeIdentifier: client.employeeIdentifier
    }, {cancelToken})
    .then(response => {
      resolve();
    })
    .catch(err => {
      console.error(err);
      reject(err);
    });
  });
};

const patchClient = (clientId, clientChanges, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    if (!clientId) {
      return reject(new Error('Client id is required'));
    }
    if (!clientChanges || Object.keys(clientChanges).length === 0) {
      return reject(new Error('Patch body is required'));
    }
    axios
    .patch(`/v1/clients/${clientId}`, clientChanges, {cancelToken})
    .then(res => {
      if (res.status !== 201) {
        return reject(new Error('Unexpected response from server updating client'));
      }
      resolve(res.data);
    })
    .catch(err => {
      console.error(err);
      reject(err);
    });
  })
};

const setNotes = (clientId, notes, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .put(`/v1/clients/${clientId}/notes`, {notes}, {cancelToken})
    .then(response => {
      resolve();
    })
    .catch(err => {
      console.error(err);
      reject(err);
    });
  });
};

const setServices = (clientId, services, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .put(`/v1/clients/${clientId}/services`, {services}, {cancelToken})
    .then(response => {
      resolve();
    })
    .catch(err => {
      console.error(err);
      reject(err);
    });
  });
};

const uploadLogo = (clientId, logo, cancelToken = null) => {
  const data = new FormData();
  data.append('image', logo);
  return new Promise((resolve, reject) => {
    axios
    .post(`/v1/clients/${clientId}/logo`, data, {cancelToken})
    .then(result => resolve(result.data))
    .catch(err => {
      console.error(err);
      reject(err);
    });
  });
};

const getNonImportedLegacyClientList = (cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.get('/v1/clients?source=legacy&filters=topLevel,nonImported', {cancelToken})
    .then(response => {
      resolve(response.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};

const getClientSummary = (clientId, sites, isRandoms, cancelToken) => {
  return new Promise((resolve, reject) => {
    axios.get(`/v1/clients/${clientId}/employees?summary=true&sites=${sites}${isRandoms ? '&isRandoms' : ''}`, {cancelToken})
    .then(response => {
      resolve(response.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};

const getFastTestResultsForClient = (clientId, sites, startDate, endDate, cancelToken) => {
  let url = `/v1/clients/${clientId}/fasttest?sites=${sites}`;
  if (!!startDate) {
    url += `&startDate=${startDate.format('YYYY-MM-DD')}T00:00:00`;
  }
  if (!!endDate) {
    url += `&endDate=${endDate.format('YYYY-MM-DD')}T23:59:59`;
  }
  return new Promise((resolve, reject) => {
    axios.get(url, {cancelToken})
    .then(response => resolve(response.data))
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};

const getRandomsPoolsForClient = (clientId, cancelToken) => {
  return new Promise((resolve, reject) => {
    axios.get(`/v1/clients/${clientId}/pools`, {cancelToken})
    .then(response => {
      resolve(response.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};

/**
 * getRandomsPoolsByClient
 * @param {string} clientId
 * @param {Object} [summary]
 * @param {true|Object|String|Array<String> } summary - true for default summary fields or
 * choose the fields returned from RandomPools Schema using https://mongoosejs.com/docs/5.x/docs/api.html#query_Query-select.
 * @returns RandomPools[]
 */
const getRandomsPoolsByClient = (clientId, summary) => apiService({
  url: `/v1/clients/${clientId}/pools?${stringify({ summary })}`
});

const getClientComplianceSummary = (clientId, cancelToken) => {
  return new Promise((resolve, reject) => {
    axios.get(`/v1/clients/${clientId}/compliance`, {cancelToken})
    .then(response => {
      resolve(response.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};

const getClientAudits = (clientId, cancelToken) => {
  return new Promise((resolve, reject) => {
    axios.get(`/v1/clients/${clientId}/audits`, {cancelToken})
    .then(response => {
      resolve(response.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};

const getActiveEmployeesBySite = (clientId, sites, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.get(`/v1/clients/${clientId}/employees?active=true&sites=${sites}`, {cancelToken}
    )
    .then(response => {
      resolve(response.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};

const getAlerts = (clientId, sites, alertTypes, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.get(`/v1/clients/${clientId}/alerts?alertTypes=${alertTypes.join(',')}&sites=${sites}&summary&problemsAndWarningsOnly=true`, {cancelToken})
    .then(response => {
      resolve(response.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};

const getDocReviewAlerts = (clientId, sites, cancelToken, mvrOrEPN) => {
  return new Promise((resolve, reject) => {
    axios.get(`/v1/clients/${clientId}/alerts?alertTypes=${mvrOrEPN}&sites=${sites}`, {cancelToken})
    .then(response => {
      resolve(mvrOrEPN === 'MVR' ? response.data.mvrAlerts : response.data.epnAlerts);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};

const getStaleApplicantAlerts = (clientId, sites, cancelToken) => {
  return new Promise((resolve, reject) => {
    axios.get(`/v1/clients/${clientId}/alerts?alertTypes=staleApplicants&sites=${sites}`, {cancelToken})
    .then(response => {
      resolve(response.data.staleApplicantAlerts);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};

const getCSAAlerts = (clientId, sites, cancelToken) => {
  return new Promise((resolve, reject) => {
    axios.get(`/v1/clients/${clientId}/alerts?alertTypes=CSA&sites=${sites}`, {cancelToken})
    .then(response => {
      resolve(response.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};

const getDACAlerts = (clientId, sites, cancelToken) => {
  return new Promise((resolve, reject) => {
    axios.get(`/v1/clients/${clientId}/alerts?alertTypes=DAC&sites=${sites}`, {cancelToken})
    .then(response => {
      resolve(response.data.dacStatus);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};

const getDocumentAlerts = (clientId, sites, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.get(`/v1/clients/${clientId}/alerts?alertTypes=documents&sites=${sites}`, {cancelToken})
    .then(response => {
      resolve(response.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};

const getMissingDocuments = (clientId, sites, allEmployees, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.get(`/v1/clients/${clientId}/alerts?alertTypes=missingDocuments&sites=${sites}&allEmployees=${allEmployees}`, {cancelToken})
    .then(response => {
      resolve(response.data.docAlerts);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};

const getMissingAndExpiredDocuments = (
    clientId,
    sites,
    applicant,
    employee,
    inactive,
    terminated,
    missing,
    expired,
    expiredDays,
    expiring,
    expiringDays,
    cancelToken = null
) => {
  const employmentStatuses = [];
  if (applicant) {
    employmentStatuses.push('Applicant');
  }
  if (employee) {
    employmentStatuses.push('Employee');
  }
  if (inactive) {
    employmentStatuses.push('Inactive');
  }
  if (terminated) {
    employmentStatuses.push('Terminated');
  }
  const docStatuses = [];
  if (missing) {
    docStatuses.push('missing');
  }
  if (expired) {
    docStatuses.push('expired');
  }
  if (expiring) {
    docStatuses.push('expiring');
  }
  return new Promise((resolve, reject) => {
        axios.get(
            // We need to pass allEmployees=true so that it gets them all from the sproc; then we also pass the list of
            // statuses that we really care about, so that they are filtered from the sproc results at the server.
            // Also pass the list of docSTatuses that we want, as well as expiredDays and expiringDays
            // -- and let the server do all the filtering.
            `/v1/clients/${clientId}/alerts?alertTypes=missingDocuments&sites=${sites}&allEmployees=true&employmentStatuses=${employmentStatuses}&docStatuses=${docStatuses}&expiredDays=${expiredDays}&expiringDays=${expiringDays}`,
            {cancelToken}
        )
        .then(response => {
          resolve(response.data.docAlerts);
        })
        .catch(err => {
          if (axios.isCancel(err)) {
            return reject(err.message);
          }
          console.error(err);
          reject(err);
        });
      }
  );
};

const getCSAReportData = (clientId, forUser, sites, reportType, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    let url = `/v1/clients/${clientId}/csa?forUser=${forUser}&reportType=${reportType}`;
    if (reportType === 'csaLocation') {
      url += `&selectedClientId=${sites}`
    } else {
      url += `&selectedDOTIds=${sites.join(',')}`;
    }
    axios.get(url, {cancelToken})
    .then(response => {
      const results = response.data.results || [];
      resolve(results);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};

// this is misleading: 'sites' is either sites or dotNums?
const getCrashReportData = (clientId, forUser, sites, reportType, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    let url = `/v1/clients/${clientId}/crashes?forUser=${forUser}&reportType=${reportType}`;
    if (reportType === 'crashLocation') {
      url += `&selectedClientId=${sites}`
    } else {
      url += `&selectedDOTIds=${sites.join(',')}`;
    }
    axios.get(url, {cancelToken})
    .then(response => {
      const results = response.data.results || [];
      resolve(results);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};

/**
 * Get Med Cards for a single client location or include child locations
 * @param {string} legacyClientId - The legacy ID of the client
 * @param {Array<string>} [selectedSites=null] - An array of selected site IDs (optional)
 * @param {object} [cancelToken=null] - Axios cancel token for request cancellation (optional)
 * @returns {Promise<Array<object>>} - The response data from the API
 * @property {string} response[].companyName - The name of the company associated with the med card
 * @property {string} response[].locationName - The name of the location associated with the med card
 * @property {string} response[].employeeName - The name of the employee holding the med card
 * @property {string} response[].ssn - The Social Security Number of the employee
 * @property {string} response[].documentBucketName - The type of document (Medical Examiners Certificate)
 * @property {string} response[].docDate - The date the document was issued, formatted as an ISO 8601 string
 * @property {string} response[].expirationDate - The expiration date of the document, formatted as an ISO 8601 string
 */
const getReportOfAllMedCardsByLocation = async ({clientId, isIncludeChildren, cancelToken = null}) => {
  try {
    const url = `/v1/clients/medCards`;
    const requestBody = {
      clientId,
      isIncludeChildren,
    };
    const response = await axios.post(url, requestBody, { cancelToken });
    return response.data;
  } catch (err) {
    console.error('Error fetching med cards:', err);
    throw err;
  }
};

const getEmployeesDOTReportData = (clientId, forUser, sites, reportType, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    let url = `/v1/clients/${clientId}/employeesByDot?reportType=employeesDOT`;
    url += `&selectedDOTIds=${sites.join(',')}`;
    axios.get(url, {cancelToken})
    .then(response => {
      const results = response.data || [];
      resolve(results);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};

const getAccidentReportData = (clientId, forUser, sites, reportType, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    let url = `/v1/clients/${clientId}/accidents?forUser=${forUser}&reportType=${reportType}`;
    if (reportType === 'accidentLocation') {
      url += `&selectedClientId=${sites}`
    } else {
      url += `&selectedDOTIds=${sites.join(',')}`;
    }
    axios.get(url, {cancelToken})
    .then(response => {
      const results = response.data.results || [];
      resolve(results);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};

const getDrugAlcoholReportData = (clientId, forUser, sites, reportType, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    let url = `/v1/clients/${clientId}/drugAlcohol?forUser=${forUser}&reportType=${reportType}`;
    if (reportType === 'daLocation') {
      url += `&selectedClientId=${sites}`
    } else {
      url += `&selectedDOTIds=${sites.join(',')}`;
    }
    axios.get(url, {cancelToken})
    .then(response => {
      const results = response.data.results || [];
      resolve(results);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};

// TODO: remove this
const addRandomPool = (clientId, pools, topLevel, includeChildren, configuring = false, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    if (!clientId) {
      return reject(new Error('The client id is required'));
    }
    if (!pools || Object.keys(pools).length === 0) {
      return reject(new Error('The random pool is required'));
    }
    axios.post(`/v1/clients/${clientId}/pools?topLevel=${topLevel}&includeChildren=${includeChildren}&configuring=${configuring}`, pools, {cancelToken})
    .then(res => {
      if (res.status !== 201 && res.status !== 200) {
        return reject(new Error('Unexpected response from server when adding new randoms pool to client'));
      }
      resolve(res.data);
    })
    .catch(err => {
      console.error(err);
      reject(err);
    });
  });
};

const addEZComplianceRandomsPool = (clientId, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    if (!clientId) {
      return reject(new Error('The client id is required'));
    }
    axios.post(`/v1/clients/${clientId}/pools?ezCompliance=true&topLevel=true`, {}, {cancelToken})
    .then(res => {
      if (res.status !== 201) {
        return reject(new Error('Unexpected response from server when adding new randoms pool to client'));
      }
      resolve(res.data);
    })
    .catch(err => {
      console.error(err);
      reject(err);
    });
  });
};

const removeEZComplianceRandomsPool = (clientId, poolId, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    if (!poolId || !clientId) {
      return reject(new Error('pool id and client id is required'));
    }
    axios.delete(`/v1/clients/${clientId}/pools/${poolId}?ezCompliance=true&topLevel=true`, {cancelToken})
    .then(res => {
      if (res.status !== 204) {
        return reject(new Error('Unexpected response from server when deleting provided randoms pool'));
      }
      resolve();
    })
    .catch(err => {
      console.error(err);
      reject(err);
    });
  });
};

const updateRandomPool = (pool, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    if (!pool || !pool._id) {
      return reject(new Error('pool is required'));
    }
    axios.patch(`/v1/clients/0/pools/${pool._id}`, pool, {cancelToken})
    .then(res => {
      if (res.status !== 204) {
        return reject(new Error('Unexpected response from server when updating provided randoms pool'));
      }
      resolve();
    })
    .catch(err => {
      console.error(err);
      reject(err);
    });
  });
};

const deleteRandomPool = (clientId, poolId, topLevel, includeChildren = false, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    if (!poolId || !clientId) {
      return reject(new Error('pool id and client id is required'));
    }
    axios.delete(`/v1/clients/${clientId}/pools/${poolId}?topLevel=${topLevel}&includeChildren=${includeChildren}`, {cancelToken})
    .then(res => {
      if (res.status !== 204) {
        return reject(new Error('Unexpected response from server when deleting provided randoms pool'));
      }
      resolve();
    })
    .catch(err => {
      console.error(err);
      reject(err);
    });
  });
};

const createRandomsPool = (clientId, poolDefinition, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    if (!clientId) {
      return reject(new Error('The client id is required'));
    }
    if (!poolDefinition) {
      return reject(new Error('The pool definition is required'));
    }
    axios.post(`/v1/clients/${clientId}/pools`, poolDefinition, {cancelToken})
    .then(res => {
      if (res.status !== 204) {
        return reject(new Error('Unexpected response from server when creating randoms pool'));
      }
      resolve(res.data);
    })
    .catch(err => {
      console.error(err);
      reject(err);
    });
  });
};

const modifyRandomsPool = (clientId, poolDefinition, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    if (!clientId) {
      return reject(new Error('The client id is required'));
    }
    if (!poolDefinition) {
      return reject(new Error('The pool definition is required'));
    }
    axios.patch(`/v1/clients/${clientId}/pools/${poolDefinition._id}`, poolDefinition, {cancelToken})
    .then(res => {
      if (res.status !== 204) {
        return reject(new Error('Unexpected response from server when updating provided randoms pool'));
      }
      resolve();
    })
    .catch(err => {
      console.error(err);
      reject(err);
    });
  });
};

const getPools = (clientId, availablePools = false, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.get(`/v1/clients/${clientId}/pools?availablePools=${availablePools}`, {cancelToken})
    .then(res => {
      if (res.status !== 200) {
        return reject(new Error('Unexpected response from server'));
      }
      resolve(res.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};

const getEmployeesForPool = (pool, clientId, sites, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.post(`/v1/clients/${clientId}/pools/employeeList?sites=${sites}`, pool, {cancelToken})
    .then(res => {
      if (res.status !== 201) {
        return reject(new Error('Unexpected response from server'));
      }
      resolve(res.data);
    })
    .catch(err => {
      console.error(err);
      reject(err);
    });
  });
};

const getLocationsForPool = (pool, clientId, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.post(`/v1/clients/${clientId}/pools/locationList`, pool, {cancelToken})
    .then(res => {
      if (res.status !== 201) {
        return reject(new Error('Unexpected response from server'));
      }
      resolve(res.data);
    })
    .catch(err => {
      console.error(err);
      reject(err);
    });
  });
};

const getUsersForClient = (clientId, forPermission = null, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    if (!clientId) {
      return reject(new Error('Invalid parameters. Unable to make request.'));
    }
    let url = `/v1/clients/${clientId}/users`;
    if (forPermission) {
      url += `?forPermission=${forPermission}`
    }
    axios.get(url, {cancelToken})
    .then(res => {
      if (res.status !== 200) {
        return reject(new Error('Unexpected error from server'));
      }
      resolve(res.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};

const getJobTitleProgramsForClient = (legacyClientId, cancelToken) => {
  return new Promise((resolve, reject) => {
    axios.get(`/v1/clients/${legacyClientId}/jobTitles?includePrograms=true&legacyClientId=${legacyClientId}`, {cancelToken})
    .then(response => {
      resolve(response);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};

const addJobTitleToClient = (selectedJobTitle, legacyClientId, newJobTitleName, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    if (!legacyClientId) {
      return reject(new Error('The client id is required'));
    }
    if (!newJobTitleName && !selectedJobTitle) {
      return reject(new Error('The job title is required to be specified'));
    }
    let jobTitleId = -1;
    let jobTitleName = newJobTitleName;
    if (selectedJobTitle) {
      jobTitleId = selectedJobTitle.value;
      jobTitleName = selectedJobTitle.name;
    }
    axios.post(`/v1/clients/${legacyClientId}/jobTitles/${jobTitleId}?jobTitle=${jobTitleName}${newJobTitleName ? '&isNew=true' : ''}`, {}, {cancelToken})
    .then(res => {
      if (res.status !== 201 && res.status !== 200) {
        return reject(new Error('Unexpected response from server when adding new job title to client'));
      }
      resolve(res.data);
    })
    .catch(err => {
      console.error(err);
      reject(err);
    });
  });
};

const updateClientHierarchy = (clientId, legacyClientId, LCodes, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.patch(`/v1/clients/${clientId}/hierarchy`, {legacyClientId, LCodes}, {cancelToken})
    .then(res => {
      resolve();
    })
    .catch(err => {
      console.error(err);
      reject(err);
    });
  });
};

const calculateRandomSelection = (clientId, poolId, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.get(`/v1/clients/${clientId}/pools/${poolId}/selections/calculation`, {cancelToken})
    .then(res => {
      resolve(res.data);
    })
    .catch(err => {
      console.error(err);
      reject(err);
    });
  });
};

const makeRandomSelection = (clientId, poolId, numToSelectDrug, numToSelectAlcohol, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.post(`/v1/clients/${clientId}/pools/${poolId}/selections?drug=${numToSelectDrug}&alcohol=${numToSelectAlcohol}`, {}, {cancelToken})
    .then(res => {
      resolve(res.data);
    })
    .catch(err => {
      console.error(err);
      reject(err);
    });
  });
};

const extendRandomSelection = (clientId, poolId, selectionId, numToSelectDrug, numToSelectAlcohol, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.patch(`/v1/clients/${clientId}/pools/${poolId}/selections/${selectionId}`, {numToSelectDrug, numToSelectAlcohol}, {cancelToken})
    .then(res => {
      resolve(res.data);
    })
    .catch(err => {
      console.error(err);
      reject(err);
    });
  });
};

const makeRandomAlternateSelection = (clientId, poolId, alternateValue, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.post(`/v1/clients/${clientId}/pools/${poolId}/selections${!!alternateValue ? '?alternateNumber=' + alternateValue : ''}`, {}, {cancelToken})
    .then(res => {
      resolve(res.data);
    })
    .catch(err => {
      console.error(err);
      reject(err);
    });
  });
};

const getPreviousRandomsPoolSelections = (clientId, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.get(`/v1/clients/${clientId}/pools/selections/previous`, {cancelToken})
    .then(result => {
      resolve(result.data)
    })
    .catch(err => {
      console.error(err);
      reject(err);
    });
  });
};


// getAlternates indicates to get the alternate list instead of the selection
// getSnapshot indicates to get the whole roster snapshot not just the selection
const getRandomSelection = ({clientId, poolId, selectionId, getAlternates = false, getSnapshot = false, isInternalUser, impersonatingUserId, cancelToken = null}) => {
  return new Promise((resolve, reject) => {
    const optionString = `${'?'}${getAlternates ? 'alternates&' : ''}${getSnapshot ? 'snapshot&' : ''}${isInternalUser ? 'isInternalUser=true&' : ''}${impersonatingUserId ? `impersonatingUserId=${impersonatingUserId}` : ''}`;
    axios.get(`/v1/clients/${clientId}/pools/${poolId}/selections/${selectionId}${optionString}`, {cancelToken})
    .then(result => {
      resolve(result.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};

const getDocTypesForClient = (clientId, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.get(`/v1/clients/${clientId}/docTypes`, {cancelToken})
    .then(response => {
      resolve(response);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};

const searchForDrugTestMatch = (clientId, searchData, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.post(`/v1/clients/${clientId}/drugTestMatch`, {searchData}, {cancelToken})
    .then(response => {
      resolve(response.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};

const saveDrugTestMatch = (clientId, employeeId, specimenReferenceId, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.post(`/v1/clients/${clientId}/drugTestMatch/${specimenReferenceId}?employeeId=${employeeId}`, {}, {cancelToken})
    .then(response => {
      resolve();
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};

const updateDrugTestMatch = (clientId, employeeId, specimenReferenceId, data, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.patch(`/v1/clients/${clientId}/drugTestMatch/${specimenReferenceId}?employeeId=${employeeId}`, data, {cancelToken})
    .then(response => {
      resolve();
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};


const getSelectionsForEmployee = (clientId, employeeId, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.get(`/v1/clients/${clientId}/selections/${employeeId}`, {cancelToken})
    .then(response => {
      resolve(response.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};

const emailRandomSelection = (clientId, poolId, selectionId, emailList, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.post(`/v1/clients/${clientId}/pools/${poolId}/selections/${selectionId}/email`, {emailList}, {cancelToken})
    .then(result => resolve(result.data))
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};

const copySettingsFromLocation = (clientId, clientFrom, fields, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.patch(`/v1/clients/${clientId}?settingsFrom=${clientFrom}&fields=${fields.join()}&legacy=true`, {}, {cancelToken})
    .then(result => resolve())
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};

const getEmployeeCount = (clientId, jobTitleId, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.get(`/v1/clients/${clientId}/employees/count?jobTitleId=${jobTitleId}`, {cancelToken})
    .then(response => {
      resolve(response.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};

const deleteJobTitleTransferEmployees = (clientId, deleteJobTitleId, transferJobTitle, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.patch(`/v1/clients/${clientId}/removeJobTitle?deleteJobTitleId=${deleteJobTitleId}&transferJobTitle=${transferJobTitle}`, {cancelToken})
    .then(() => resolve())
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};

const getClientMVRScoreDefs = (clientId, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.get(`/v1/clients/${clientId}/MVRScoreDefs`, {cancelToken})
    .then(response => {
      resolve(response.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};

const getClientMVRScoringRules = (clientId, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.get(`/v1/clients/${clientId}/MVRScoringRules`, {cancelToken})
    .then(response => {
      resolve(response.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};

const getClientMVRScoringReasons = (clientId, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.get(`/v1/clients/${clientId}/MVRScoringReasons`, {cancelToken})
    .then(response => {
      resolve(response.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};

const searchByDOTNumber = (DOTNumber, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.get(`/v1/clients?source=legacy&dotnumber=${DOTNumber}`, {cancelToken})
    .then(response => {
      resolve(response.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};

const getClientLocations = (clientId, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.get(`/v1/clients?descendantsOf=${clientId}&filters=active&source=legacy&aliases=true&flatten=true`, {cancelToken})
    .then(response => resolve(response.data))
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};

const updateLocationAliases = (integrationTypeId, aliases, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.patch(`/v1/clients/aliases/${integrationTypeId}`, aliases, {cancelToken})
    .then(response => {
      resolve();
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};

const getClearinghouseQueryAlerts = (clientId, sites, problemsAndWarningsOnly, cancelToken) => {
  return new Promise((resolve, reject) => {
    axios.get(`/v1/clients/${clientId}/alerts?alertTypes=clearinghouseQueries&sites=${sites}&problemsAndWarningsOnly=${problemsAndWarningsOnly}`, {cancelToken})
    .then(response => {
      resolve(response.data.clearinghouseQueryAlerts);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error(err);
      reject(err);
    });
  });
};

// TODO - move this to Concorde API
const getAllJobTitlesForAClient = async (clientMongoId) => {
  const { data } = await axios.get(`/v1/clients/${clientMongoId}/jobTitles?forCompleteHierarchy=true`)
  return data;
}

export default {
  getAllJobTitlesForAClient,
  getClientList,
  getClient,
  getClientByLegacyId,
  getTopLevelClient,
  updateClient,
  setNotes,
  setServices,
  uploadLogo,
  getNonImportedLegacyClientList,
  getClientSummary,
  getClientComplianceSummary,
  getClientAudits,
  getActiveEmployeesBySite,
  getAlerts,
  getDocReviewAlerts,
  getStaleApplicantAlerts,
  getCSAAlerts,
  getDACAlerts,
  getDocumentAlerts,
  getMissingDocuments,
  getMissingAndExpiredDocuments,
  getCSAReportData,
  getCrashReportData,
  getReportOfAllMedCardsByLocation,
  getAccidentReportData,
  getDrugAlcoholReportData,
  addRandomPool,  // TODO: remove this
  getEmployeesForPool,
  getLocationsForPool,
  updateRandomPool,
  deleteRandomPool,
  createRandomsPool,
  modifyRandomsPool,
  addEZComplianceRandomsPool,
  removeEZComplianceRandomsPool,
  patchClient,
  getPools,
  getUsersForClient,
  getJobTitleProgramsForClient,
  addJobTitleToClient,
  getEmployeesDOTReportData,
  getFastTestResultsForClient,
  getRandomsPoolsForClient,
  getRandomsPoolsByClient,
  updateClientHierarchy,
  calculateRandomSelection,
  makeRandomSelection,
  extendRandomSelection,
  makeRandomAlternateSelection,
  getPreviousRandomsPoolSelections,
  getRandomSelection,
  getDocTypesForClient,
  searchForDrugTestMatch,
  saveDrugTestMatch,
  updateDrugTestMatch,
  getSelectionsForEmployee,
  emailRandomSelection,
  copySettingsFromLocation,
  getEmployeeCount,
  deleteJobTitleTransferEmployees,
  getClientMVRScoreDefs,
  getClientMVRScoringRules,
  getClientMVRScoringReasons,
  searchByDOTNumber,
  getClientLocations,
  updateLocationAliases,
  getClearinghouseQueryAlerts
};