import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import VerificationModal from './verificationOfFCRAModalComponent';
import axios from "axios";
import {alertsOperations} from '../../../../ducks/alerts';
import {modalOperations} from "../../../../ducks/modal";
import ReactHtmlParser from 'react-html-parser';
import {Rnd} from "react-rnd";

class VerificationOfFCRAModalContainer extends Component {
  constructor(props) {
    super(props);

    //the props are coming in on the info prop of the modal container, so map them to the ones we need
    this.state = {
      refreshData: props.info && props.info.refreshData ? props.info.refreshData : false,
      content: null
    };
  }

  componentDidMount() {
    this.cancelSource = axios.CancelToken.source();
    axios.get(`/v1/settings/documents/verification`, {cancelToken: this.cancelSource.token})
    .then(response => {
      this.setState({content: <div className={'m-2'}>{ReactHtmlParser(response.data.contents)}</div>})
    }).catch(err => {
      console.log(err);
      this.props.addAlert('The verification content could not be loaded.', 'danger');
    });
  }

  componentWillUnmount() {
    this.cancelSource.cancel('userCanceled');
  }


  acceptVerification = async () => {
    if (this.state.accepted) {
      return; // prevent calling hide modal prop twice
    }
    await this.setStateAsync({accepted: true});
    this.props.hideModal(true, {dialogAccepted: true, info: this.props.info});
  };

  cancelVerification = () => {
    this.props.hideModal(true);
  };

  setStateAsync = state => {
    return new Promise(resolve => {
      this.setState({...state}, () => resolve())
    });
  };

  render() {
    const {content} = this.state;
    if (!content) {
      return null;
    }
    return (
        <Fragment>
          <Modal isOpen={true} className={'large-modal'}>
            <Rnd dragHandleClassName={'modal-header'} enableResizing={false}>
              <ModalHeader className={'cursor-move'}>You must verify your consent under the Fair Credit Reporting
                Act</ModalHeader>
              <ModalBody>
                <VerificationModal
                    content={content}
                />
              </ModalBody>
              <ModalFooter>
                <button className={'btn btn-primary btn-sm'} onClick={this.acceptVerification}>Accept
                </button>
                <button className={'btn btn-secondary btn-sm'} onClick={this.cancelVerification}>Cancel</button>
              </ModalFooter>
            </Rnd>
          </Modal>
        </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = {
  hideModal: modalOperations.hideModal,
  addAlert: alertsOperations.addAlert
};

export default connect(mapStateToProps, mapDispatchToProps)(VerificationOfFCRAModalContainer);