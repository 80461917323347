import types from './types';

const concordeReducer = (state = {clientList: []}, action) => {
  switch (action.type) {
    case types.SET_CLIENT_LIST:
      return {
        ...state,
        clientList: action.payload
      };
    default:
      return state;
  }
};

export default concordeReducer;