import React from 'react';
import {Button} from 'reactstrap';
import ReactTable from 'react-table';
import CBReactTablePagination from '../CBReactTablePagination/CBReactTablePagination';

const vendorLookupComponent = props => {
  const {results, buttonWrapClassName, columns, showPagination, showVendorIdField, filterBreathAlcohol, requirePOCT, distanceColumnVisible} = props;
  return (
      <form autoComplete="new-password">
        {filterBreathAlcohol &&
        <div className={'bg-danger text-white font-weight-bold p-2'}>
          You have selected to order a breath alcohol test, therefore your search will only show
          clinics that are capable of performing breath alcohol tests.
        </div>
        }
        {requirePOCT &&
        <div className={'bg-danger text-white font-weight-bold p-2'}>
          You have selected to order an instant test, therefore your search will only show
          clinics that are capable of performing point-of-collection testing.
        </div>
        }
        <div className="form-row align-items-center">
          {showVendorIdField &&
          <div className="col-sm-2 my-1">
            <label htmlFor="vendorId" className="sr-only">Vendor ID</label>
            <input name="vendorId"
                    type="text"
                    autoComplete="new-password"
                    className="form-control"
                    id="vendorId"
                    placeholder="vendor id"
                    onChange={props.handleSearchInputValueChange}/>
          </div>}
          <div className="col-sm-4 my-1">
            <label htmlFor="name" className="sr-only">Clinic</label>
            <input name="vendor"
                   type="text"
                   autoComplete="new-password"
                   className="form-control"
                   id="vendor"
                   placeholder="clinic"
                   onChange={props.handleSearchInputValueChange}/>
          </div>
          <div className={showVendorIdField ? "col-sm-3 my-1" : "col-sm-5 my-1"}>
            <label htmlFor="address" className="sr-only">Address</label>
            <input name="address"
                   type="text"
                   autoComplete="new-password"
                   className="form-control"
                   id="address"
                   placeholder="address"
                   onChange={props.handleSearchInputValueChange}/>
          </div>
          <div className="col-sm-2 my-1">
            <label htmlFor="zip" className="sr-only">Zip</label>
            <input name="zip"
                   type="text"
                   autoComplete="new-password"
                   className="form-control"
                   id="zip"
                   placeholder="zip"
                   onChange={props.handleSearchInputValueChange}/>
          </div>
          <div className="col-auto my-1">
            <button type="submit" className="btn btn-primary" onClick={props.handleVendorSearch} aria-label='Clinic Search Button'>
              <i className="fas fa-search"/>
            </button>
          </div>
        </div>
        <div className="results-wrapper">
          <ReactTable
              className={'-striped -highlight -cursor-pointer'}
              columns={columns}
              data={results || []}
              pageSizeOptions={[10, 20, 25]}
              defaultPageSize={10}
              showPagination={showPagination}
              PaginationComponent={CBReactTablePagination}
              defaultSorted={[{id: 'colDistance'}]}
              noDataText={!!results ? 'No clinics to show' : 'Use the form above to search for clinics'}
              getTrProps={
                (state, rowInfo, column) => {
                  if (!rowInfo) return {};
                  if (!rowInfo.original) return {};
                  return {
                    className: rowInfo.original.VendorID === props.selectedVendorId ? 'selected' : ''
                  }
                }
              }
              getTdProps={(state, rowInfo, column, instance) => {
                return {
                  onClick: (e, handleOriginal) => {
                    if (!rowInfo) return;
                    const record = rowInfo.original;
                    props.handleVendorSelection(record);
                    if (handleOriginal) {
                      handleOriginal();
                    }
                  }
                };
              }}
          />
        </div>
        {distanceColumnVisible &&
        <div className={'bg-info mx-1 px-2'}>
          * Distance in miles between the clinic's zip code and the one requested -- NOT actual driving distance!
        </div>
        }
        <div className={buttonWrapClassName}>
          {props.message &&
          <div style={{width: '100%'}}>{props.message}</div>}
          <Button color="primary" onClick={props.handleConfirmVendorSelection} // calls handleConfirmClinicSelection() in BulkOrderDrugScreenContainer
                  disabled={props.selectedVendorId === null || props.selectedVendorId === 0}>Select Clinic</Button>
          <Button color="secondary" onClick={props.handleCancelVendorSelection}>Cancel</Button>
        </div>
      </form>
  )
};

export default vendorLookupComponent;