import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';

import SimpleSitesSelectorComponent from './simpleSitesSelectorComponent';
import {dqfOperations} from "../../../ducks/dqf";


export class SimpleSitesSelectorContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedSite: {},
      selectedSites: [],
      placeholder: 'Select Location', // placeholder text in select mode
      onlySite: null, // is always set internally
      autoSelectSite: null, // set in mounting once
      showHierarchySearchBox: true
    }
  }

  componentDidMount() {

    const newState = {onlySite: null, autoSelectSite: null};

    if (this.props.sites.displaySite) {
      // reset the 'selected site' and 'selected sites' in Redux if the displayValue in Redux is equal to 'allSites'
      // and the implementation doesn't support the 'selectAll' option
      if (this.props.sites.displaySite._id === 'allSites' && !this.props.selectAll) {
        this.props.setSelectedSites([], {});
      } else {
        // otherwise, the selectedSite is can be set equal to the displaySite stored in Redux
        newState.selectedSite = this.props.sites.displaySite;
      }
    }

    if (this.props.displaySites && this.props.displaySites.length === 1) {
      newState.onlySite = this.props.displaySites[0];
    }

    newState.autoSelectSite = this.props.autoSelectSite || null;

    // set the 'selected site' with the provided value for autoSelectSite or the onlySite possible
    if (newState.autoSelectSite || newState.onlySite) {
      const autoSite = newState.autoSelectSite || newState.onlySite;
      newState.selectedSite = autoSite;
      const autoClickedSite = {value: autoSite._id, label: autoSite.name};
      this.setSitesForFlatSingleSelect(autoClickedSite, true);
    }

    this.setState({...newState});
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.sites.sessionClient !== nextProps.sites.sessionClient ||
        !_.isEqual(this.props.sites.displaySite, nextProps.sites.displaySite) ||
        !_.isEqual(this.props.displaySites, nextProps.displaySites) ||
        !_.isEqual(this.props.autoSelectSite, nextProps.autoSelectSite) ||
        !_.isEqual(this.state.selectedSite, nextState.selectedSite) || this.props.selectIsDisabled !== nextProps.selectIsDisabled;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const newState = {};
    let requiresReSelection = false;
    let sessionClientChanged = this.props.sites.sessionClient !== prevProps.sites.sessionClient;
    let reduxDisplaySiteChanged = !_.isEqual(this.props.sites.displaySite, prevProps.sites.displaySite);
    let displaySitesChanged = !_.isEqual(this.props.displaySites, prevProps.displaySites);
    let autoSelectSiteChanged = !_.isEqual(this.props.autoSelectSite, prevProps.autoSelectSite);

    if (autoSelectSiteChanged) {
      newState.autoSelectSite = this.props.autoSelectSite;
      requiresReSelection = true;
    }
    if (displaySitesChanged) {
      const isATT = this.props.sites.siteTree.legacyClientId === 513;
      newState.displaySites = this.props.displaySites.filter(site => isATT || !site.deactivated);
      newState.onlySite = newState.displaySites && newState.displaySites.length === 1 ? newState.displaySites[0] : null;
      requiresReSelection = true;
    }
    if (reduxDisplaySiteChanged && this.props.sites.displaySite && Object.keys(this.props.sites.displaySite).length > 0) {
      newState.selectedSite = this.props.sites.displaySite;
    }
    // note: a client selection change *should* always be an independent update from other prop changes passed into
    // the component. if that changes it possible 're selection' logic needs to be applied to the truthy condition

    if (!sessionClientChanged && requiresReSelection) {
      const onlySite = newState.onlySite || this.state.onlySite; // the updated 'onlySite' takes priority over the property in state
      const autoSelectSite = newState.autoSelectSite || this.state.autoSelectSite; // the updated 'autoSelectSite' takes priority over the priority in state
      const autoAssignSite = autoSelectSite || onlySite; // if both exist take the autSelectSite as that is defined by the user
      if (autoAssignSite) {
        newState.selectedSite = autoSelectSite;
        this.setSitesForFlatSingleSelect({
          value: autoAssignSite._id,
          label: autoAssignSite.name
        }, true);
      }
    }

    if (Object.keys(newState).length > 0) {
      this.setState({...newState});
    }

  }

  componentWillUnmount() {
    this.props.setAutoSelectSite(null);
    this.props.setSelectedHierarchySites([]);
  }

  /**
   * Event handler for when sites are selected in Flat Mode
   * @param selection - (array or object) react-select selected sites
   * multi select: e: [ value, name ]
   * single select - e: { value, name }
   */
  handleFlatModeSitesChanged = selection => {
    this.setSitesForFlatMode(selection);
  };

  setSitesForFlatMode = (selection) => {
    this.setSitesForFlatSingleSelect(selection);
  };

  setSitesForFlatSingleSelect = (site, broadcastOnly = false) => {
    const selectedSite = {_id: site.value, name: site.label};
    const selectedSites = [selectedSite];

    if (broadcastOnly) {
      this.broadcastChanges(selectedSites, selectedSite);
    } else {
      this.broadcastChangesAndSetState(selectedSites, selectedSite, false);
    }
  };

  broadcastChanges = (selectedSites, displaySite) => {
    this.props.setSelectedSites(selectedSites, displaySite);
    // We also separately record the selected site, so that it cab be reinstated on all pages that display a roster. This needs
    // to be done in case a user has "drilled down" to a driver at a child site, and then wants to return to the parent site
    // when viewing the roster again. We set it HERE because this component is ONLY used in the page header, to select a location.
    this.props.setRosterSelectedLocation(displaySite);
    // do we have a onSitesChangedProp to fire?
    if (this.props.onSitesChanged) {
      if (selectedSites.length > 0) {
        this.props.onSitesChanged(selectedSites, displaySite); // fire event handler if we found at least on site in withChildren mode
      }

    }
  };

  broadcastChangesAndSetState = (selectedSites, displaySite, allSitesSelected) => {
    this.broadcastChanges(selectedSites, displaySite);
    this.setState({
      selectedSites,
      selectedSite: displaySite,
      allSitesSelected
    })
  };

  render() {
    const isATT = this.props.sites.siteTree.legacyClientId === 513;
    return (
        <SimpleSitesSelectorComponent
            placeholder={this.state.placeholder}
            displaySites={(this.props.displaySites || []).filter(site => isATT || !site.deactivated)}
            selectedSites={this.props.sites.selectedSites}
            selectedSite={this.state.selectedSite}
            selectAll={this.props.selectAll}
            allSitesSelected={this.state.allSitesSelected}
            hideSelect={!!(this.state.onlySite)}
            selectIsDisabled={!!this.props.selectIsDisabled}
            onSitesChanged={this.handleFlatModeSitesChanged}
        />
    );
  }
}

const mapStateToProps = state => ({
  sites: state.dqf ? state.dqf.sites : {}
});

const mapDispatchToProps = {
  setSelectedSites: dqfOperations.setSelectedSites,
  setSelectedHierarchySites: dqfOperations.setSelectedHierarchySites,
  setRosterSelectedLocation: dqfOperations.setRosterSelectedLocation,
  setAutoSelectSite: dqfOperations.setAutoSelectSite
};

export default connect(mapStateToProps, mapDispatchToProps)(SimpleSitesSelectorContainer);