const RESET_STEP_4_GLOBAL_STATE = 'RESET_STEP_4_GLOBAL_STATE';
const SET_STEP4_AVG_POOL_ROSTER_SIZE = 'SET_STEP4_AVG_POOL_ROSTER_SIZE';
const SET_STEP4_FOR_EDITING = 'SET_STEP4_FOR_EDITING';
const SET_STEP4_NUM_SELECTED_DRUG = 'SET_STEP4_NUM_SELECTED_DRUG';
const SET_STEP4_NUM_TESTED_DRUG = 'SET_STEP4_NUM_TESTED_DRUG';
const SET_STEP4_NUM_SELECTED_ALCOHOL = 'SET_STEP4_NUM_SELECTED_ALCOHOL';
const SET_STEP4_NUM_TESTED_ALCOHOL = 'SET_STEP4_NUM_TESTED_ALCOHOL';

export default {
  RESET_STEP_4_GLOBAL_STATE,
  SET_STEP4_AVG_POOL_ROSTER_SIZE,
  SET_STEP4_FOR_EDITING,
  SET_STEP4_NUM_SELECTED_DRUG,
  SET_STEP4_NUM_TESTED_DRUG,
  SET_STEP4_NUM_SELECTED_ALCOHOL,
  SET_STEP4_NUM_TESTED_ALCOHOL
}