import types from './types';

const setRosterView = (rosterView, auditId) => ({
  type: types.DQF_SET_ROSTER_VIEW,
  payload: {rosterView, auditId}
});

const setRandomsRosterView = rosterView => ({
  type: types.DQF_SET_RANDOMS_ROSTER_VIEW,
  payload: { rosterView }
});

const setRandomsPoolRosterView = rosterView => ({
  type: types.DQF_SET_RANDOMS_POOL_ROSTER_VIEW,
  payload: { rosterView }
});

const setSortedFilteredRoster = roster => ({
  type: types.DQF_SET_SORTED_FILTERED_ROSTER,
  payload: roster
});

const setRosterSelectedLocation = location => ({
  type: types.DQF_SET_ROSTER_SELECTED_LOCATION,
  payload: location
});

const setFilteredExcelData = excelData => ({
  type: types.DQF_SET_FILTERED_EXCEL_DATA,
  payload: excelData
});

const searchResults = (query, results, redirectTo) => ({
  type: types.DQF_SEARCH_EMPLOYEES,
  payload: {
    query,
    results,
    redirectTo
  }
});

/**
 * Set the notifications list in redux
 * @param notificationsData - (array) notifications
 * @returns {{type: string, payload: *}}
 */
const setNotificationsList = notificationsData => ({
  type: types.DQF_NOTIFICATIONS_SET_LIST,
  payload: notificationsData
});

/**
 * SEt the flag indicating whether notifications are loading
 * @param loading
 * @returns {{payload: *, type: string}}
 */
const setNotificationsLoading = loading => ({
  type: types.DQF_NOTIFICATIONS_SET_LOADING,
  payload: loading
});

/**
 * Set the pending changes in redux to be processed on save
 * @param changeLog - (object) list of pending changes: {contactId: notificationType: notificationValue, etc...}
 * @returns {{type: string, payload: {pendingChanges: *}}}
 */
const notificationsChanged = changeLog => ({
  type: types.DQF_NOTIFICATIONS_CHANGED,
  payload: {pendingChanges: changeLog}
});

/**
 * Set whether the notifications manager should be in edit mode
 * @param isEditing - (bool)
 * @param fromCancel - (bool) edit mode to view mode caused by a Cancel operation (from the caller)
 * @returns {{type: string, payload: {isEditing: *, canceled: boolean}}}
 */
const setNotificationsEditing = (isEditing, fromCancel) => ({
  type: types.DQF_NOTIFICATIONS_SET_EDITING,
  payload: {isEditing, canceled: fromCancel ? fromCancel : false}
});

/**
 * Filter the notifications list by filter / query string
 * @param filterBy - (string) query string
 * @returns {{type: string, payload: {filterBy: *}}}
 */
const setNotificationsFilterBy = filterBy => ({
  type: types.DQF_NOTIFICATIONS_SET_FILTER_BY,
  payload: {filterBy}
});

/**
 * Update the notifications list with the processed / saved notifications changes
 * @param changeLog - (object) list of changes: {contactId: notificationType: notificationValue, etc...}
 * @returns {{type: string, payload: *}}
 */
const applyChanges = changeLog => ({
  type: types.DQF_NOTIFICATIONS_APPLY_CHANGES,
  payload: changeLog
});

const setSitesSelected = (selectedSites = [], displaySite = {}) => {
  return ({
    type: types.DQF_SITES_SET_SELECTED,
    payload: {selectedSites, displaySite}
  })
};

const setSiteTree = siteTree => {
  return ({
    type: types.DQF_SITES_SET_SITE_TREE,
    payload: siteTree
  })
};

const setSelectedHierarchySites = sites => ({
  type: types.DQF_SET_SELECTED_HIERARCHY_SITES,
  payload: sites
});

/**
 * Update the Session Client in dqf.sites
 * @param sessionClient
 * @returns {{type: string, payload: *}}
 */
const setSessionClient = sessionClient => ({
  type: types.DQF_SITES_SET_SESSION_CLIENT,
  payload: sessionClient
});

const setDisplayIncludeChildren = displayIncludeChildren => ({
  type: types.DQF_SET_DISPLAY_INCLUDE_CHILDREN,
  payload: displayIncludeChildren
});

const setAutoSelectSite = site => ({
  type: types.DQF_SET_AUTO_SELECT_SITE,
  payload: site
});

const setSitesSelectorEnabled = enabled => ({
  type: types.DQF_SET_SITES_SELECTOR_ENABLED,
  payload: enabled
});

const setAlertCounts = alertCounts => ({
  type: types.DQF_SET_ALERT_COUNTS,
  payload: alertCounts
});

const setAlertsValues = alertsValues => ({
  type: types.DQF_SET_ALERTS_VALUES,
  payload: alertsValues
});

const setDocumentTabsSingleOrMulti = documentTabsSingleOrMulti => ({
  type: types.DQF_SET_DOCUMENT_TABS_SINGLE_OR_MULTI,
  payload: documentTabsSingleOrMulti
});

const setUnscoredMVRs = items => ({
  type: types.DQF_SET_UNSCORED_MVRS,
  payload: items
});

export default {
  setRosterView,
  setRandomsRosterView,
  setRandomsPoolRosterView,
  setSortedFilteredRoster,
  setRosterSelectedLocation,
  searchResults,
  setNotificationsLoading,
  setNotificationsList,
  notificationsChanged,
  setNotificationsEditing,
  setNotificationsFilterBy,
  applyChanges,
  setSitesSelected,
  setSiteTree,
  setSelectedHierarchySites,
  setSessionClient,
  setDisplayIncludeChildren,
  setAutoSelectSite,
  setSitesSelectorEnabled,
  setAlertCounts,
  setFilteredExcelData,
  setAlertsValues,
  setDocumentTabsSingleOrMulti,
  setUnscoredMVRs
}