import React from 'react'
import {NavLink} from 'react-router-dom';

const footerComponent = props => {
  const {apiVersion} = props;
  return (
      <footer id="footer">
        <div className="inner-footer">
          <div className="container">
            <div className="row">
              <div className="col-sm-4">
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <small className="text-muted">&copy; {(new Date()).getFullYear()} Concorde, Inc.</small>
                  </li>
                </ul>
              </div>
              <div className="col-sm-4">
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <small className="text-muted">Ver: {apiVersion} / {global.clientVersion}</small>
                  </li>
                </ul>
              </div>
              <div className="col-sm-4">
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <small className="text-muted"><NavLink to="/termsOfService">Terms of Service</NavLink></small>
                  </li>
                  <li className="list-inline-item">
                    <small className="text-muted"><NavLink to="/privacyPolicy">Privacy Policy</NavLink></small>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
  )
};

export default footerComponent;