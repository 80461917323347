import {combineReducers} from 'redux';
import layout from './layout';
import alerts from './alerts';
import cme from './cme';
import userSession from './userSession';
import auth from './auth';
import dqf from './dqf';
import dqInternal from './dqInternal';
import breadcrumb from './breadcrumb';
import modal from './modal';
import concorde from './concorde';
import tableSettings from './tablesettings';
import randoms from './randomsPage/reducers';

export default combineReducers({
  layout,
  alerts,
  cme,
  userSession,
  auth,
  dqf,
  dqInternal,
  breadcrumb,
  modal,
  concorde,
  tableSettings,
  randoms
});