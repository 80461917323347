import {combineReducers} from 'redux';
import moment from 'moment';
import uuid from 'uuid/v1';
import _ from 'lodash';

import types from './types';

const excelDataReducer = (state = {}, action) => {
  switch (action.type) {
    case types.DQF_SET_FILTERED_EXCEL_DATA:
      return {
        ...state,
        filteredExcelData: action.payload
      };
    default:
      return state;
  }
};

export const defaultEmployeeRosterState = {
  rosterView: 'ACTIVE_EMPLOYEES',
  randomsRosterView: 'RANDOMS_SELECTED_EMPLOYEES',
  randomsPoolRosterView: 'ALL_EMPLOYEES',
  sortedFilteredEmployeeIds: []
};

const employeeRosterReducer = (state = defaultEmployeeRosterState, action) => {
  switch (action.type) {
    case types.DQF_SET_ROSTER_VIEW:
      return {
        ...state,
        rosterView: action.payload.rosterView,
        auditId: action.payload.auditId
      };
    case types.DQF_SET_RANDOMS_ROSTER_VIEW:
      return {
        ...state,
        randomsRosterView: action.payload.rosterView,
      };
    case types.DQF_SET_RANDOMS_POOL_ROSTER_VIEW:
      return {
        ...state,
        randomsPoolRosterView: action.payload.rosterView,
      };
    case types.DQF_SET_SORTED_FILTERED_ROSTER:
      return {
        ...state,
        sortedFilteredEmployeeIds: action.payload
      };
    case types.DQF_SET_ROSTER_SELECTED_LOCATION:
      return {
        ...state,
        selectedLocation: action.payload
      };
    default:
      return state;
  }
};

const employeeSearchReducer = (state = {query: '', results: []}, action) => {
  const now = moment(Date.now());
  switch (action.type) {
    case types.DQF_SEARCH_EMPLOYEES:
      return {
        ...state,
        query: action.payload.query,
        results: action.payload.results,
        timestamp: now,
        redirectTo: action.payload.redirectTo
      };
    default:
      return state;
  }
};

const notificationsDefaultState = {
  loading: false,
  list: [],
  filterBy: '',
  pendingChanges: {},
  editing: false,
  managerKey: uuid()
};

const notificationsReducer = (state = notificationsDefaultState, action) => {
  switch (action.type) {
    case types.DQF_SET_SORTED_FILTERED_ROSTER:
      return {
        ...state
      };
    case types.DQF_NOTIFICATIONS_SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case types.DQF_NOTIFICATIONS_SET_LIST:
      return {
        ...state,
        list: action.payload
      };
    case types.DQF_NOTIFICATIONS_APPLY_CHANGES:
      const changeLog = action.payload;
      const currentList = _.cloneDeep(state.list);
      // for each contact
      Object.keys(changeLog).forEach(contactId => {
        const notificationToUpdate = currentList.find(cl => cl.contactId === parseInt(contactId, 10));
        // for each (contact) notification change
        Object.keys(changeLog[contactId]).forEach(notificationChange => {
          if (notificationChange === 'csaScores') {//csa notifications are handled differently
            notificationToUpdate[notificationChange] = changeLog[contactId][notificationChange].csaScores;
          } else if (notificationChange === 'csaDetails') {
            notificationToUpdate.types = changeLog[contactId][notificationChange].types;
            let allOn = true;
            let allOff = true;
            // Start by assuming they're all on, AND they're all off; obviously one or both of these assumptions is inccorrect
            notificationToUpdate.types.forEach((type) => {
              // Check each type to see if they're all on or they're all off (skip over the properties for type id and type name)
              if (allOn || allOff) {
                if (Object.entries(type).some(([key, value]) => key !== 'typeId' && key !== 'typeName' && !!value)) {
                  // At least one in this type is on, so they can't all be off
                  allOff = false;
                }
                if (Object.entries(type).some(([key, value]) => key !== 'typeId' && key !== 'typeName' && !value)) {
                  // At least one in this type is off, so they can't all be on
                  allOn = false;
                }
              }
            });
            notificationToUpdate.csaDetail = allOn ? 'ALL' : allOff ? '' : 'SOME';
            delete notificationToUpdate.csaDetails;
          } else {
            notificationToUpdate[notificationChange] = changeLog[contactId][notificationChange];
          }
        });
      });
      return {
        ...state,
        list: currentList
      };
    case types.DQF_NOTIFICATIONS_CHANGED:
      return {
        ...state,
        pendingChanges: action.payload.pendingChanges
      };
    case types.DQF_NOTIFICATIONS_SET_EDITING:
      const currentState = state;
      const newState = action.payload;
      let managerKey = currentState.managerKey;
      let pendingChanges = {...currentState.pendingChanges};
      if (!newState.editing && newState.canceled && Object.keys(pendingChanges).length > 0) {
        managerKey = uuid();
        pendingChanges = {};
      }
      return {
        ...currentState,
        managerKey,
        pendingChanges,
        editing: action.payload.isEditing
      };
    case types.DQF_NOTIFICATIONS_SET_FILTER_BY:
      return {
        ...state,
        filterBy: action.payload.filterBy
      };
    default:
      return state;
  }
};

const sitesReducer = (state = {
  sessionClient: '',
  selectedSites: [],
  siteTree: {},
  displaySite: {},
  selectedHierarchySites: [],
  autoSelectSite: null,
  sitesSelectorEnabled: false
}, action) => {
  switch (action.type) {
    case types.DQF_SITES_SET_SELECTED:
      return {
        ...state,
        selectedSites: action.payload.selectedSites,
        displaySite: action.payload.displaySite
      };
    case types.DQF_SITES_SET_SITE_TREE:
      return {
        ...state,
        siteTree: action.payload
      };
    case types.DQF_SITES_SET_SESSION_CLIENT:
      return {
        ...state,
        sessionClient: action.payload
      };
    case types.DQF_SET_SELECTED_HIERARCHY_SITES:
      return {
        ...state,
        selectedHierarchySites: action.payload
      };
    case types.DQF_SET_AUTO_SELECT_SITE:
      return {
        ...state,
        autoSelectSite: action.payload
      };
    case types.DQF_SET_SITES_SELECTOR_ENABLED:
      return {
        ...state,
        sitesSelectorEnabled: action.payload
      };
    case types.DQF_SITES_SET_DISPLAY_INCLUDE_CHILDREN:
      return {
        ...state,
        displayIncludeChildren: action.payload
      };
    default:
      return state;
  }
};

const alertCountsReducer = (state = {}, action) => {
  switch (action.type) {
    case types.DQF_SET_ALERT_COUNTS:
      return action.payload;
    default:
      return state;
  }
};

const alertValuesReducer = (state = {}, action) => {
  switch (action.type) {
    case types.DQF_SET_ALERTS_VALUES:
      return action.payload;
    default:
      return state;
  }
};

const displayIncludeChildrenReducer = (state = {}, action) => {
  switch (action.type) {
    case types.DQF_SET_DISPLAY_INCLUDE_CHILDREN:
      return action.payload;
    default:
      return state;
  }
};

const documentTabsSingleOrMultiReducer = (state = 'multi', action) => {
  switch (action.type) {
    case types.DQF_SET_DOCUMENT_TABS_SINGLE_OR_MULTI:
      return action.payload;
    default:
      return state;
  }
};

const unscoredMVRsReducer = (state = [], action) => {
  switch (action.type) {
    case types.DQF_SET_UNSCORED_MVRS:
      return action.payload;
    default:
      return state;
  }
};

const reducer = combineReducers({
  excelData: excelDataReducer,
  employeeRoster: employeeRosterReducer,
  employeeSearch: employeeSearchReducer,
  notifications: notificationsReducer,
  sites: sitesReducer,
  alertCounts: alertCountsReducer,
  alertsValues: alertValuesReducer,
  displayIncludeChildren: displayIncludeChildrenReducer,
  documentTabsSingleOrMulti: documentTabsSingleOrMultiReducer,
  unscoredMVRs: unscoredMVRsReducer
});

export default reducer;