import types from './types';
import step1DefaultState from './defaultState';

const randomsPoolCreationWizardStep1Reducer = (state = step1DefaultState, action) => {
  switch (action.type) {
    // =======================================================================================
    //                             Randoms Pool Creation Wizard - Step 1
    // =======================================================================================
    case types.RESET_STEP_1_GLOBAL_STATE: 
      return step1DefaultState

    case types.SET_STEP1_BY_DOT_NUMBER_SELECTION_VALUES: 
      return {
        ...state,
        byDOTNumberSelectionValues: action.payload,
      }

    case types.SET_STEP1_BY_LOCATION_NAME_SELECTION_VALUES: 
      return {
        ...state,
        byLocationNameSelectionValues: action.payload,
      }

    case types.SET_STEP1_DOT_LIST_FOR_DROPDOWN: 
      return {
        ...state,
        dotListForDropdown: action.payload,
      }

    case types.SET_STEP1_FOR_EDITING: 
      return {
        ...state,
        ...action.payload
      }

    case types.SET_STEP1_LOCATION_SEARCH_QUERY: 
      return {
        ...state,
        locationSearchQuery: action.payload
      }

    case types.SET_STEP1_SELECTION: 
      return {
        ...state,
        selection: action.payload,
        // we always want to reset the properties below when the user chooses a new selection
        locationSearchQuery: '',
        byDOTNumberSelectionValues: [],
        byLocationNameSelectionValues: []
      }

    default:
      return state;
  }
}

export default randomsPoolCreationWizardStep1Reducer;