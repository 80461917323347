// =======================================================================================
//                                Vaccines Reports Tables
// =======================================================================================
const SET_VACCINE_EVENT_REPORT_TABLE_PAGE_INDEX = 'SET_VACCINE_EVENT_REPORT_TABLE_PAGE_INDEX';
const SET_VACCINE_EVENT_REPORT_PAGE_SIZE = 'SET_VACCINE_EVENT_REPORT_PAGE_SIZE';
const SET_VACCINE_EVENT_REPORT_SORTING = 'SET_VACCINE_EVENT_REPORT_SORTING';
const SET_VACCINE_EVENT_REPORT_FILTERING = 'SET_VACCINE_EVENT_REPORT_FILTERING';
const SET_VACCINE_EVENT_REPORT_RECENT = 'SET_VACCINE_EVENT_REPORT_RECENT';
const SET_VACCINE_TEST_REPORT_TABLE_PAGE_INDEX = 'SET_VACCINE_TEST_REPORT_TABLE_PAGE_INDEX';
const SET_VACCINE_TEST_REPORT_PAGE_SIZE = 'SET_VACCINE_TEST_REPORT_PAGE_SIZE';
const SET_VACCINE_TEST_REPORT_SORTING = 'SET_VACCINE_TEST_REPORT_SORTING';
const SET_VACCINE_TEST_REPORT_FILTERING = 'SET_VACCINE_TEST_REPORT_FILTERING';
const SET_VACCINE_TEST_REPORT_RECENT = 'SET_VACCINE_TEST_REPORT_RECENT';

export default {
  // Vaccine reports tables
  SET_VACCINE_EVENT_REPORT_TABLE_PAGE_INDEX,
  SET_VACCINE_EVENT_REPORT_PAGE_SIZE,
  SET_VACCINE_EVENT_REPORT_SORTING,
  SET_VACCINE_EVENT_REPORT_FILTERING,
  SET_VACCINE_EVENT_REPORT_RECENT,
  SET_VACCINE_TEST_REPORT_TABLE_PAGE_INDEX,
  SET_VACCINE_TEST_REPORT_PAGE_SIZE,
  SET_VACCINE_TEST_REPORT_SORTING,
  SET_VACCINE_TEST_REPORT_FILTERING,
  SET_VACCINE_TEST_REPORT_RECENT
};