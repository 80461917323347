import React from 'react';

const searchComponent = props => {
  return (
      <div style={props.searchStyles}>
        <form autoComplete="new-password" className="input-group form-inline">
          <input type="search"
            id={props.placeholder.replace(/\s+/g, '-') + '-input'}
            onChange={props.onSearchInputChanged}
            className="form-control"
            placeholder={props.placeholder}
            aria-label="Search"
            value={props.query}
            onMouseDown={e => e.stopPropagation()} //this allows typing in the text field if it's used in the header of the Rnd component
            disabled={props.disabled}
          />
          <div className="input-group-append">
            <button
              type="submit"
              className="btn btn-primary"
              aria-label="Search Submit Button"
              onClick={props.onSearchButtonClick}
              disabled={props.disabled || props.buttonDisabled}
            >
              <i className="fas fa-search"/>
            </button>
          </div>
        </form>
      </div>
  );
};

export default searchComponent;