import moment from 'moment';
import userSession from '../../config/userSession';
import types from './types';

const sessionReducer = (state = { status: 'INITIAL', expireAt: null, config: { ...userSession } }, action) => {
  switch (action.type) {
    case types.SET_SESSION_STATUS:
      const now = moment(Date.now());
      //console.log(`${action.payload} timeout: ${moment(now).add(state.config.timeout, 'minutes')} warn before: ${moment(now).add(state.config.timeout - state.config.warnBefore, 'minutes')}`);
      return {
        ...state,
        status: action.payload,
        expireAt: action.payload === 'ACTIVE' ? moment(now).add(state.config.timeout, 'minutes') : null,
        warnAt: action.payload === 'ACTIVE' ? moment(now).add(state.config.timeout - state.config.warnBefore, 'minutes') : null
      };
    case types.SET_SESSION_CONFIG:
      return {
        ...state,
        config: action.payload
      };
    case types.SET_SOCKET:
      return {
        ...state,
        socket: action.payload
      };
    default:
      return state;
  }
};

export default sessionReducer;
