const allStepsDefaultState = {
  criticalErrorEncountered: false,
  currentStep: 0,
  haveFetchedDataForWizard: false, // whether we've fetched the data we need to complete the wizard
  okToProceedToNextStep: false,
  poolToEdit: null,
  showRandomsPoolWizard: false,
  steps: [
    {title: 'Location(s)', status: 'process'},
    {title: 'Filters', status: 'wait'},
    {title: '% ages and frequency', status: 'wait'},
    {title: 'YTD totals (optional)', status: 'wait'},
    {title: '# of Employees', status: 'wait'}
  ]
}

export default allStepsDefaultState;