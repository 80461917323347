import moment from 'moment';

const saveObjectToLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

const getObjectFromLocalStorage = key => {
  const value = localStorage.getItem(key);
  if (value) {
    return JSON.parse(value);
  } else
    return {};
};

const setCookie = (key, value) => {
  document.cookie = `${key}=${value};path=/;expires=;`;
};

const getCookie = key => {
  let decodedCookie = decodeURIComponent(document.cookie);
  const cookies = decodedCookie.split(';');
  for (let cookie of cookies) {
    const currentCookie = cookie.split('=');
    if (currentCookie[0].trim() === key) {
      return currentCookie[1];
    }
  }
  return null;
};

const deleteCookie = key => {
  document.cookie = `${key}=;path=/;expires=${moment.utc(new Date()).format('ddd, DD-MMM-YYYY h:mm:ss a')};`;
};

export {
  saveObjectToLocalStorage,
  getObjectFromLocalStorage,
  setCookie,
  getCookie,
  deleteCookie
};